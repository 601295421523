import React from 'react';
import { uniqueId } from 'lodash';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import moment from 'moment/moment';

import { Events } from 'trackers/analytics/enums';

import Component from 'components/Component';
import MoreButton from 'components/pages/settings/goals/controls/MoreButton';
import GeneralPopup from 'components/common/GeneralPopup';
import Tooltip from 'components/controls/Tooltip';
import GoalIcon from 'components/common/GoalIcon';
import Skeleton from 'components/common/Skeleton';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';

import { formatIndicatorDisplay } from 'components/utils/indicators';
import { getGoalPacing, getGoalProgress, getTimeData } from 'components/utils/goals';
import { mixColors } from 'components/utils/colors';
import { getDateFromUserTZ } from 'stores/analyze/timeUtils';
import { compose } from 'components/utils/utils';
import { widgetTypes } from 'components/pages/analyze/enums';

import style from 'styles/settings/goals/goals.css';

import { nonCumulativeMetricTypes, nonUpDirectionMetricTypes } from './logic/enums';

const enhance = compose(
  inject((stores) => {
    const {
      actualIndicators: dashboardActualIndicators,
    } = stores.dashboardStore;

    const {
      userMonthPlan: {
        region,
      },
    } = stores.userStore;

    const {
      updateWidgetResultData,
      updateWidgetStatusIndication,
    } = stores.widgetsAnalysisStore;

    return {
      dashboardActualIndicators,
      region,
      updateWidgetResultData,
      updateWidgetStatusIndication,
    };
  }),
  observer
);

class GoalRecordRow extends Component {
  style = style;

  openPopup = () => this.popup.open();

  closePopup = () => this.popup.close();

  async deleteGoal({ goalId }) {
    this.props.updateWidgetStatusIndication({
      widget: widgetTypes.goalsAnalysis,
      widgetRequestId: this.props.goalsAnalysisRequestId,
      newStatusIndication: 'inProgress',
    });

    await serverCommunication.serverRequest('DELETE', 'goals', JSON.stringify({ goalId }), this.props.region);
    const updatedData = this.props.goals.filter((goal) => goal.id !== goalId);

    this.props.updateWidgetResultData({
      widget: widgetTypes.goalsAnalysis,
      widgetRequestId: this.props.goalsAnalysisRequestId,
      newWidgetData: {
        goals: updatedData,
      },
    });

    this.props.updateWidgetStatusIndication({
      widget: widgetTypes.goalsAnalysis,
      widgetRequestId: this.props.goalsAnalysisRequestId,
      newStatusIndication: 'finished',
    });
  }

  render() {
    const {
      goal,
      editGoalPopup,
      isRoot,
      isFullyLast,
      isExpanded,
      setExpanded,
      isLoaded,
    } = this.props;

    const {
      id: goalId,
      metricName,
      metricType,
      title,
      targetValue: planned,
      actualValue: actual,
      timeframe = {},
      isMainGoal,
      containBreakdowns,
    } = goal;
    const startDate = moment(timeframe.startDate).utc();
    const endDate = moment(timeframe.endDate).utc();
    const localGoalStartTime = moment(getDateFromUserTZ(timeframe.startDate));
    const localGoalEndTime = moment(getDateFromUserTZ(timeframe.endDate));
    const {
      isCompleted,
      isOngoing,
      leftToFinish,
    } = getTimeData(localGoalStartTime, localGoalEndTime);

    const isUp = !nonUpDirectionMetricTypes.includes(metricType);
    const progress = getGoalProgress({ metricType, planned, actual });
    const percent = `${Math.floor(progress * 100)}%`;
    const percentRate = Math.round(Math.min(progress, 1) * 100);
    const goalColor = mixColors(percentRate);

    const usePacingForGoal = !nonCumulativeMetricTypes.includes(metricType);
    const pacing = getGoalPacing({ timeframe, actual });
    const project = usePacingForGoal ? pacing : planned;

    const buttonProps = [];

    if (isMainGoal) {
      buttonProps.push(
        {
          title: 'Edit',
          onClick: () => editGoalPopup(goal),
        },
        {
          title: 'Delete',
          onClick: () => this.deleteGoal({ goalId }),
        }
      );
    }

    if (isOngoing) {
      buttonProps.push({
        title: 'Create alert',
        onClick: () => {
          this.props.createAlertPopup(goalId);
          servicesStore.eventTracker.track({
            eventName: Events.alertsNewAlertClicked,
          });
        },
      });
    }

    const showDivider = isExpanded || (!isRoot && !isFullyLast);
    const isNotFuture = isOngoing || isCompleted;

    return (
      <div
        className={this.classes.objectiveItem}
      >
        <div className={this.classes.leftCol}>
          <div
            style={{ marginRight: 15, width: 27 }}
            onClick={() => (containBreakdowns ? setExpanded() : null)}
          >
            {containBreakdowns && (
              <div
                className={classnames(this.classes.icon, {
                  [this.classes.iconDown]: isExpanded,
                })}
              />
            )}
          </div>
          {isLoaded ? (
            <div className={this.classes.objectiveItemTitle}>
              {title}
              {' '}
              {isNotFuture && !isMainGoal && <div className={this.classes.currentIcon} />}
            </div>
          ) : <Skeleton height={20} width={160} isLightTheme />}
        </div>
        <div className={this.classes.rightCol}>
          {isLoaded ? (
            <div className={this.classes.objectiveItemDate}>
              <div style={{ display: 'flex', flex: 0 }}>
                <Tooltip
                  tip={`${startDate.format('D MMM YY')} - ${endDate.format('D MMM YY')}`}
                  id={uniqueId()}
                >
                  {isCompleted ? endDate.format('D MMM YY') : `${leftToFinish} left`}
                </Tooltip>
              </div>
            </div>
          ) : (<Skeleton height={20} width={160} isLightTheme />)}
          <div className={this.classes.objectiveChartContainer}>
            {isLoaded ? (
              <div className={this.classes.objectiveChartBg}>
                <div
                  className={classnames(this.classes.objectiveChartItem, {})}
                  style={{
                    backgroundColor: goalColor,
                    width: percent,
                  }}
                />
              </div>
            ) : <Skeleton isLightTheme />}
            <div className={this.classes.metricsRow}>
              {isLoaded ? (
                <span>
                  <span style={{ color: '#182033' }}>
                    {formatIndicatorDisplay(metricName, actual, false, false)}
                  </span>
                  <span>
                    {` of ${formatIndicatorDisplay(metricName, planned, false, false)} goal`}
                  </span>
                </span>
              ) : (<Skeleton width={40} height={10} isLightTheme />)}
              <div>
                {isLoaded ? percent : (<Skeleton width={40} height={10} isLightTheme />)}
              </div>
            </div>
          </div>
          <div className={this.classes.objectiveControls}>

            {buttonProps.length > 0 ? (
              <MoreButton onClick={() => this.openPopup()} />
            ) : null}

            {isMainGoal && isNotFuture && (
              <GoalIcon
                project={project}
                target={planned}
                value={actual}
                isDirectionUp={isUp}
                usePacingForGoal={usePacingForGoal}
                indicator={metricName}
                containerClass={this.classes.iconNoMargin}
                isHistory={isCompleted}
                metricType={metricType}
              />
            )}
            <GeneralPopup ref={(el) => { this.popup = el; }} popupClass={this.classes.popup}>
              {buttonProps.map(({ title: buttonTitle, onClick }) => (
                <div
                  key={buttonTitle}
                  className={this.classes.popupButton}
                  onClick={() => {
                    onClick();
                    this.closePopup();
                  }}
                >
                  {buttonTitle}
                </div>
              ))}
            </GeneralPopup>
          </div>
        </div>
        {showDivider && <div className={this.classes.divider} />}
      </div>
    );
  }
}

export default enhance(GoalRecordRow);
