import { headerMenuKinds } from 'components/navigationMenu/enums';
import { tabsPaths, tabsIds } from 'enums';

export function getHeaderMenuKindByPath({ path }) {
  if (path.includes(tabsIds.analyze)) {
    return headerMenuKinds.analyze;
  }

  if (path === tabsPaths.questionsResults) {
    return headerMenuKinds.questionsResults;
  }

  if (path === tabsPaths.answers) {
    return headerMenuKinds.aiAnswers;
  }

  if (path === tabsPaths.reports) {
    return headerMenuKinds.reports;
  }

  if (path === tabsPaths.reportsNew) {
    return headerMenuKinds.createNewReport;
  }

  if (path === tabsPaths.widgetBuilder) {
    return headerMenuKinds.widgetBuilder;
  }

  if (path === tabsPaths.dashboard) {
    return headerMenuKinds.dashboard;
  }

  return '';
}
