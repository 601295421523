import PropTypes from 'prop-types';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { get, isObject } from 'lodash';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';

import countryCode from 'data/countryCode';
import exportData from 'modules/exportData';

import { Events } from 'trackers/analytics/enums';

import ChannelList from 'components/common/ChannelList';
import CheckboxListPopup from 'components/common/CheckboxListPopup';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import SearchInput from 'components/controls/SearchInput';
import Spinner from 'components/pages/journeys/Spinner';
import Table, { DEFAULT_PAGE_SIZE } from 'components/controls/Table';
import Tooltip from 'components/controls/Tooltip';
import UserInfoCell from 'components/pages/journeys/UserInfoCell';
import UsersPopup from 'components/pages/users/UsersPopup';
import WidgetHeader from 'components/common/WidgetHeader';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';
import Skeleton from 'components/common/Skeleton';
import ActionPopup from 'components/pages/settings/actions/actionPopup';
import servicesStore from 'stores/servicesStore';

import { DEFAULT_TIME_ZONE, getInsightsTimeframeOptions } from 'components/utils/timeframe';
import { compose } from 'components/utils/utils';
import { ALWAYS_PRESENT_COLUMNS, getCustomValue, COLUMNS } from 'components/utils/users';
import { widgetTypes, queryParamsNames } from 'components/pages/analyze/enums';
import { createSheetAnalyze } from 'components/utils/excelExport';
import {
  filterJourneysColumns,
  getJourneysEventData,
  parsedJourneysArrayData,
} from 'components/pages/journeys/logic/AudienceTable';
import { skeletonSmallTableCellSizes, skeletonTableRows } from 'components/common/enums';
import { getQueryParams, removeQueryParams, setQueryParams } from 'components/utils/UrlParamsProvider';
import { actionTriggerTypes } from 'components/pages/settings/actions/enums';
import { getWidgetFullConfig, getWidgetsDataFromStoreV2 } from 'components/pages/analyze/widgetsRequest';

import styles from 'styles/users/users.css';

const classes = styles.locals || {};

const enhance = compose(
  inject(({
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.journeysTable]: journeysTableData,
      },
    },
    filterStore: {
      filtersData,
    },
    attributionStore: {
      groupBy,
    },
    userStore: {
      userMonthPlan: {
        region,
        UID,
      },
      userAccount: {
        leadSourcesIdToLabelMap,
        customFieldsIdToLabelMap,
        onboardingConfig,
      },
      getMetricNickname,
      userRegionsNicknames,
      actions,
    },
  }) => ({
    region,
    filtersData,
    leadSourcesIdToLabelMap,
    customFieldsIdToLabelMap,
    UID,
    groupBy,
    getMetricNickname,
    onboardingConfig,
    userRegionsNicknames,
    actions,
    getWidgetRequestId,
    journeysTableData,
  })),
  observer
);

class AudienceTable extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    dateRange: PropTypes.shape({}),
  };

  static defaultProps = {
    dateRange: {},
    data: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      channelsContainerWidth: 224,
      pageSize: DEFAULT_PAGE_SIZE,
      visibleColumns: this.getVisibleColumnsFromLocalStorage(),
      isExportLoading: false,
      selectedUser: null,
      showActionPopupWithData: null,
    };
  }

  componentDidMount() {
    if (this.channelContainer && this.channelContainer.clientWidth) {
      this.setState({
        channelsContainerWidth: this.channelContainer.clientWidth,
      });
    }
  }

  componentDidUpdate() {
    const hasData = this.props.data.length > 0;
    if (hasData && !this.state.selectedUser) {
      const journeyIdQueryParams = getQueryParams({ queryParamKey: queryParamsNames.journeyId });
      if (journeyIdQueryParams) {
        const selectedUser = this.props.data.find((item) => item.identityId === journeyIdQueryParams);
        if (selectedUser) {
          this.setState({ selectedUser });
        }
      }
    }
  }

  handleSortChange(target) {
    if (target) {
      const html = target.innerHTML;
      let id;
      if (html.toLowerCase().includes('first touch')) {
        id = 'firstTouchPoint';
      } else if (html.toLowerCase().includes('last touch')) {
        id = 'lastTouchPoint';
      } else {
        return;
      }

      const { sort } = this.props;

      let newSort;
      if (!sort || sort.id !== id) {
        newSort = { id, desc: true };
      } else {
        newSort = { id, desc: !sort.desc };
      }

      this.props.updateSorting(newSort);
    }
  }

  handleShowMoreClick = (showMoreStep, data) => {
    if (this.props.handleShowMoreClick) {
      this.props.handleShowMoreClick();
    }

    this.setState((prevState) => {
      const isLastPage = data.length - prevState.pageSize < showMoreStep;
      return {
        pageSize: isLastPage
          ? data.length
          : prevState.pageSize + showMoreStep,
      };
    });
  };

  onRowClick(journey) {
    this.setState({ selectedUser: journey });
    setQueryParams({
      queryParamKey: queryParamsNames.journeyId,
      queryParamValues: journey.identityId,
    });
  }

  getCellSkeleton() {
    return (
      <Skeleton
        {...skeletonSmallTableCellSizes}
        isLightTheme
      />
    );
  }

  getTableColumns = () => {
    const { channelsContainerWidth, visibleColumns } = this.state;
    const {
      customFieldsIdToLabelMap = {}, getMetricNickname, isLoaded,
    } = this.props;
    const cellSkeleton = this.getCellSkeleton();

    const alwaysShowColumns = [{
      id: 'User',
      header: 'User',
      cell: (item) => {
        if (!isLoaded && !item.displayName) {
          return cellSkeleton;
        }
        return (
          <UserInfoCell
            classes={classes}
            {...item}
            id={this.props.onboardingConfig?.journeys?.journeyName === item.displayName ? 'onboardingJourney' : null}
          />
        );
      },
      fixed: 'left',
      minWidth: 200,
    }];

    let regularColumns = [
      {
        id: 'Channels',
        header: 'Channels',
        cell: ({ uniqChannels, displayName }) => {
          if (!isLoaded && !displayName) {
            return cellSkeleton;
          }

          if (!uniqChannels) {
            return null;
          }

          return (
            <div
              className={classes.channelContainer}
              ref={(el) => {
                this.channelContainer = el;
              }}
            >
              <ChannelList
                channels={isObject(uniqChannels[0]) ? uniqChannels.map((ch) => ch.value) : uniqChannels}
                width={channelsContainerWidth}
              />
            </div>
          );
        },
        minWidth: 224,
        minResizeWidth: 192,
      },
      {
        id: 'CurrentStage',
        header: 'Recent Transition',
        cell: (cell = {}) => {
          if (!isLoaded && !cell.displayName) {
            return cellSkeleton;
          }

          const { currentStage } = toJS(cell);
          return getMetricNickname({ metric: currentStage, isSingular: true });
        },
        minWidth: 100,
      },
      {
        id: 'MostAdvancedStage',
        header: 'Account Stage',
        cell: (cell = {}) => {
          if (!isLoaded && !cell.displayName) {
            return cellSkeleton;
          }

          const { mostAdvancedStage } = toJS(cell);
          return getMetricNickname({ metric: mostAdvancedStage, isSingular: true });
        },
        minWidth: 100,
      },
      {
        id: 'Sessions',
        header: '# of sessions',
        cell: ({ sessions, sessionsCount, displayName }) => {
          if (!isLoaded && !displayName) {
            return cellSkeleton;
          }

          if (!sessions) {
            return null;
          }

          return sessionsCount || sessions.length;
        },
      },
      {
        id: 'Country',
        header: 'Country',
        cell: ({ countries = [], displayName }) => {
          if (!isLoaded && !displayName) {
            return cellSkeleton;
          }

          if (!countries) {
            return null;
          }

          return countries.map((item) => (
            <Tooltip
              id={`country-${item}`}
              key={item}
              tip={countryCode[item] || item}
              data-tip-disable={countries.length === 1}
            >
              <div className={classes.country}>
                <ReactCountryFlag
                  code={item}
                  svg
                  styleProps={{
                    width: '24px',
                    height: '18px',
                    flexShrink: 0,
                    marginRight: 8,
                  }}
                />
                {countries.length === 1 && (
                <div>{countryCode[item] || item}</div>
                )}
              </div>
            </Tooltip>
          ));
        },
        minWidth: 160,
      },
      {
        id: 'FirstTouch',
        header: 'First touch',
        cell: ({ timeSinceFirst, displayName }) => {
          if (!isLoaded && !displayName) {
            return cellSkeleton;
          }

          return (
            <div className={classes.light}>{timeSinceFirst}</div>
          );
        },
        sortable: true,
        sortMethod: (a, b) => a.firstTouchPoint - b.firstTouchPoint,
      },
      {
        id: 'LastTouch',
        header: 'Last touch',
        cell: ({ timeSinceLast, displayName }) => {
          if (!isLoaded && !displayName) {
            return cellSkeleton;
          }

          return (
            <div className={classes.light}>{timeSinceLast}</div>
          );
        },
        sortable: true,
        sortMethod: (a, b) => a.lastTouchPoint - b.lastTouchPoint,
      },
      {
        id: 'Device',
        header: 'Device',
        cell: ({ devices = [], displayName }) => {
          if (!isLoaded && !displayName) {
            return cellSkeleton;
          }

          if (!devices) {
            return null;
          }

          return devices.map((item) => (
            <Tooltip
              key={item}
              tip={item}
              id={`device-${item}`}
              data-tip-disable={devices.length === 1}
            >
              <div className={classes.device}>
                <div
                  className={classes.icon}
                  data-icon={`device:${item}`}
                />
                {devices.length === 1 && item}
              </div>
            </Tooltip>
          ));
        },
        minWidth: 150,
      },
    ];

    const customColumnsWithCell = [];
    for (const [fieldId, fieldLabel] of Object.entries(customFieldsIdToLabelMap)) {
      customColumnsWithCell.push({
        id: fieldId,
        header: fieldLabel,
        cell: ({ customFields = [], displayName }) => {
          const customFieldsValues = customFields?.[fieldId] || [];

          if (!isLoaded && (!displayName || customFieldsValues.length === 0)) {
            return cellSkeleton;
          }

          return (
            <EllipsisTooltip text={getCustomValue(customFieldsValues)} />
          );
        },
      });
    }
    regularColumns = regularColumns.concat(customColumnsWithCell);

    const visibleCols = regularColumns.filter((c) => visibleColumns.includes(c.id));
    alwaysShowColumns.push(...visibleCols);
    return {
      mappedColumns: alwaysShowColumns,
      activeColsLen: alwaysShowColumns.length,
    };
  };

  onResizedChange = (newResized) => {
    const resizedChannel = newResized.find((item) => item.id === 'Channels');

    if (resizedChannel) {
      // set new channels column width - padding size
      this.setState({
        channelsContainerWidth: resizedChannel.value - 48,
      });
    }
  };

  exportFunnelTransitionsData = async () => {
    try {
      await exportData.exportFunnelTransitionsData({ parse: parsedJourneysArrayData, createSheet: createSheetAnalyze });
    } catch (error) {
      servicesStore.logger.error('failed to get journey funnel transitions export', {
        UID: this.props.UID,
        error,
      });
      throw error;
    }
  };

  onExportFunnelTransitionsData = async () => {
    this.setState({ isExportLoading: true });
    await this.exportFunnelTransitionsData();
    this.setState({ isExportLoading: false });
  };

  onExportJourneysData = async () => {
    this.setState({ isExportLoading: true });
    const { visibleColumns } = this.state;
    const allColumns = this.getAllColumns();
    const { mappedColumns } = this.getTableColumns();
    const columnIdsInDisplayOrder = mappedColumns.map((column) => column.header.toLowerCase());

    const selectedColumns = [];
    for (const column of ALWAYS_PRESENT_COLUMNS) {
      selectedColumns.push({
        key: column.key,
        label: column.label,
        getData: column.key === 'emails' ? (emails) => (emails?.join(', ')) : column.getData,
      });
    }

    for (const column of visibleColumns) {
      const columnInfo = allColumns.find((item) => item.value === column);
      if (columnInfo) {
        selectedColumns.push({
          key: columnInfo.key,
          label: columnInfo.label,
          getData: columnInfo.getData,
        });
      }
    }

    const orderMap = new Map(columnIdsInDisplayOrder.map((id, index) => [id, index]));
    const reorderedSelectedColumns = selectedColumns.sort((a, b) => {
      const indexA = orderMap.get(a.label.toLowerCase());
      const indexB = orderMap.get(b.label.toLowerCase());

      if (indexA === undefined && indexB === undefined) { return 0; }
      if (indexA === undefined) { return 1; }
      if (indexB === undefined) { return -1; }

      return indexA - indexB;
    });
    await this.exportJourneysData({ selectedColumns: reorderedSelectedColumns });
    this.setState({ isExportLoading: false });
  };

  getVisibleColumnsFromLocalStorage = () => {
    let savedItemsFromLocalStorage = [];
    const allColumns = this.getAllColumns();
    const storageItem = localStorage.getItem(`journeys-table-columns-${this.props.UID}`);
    if (storageItem) {
      savedItemsFromLocalStorage = JSON.parse(storageItem);
    }
    if (savedItemsFromLocalStorage.length === 0) {
      savedItemsFromLocalStorage = allColumns.map((c) => c.value);
    }
    return savedItemsFromLocalStorage;
  };

  saveColumnsSettings = (visibleColumns) => {
    localStorage.setItem(`journeys-table-columns-${this.props.UID}`, JSON.stringify(visibleColumns));
    this.setState({
      visibleColumns,
    });
  };

  getAllColumns() {
    const { leadSourcesIdToLabelMap, customIdToLabelMap } = this.props;
    const commonColumns = COLUMNS.filter((c) => !c.isCRM);
    const customColumns = customIdToLabelMap;
    const crmLeadSourceColumns = [];

    for (const [fieldId, fieldLabel] of Object.entries(leadSourcesIdToLabelMap)) {
      crmLeadSourceColumns.push({
        value: fieldId,
        label: fieldLabel,
        getData: (row) => get(row, ['leadSources', fieldId], []).join(','),
        key: fieldId,
      });
    }

    return [...commonColumns, ...customColumns, ...crmLeadSourceColumns];
  }

  getSkeletonData() {
    return Array(skeletonTableRows).fill({});
  }

  exportJourneysData = async ({ selectedColumns }) => {
    const timeFrameOptions = getInsightsTimeframeOptions();
    const timeFrameObject = timeFrameOptions.find((option) => option.value === this.props.timeFrame?.value);
    const timeFrameLabel = timeFrameObject?.label?.toLowerCase();
    const timeFrameRange = `${moment(this.props.dateRange.startDate).toISOString()} - ${moment(this.props.dateRange.endDate).toISOString()}`;

    const fileName = `infinigrow accounts in ${timeFrameLabel || timeFrameRange}`;

    const config = getWidgetFullConfig({
      widgetConfig: {
        journeysTableParams: {
          selectedColumns,
        },
        clientCursor: 0,
        limit: 10000,
        type: widgetTypes.journeysTable,
        timeFrameLabel,
      },
    });

    await getWidgetsDataFromStoreV2({
      widget: widgetTypes.journeysTable,
      widgetConfig: config,
    });

    const requestId = this.props.getWidgetRequestId({
      widget: widgetTypes.journeysTable,
      widgetConfig: config,
    });
    const dataToExport = this.props.journeysTableData[requestId]?.result;

    const eventData = getJourneysEventData({ widgetConfig: config });
    try {
      await exportData.exportAnalyzeWidgetData({
        widget: widgetTypes.journeysTable,
        config,
        selectedColumns,
        filter: filterJourneysColumns,
        parse: parsedJourneysArrayData,
        createSheet: createSheetAnalyze,
        eventData,
        fileName,
        dataToExport,
      });
    } catch (error) {
      servicesStore.logger.error('failed to export journey table', {
        UID: this.props.UID,
        error,
      });
      throw error;
    }
  };

  closePopup() {
    this.setState({ selectedUser: null });
    const currentSearchParams = getQueryParams({
      queryParamKey: queryParamsNames.journeyId,
    });
    if (currentSearchParams) {
      removeQueryParams({ queryParamKey: queryParamsNames.journeyId });
    }
  }

  onCreateAlert() {
    const currentFilters = this.props.filters;
    this.setState({
      showActionPopupWithData: {
        triggerType: actionTriggerTypes.accountEngagement,
        triggerRules: [{ filters: currentFilters }],
      },
    });
  }

  render() {
    const {
      data,
      sort,
      filters,
      isLoaded,
      CRMConfig: { timezone } = {},
      dateRange,
      isLoading,
      dataLength,
      searchQuery,
      isFailedToLoad,
      updateSearchQuery,
      widgetHeaderProps = {},
      widgetHeaderConfig = {
        type: widgetTypes.journeysTable,
        searchQuery,
        sort,
        clientCursor: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      customFieldsIdToLabelMap,
      userRegionsNicknames,
      actions,
    } = this.props;

    if (isFailedToLoad) {
      return (
        <ErrorWidgetWithBlur
          status="error"
          widgetType={widgetTypes.journeysTable}
        />
      );
    }

    const isLoadingData = data.length === 0 && !isLoaded;
    const tableData = isLoadingData ? this.getSkeletonData() : data;

    const { selectedUser } = this.state;
    const { mappedColumns: cols, activeColsLen } = this.getTableColumns();

    const widgetHeaderConfigsOptions = [
      { label: 'Export journeys to XLSX', action: this.onExportJourneysData },
      {
        label: 'Create an Alert',
        action: () => {
          this.onCreateAlert();
          servicesStore.eventTracker.track({
            eventName: Events.alertsNewAlertClicked,
          });
        },
      },
    ];

    if (filters.length === 1 && filters[0].config.kind === 'funnelStages') {
      widgetHeaderConfigsOptions.unshift({ label: 'Export validation data', action: this.onExportFunnelTransitionsData });
    }

    return (
      <div data-testid="audience-table" style={{ position: 'relative' }}>
        <WidgetHeader
          {...widgetHeaderProps}
          cogwheelOptions={widgetHeaderConfigsOptions}
          widgetHeaderConfig={widgetHeaderConfig}
        >
          <CheckboxListPopup
            onSave={this.saveColumnsSettings}
            options={this.getAllColumns()}
            selectedOptions={this.state.visibleColumns}
            title="Manage columns and metrics"
            subTitle="Columns"
          />

          <SearchInput
            placeholder="Search..."
            defaultValue={searchQuery}
            onSearch={updateSearchQuery}
            classes={{ input: classes.journeysSearchInput }}
          />
          { this.state.isExportLoading && <Spinner />}
        </WidgetHeader>

        <div className={classes.inner}>
          {this.props.emptyStateComponent && isLoaded && tableData.length === 0 ? (
            this.props.emptyStateComponent
          ) : (
            <>
              <Table
                withFixedColumns
                cellClassName={classes.cell}
                className={classes.usersTable}
                columns={cols}
                data={tableData}
                dataLength={dataLength || tableData.length}
                handleShowMoreClick={this.handleShowMoreClick}
                headerClassName={classes.header}
                headRowClassName={classes.headRow}
                minRows={0}
                onResizedChange={this.onResizedChange}
                onRowClick={(journey) => this.onRowClick(journey)}
                pageSize={tableData.length}
                rowClassName={classes.row}
                showPagination
                tableClassName={classes.table}
                bodyCustomClass={classes.tableBody}
                style={{ maxHeight: 800 }}
                alwaysShowPagination
                activeColsLen={activeColsLen}
                handleSortChange={(target) => this.handleSortChange(target)}
                LoadingComponent={({ loading }) => (
                  <div className={classes.loaderCenter}>
                    {loading && (
                    <Spinner />
                    )}
                  </div>
                )}
                loading={isLoading}
              />
              <div className={classes.flexStartRow}>
                Logos provided by
                <a
                  href="https://clearbit.com"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Clearbit
                </a>
              </div>
            </>
          )}
        </div>

        {selectedUser ? (
          <UsersPopup
            selectedUser={selectedUser}
            dateRange={dateRange}
            startOfPeriod={dateRange.start}
            customIdToLabelMap={customFieldsIdToLabelMap}
            close={() => this.closePopup()}
            timezone={timezone || DEFAULT_TIME_ZONE}
          />
        ) : null}

        {this.state.showActionPopupWithData ? (
          <ActionPopup
            onClose={() => this.setState({ showActionPopupWithData: null })}
            actionData={this.state.showActionPopupWithData}
            isMultiRegionsAccount={userRegionsNicknames?.length > 1}
            userActionNames={actions?.map((action) => action.name)}
          />
        ) : null}
      </div>
    );
  }
}

export default enhance(AudienceTable);
