import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { orderBy } from 'lodash';
import { Button } from '@infinigrow/libs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import WidgetHeader from 'components/common/WidgetHeader';
import TimeframeDropdown from 'components/common/TimeframeDropdown';
import CheckListWithPresets from 'components/controls/CheckListWithPresets';
import InfiniteScrollTable from 'components/common/InfiniteScrollTable';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Skeleton from 'components/common/Skeleton';

import { skeletonSmallTableCellSizes } from 'components/common/enums';
import {
  filterDataAccordingActiveColumns,
  getMySignalsIds,
  getIntentStatusIconSrc, getSignalNameById,
} from 'components/widgets/signals/logic/signals';
import {
  accountPrioritizationActiveColumns,
  accountPrioritizationColumnGroupLabels,
  accountPrioritizationColumnsKeys,
  accountPrioritizationColumnsLabels,
  accountPrioritizationSubTitle,
  accountPrioritizationTitle,
  geyPriorityTooltip,
  signalDateFormat,
  signalsPrioritiesLabels,
} from 'components/widgets/signals/enums';
import {
  getColumnOptionsByData,
  tableDataWithFiltersByColumns,
} from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { widgetTypes } from 'components/pages/analyze/enums';
import { formatBudget } from 'components/utils/budget';

import iconXBlue from 'assets/x-blue.svg';
import style from 'styles/salesEnablement/salesEnablement.css';

const styles = style.locals || {};

function AccountPrioritization({
  timeFrame,
  setTimeFrame,
  data,
  isLoaded,
  mySignals,
}) {
  useStyles([style]);

  const [tableData, setTableData] = useState([]);
  const [sortByColumn, setSortByColumn] = useState({});
  const [columnOptions, setColumnOptions] = useState(Object.keys(accountPrioritizationColumnsKeys).filter((columnKey) => columnKey !== accountPrioritizationColumnsKeys.signals));
  const [selectedColumns, setSelectedColumns] = useState(accountPrioritizationActiveColumns);
  const [filtersByColumn, setFiltersByColumn] = useState({});
  const [columnsOptionsForCheckList, setColumnsOptionsForCheckList] = useState([]);
  const [activeColumns, setActiveColumns] = useState(accountPrioritizationActiveColumns);

  const signalIds = getMySignalsIds({ mySignals });

  useEffect(() => {
    const currentTableData = [...data];
    const dataWithSignals = currentTableData.map((item) => {
      const currentAccountSignals = item[accountPrioritizationColumnsKeys.signals];
      delete item[accountPrioritizationColumnsKeys.signals];
      for (const signal of currentAccountSignals) {
        item[signal.signalId] = signal.triggered;
      }

      return item;
    });
    setTableData(dataWithSignals);

    if (dataWithSignals.length > 0) {
      setColumnsOptionsForCheckList(getColumnsOptions());
    }
  }, [data]);

  function getColumnsOptions() {
    const allOptions = [];
    const triggeredSignalsColumnsOptions = [];
    const newColumnIds = [];
    if (mySignals?.length) {
      for (const signal of mySignals) {
        newColumnIds.push(signal.id);
        const item = ({
          value: signal.id,
          label: `Count "${signal.name}"`,
        });
        triggeredSignalsColumnsOptions.push(item);
      }
      const triggeredSignalsGroupColumnsOptions = {
        label: accountPrioritizationColumnGroupLabels.signals,
        options: triggeredSignalsColumnsOptions,
      };
      allOptions.push(triggeredSignalsGroupColumnsOptions);

      setColumnOptions([...new Set([...columnOptions, ...newColumnIds])]);
    }

    const accountStatusColumnsOptions = columnOptions.map((columnKey) => ({
      value: columnKey,
      label: accountPrioritizationColumnsLabels[columnKey],
    }));
    const accountStatusGroupColumnsOptions = {
      label: accountPrioritizationColumnGroupLabels.accountStatus,
      options: accountStatusColumnsOptions,
    };
    allOptions.push(accountStatusGroupColumnsOptions);

    return allOptions;
  }

  function onSelectedColumns({ options }) {
    setActiveColumns(options);
    setSelectedColumns(options);
  }

  function onUpdateFiltersByColumn({ filters }) {
    const filteredByColumnsItems = tableDataWithFiltersByColumns({ tableData: data, filtersByColumn: filters });
    setTableData(filteredByColumnsItems);
    setFiltersByColumn(filters);
  }

  function getTableColumnByKey({ columnKey }) {
    const newColumn = {
      id: columnKey,
      accessor: columnKey,
      header: accountPrioritizationColumnsLabels[columnKey] || columnKey,
      inlineFiltersParams: {
        columnKey,
        selectedSegments: {},
        sortByColumn,
        filtersByColumn,
        setSortByColumn: ({ id, desc }) => setSortByColumn({ id, desc }),
        updateFiltersByColumn: ({ filters }) => onUpdateFiltersByColumn({ filters }),
        isNumeric: true,
      },
      cell: (cellItem) => {
        if (isLoaded || cellItem) {
          return (
            <>
              <EllipsisTooltip text={cellItem}>
                {cellItem}
              </EllipsisTooltip>
            </>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      },
    };

    if (columnKey === accountPrioritizationColumnsKeys.account) {
      const columnFiltersOptions = getColumnOptionsByData({
        selectedSegments: {}, tableData: data, columnKey,
      });
      newColumn.priority = 1;
      newColumn.inlineFiltersParams = {
        ...newColumn.inlineFiltersParams,
        isNumeric: false,
        columnFiltersOptions,
      };
    }

    if (columnKey === accountPrioritizationColumnsKeys.intentStatus) {
      const columnFiltersOptions = getColumnOptionsByData({
        selectedSegments: {}, tableData: data, columnKey,
      });
      newColumn.inlineFiltersParams = {
        ...newColumn.inlineFiltersParams,
        isNumeric: false,
        columnFiltersOptions,
      };
      newColumn.priority = 2;
      newColumn.tooltip = geyPriorityTooltip();
      newColumn.cell = (cellItem) => {
        const priorityLabel = signalsPrioritiesLabels[cellItem];
        const imageSrc = getIntentStatusIconSrc({ value: cellItem });
        if (isLoaded || cellItem) {
          return (
            <div className={classnames(styles.cellWithIcon)}>
              <img src={imageSrc} alt={cellItem} />
              <EllipsisTooltip text={priorityLabel}>
                {priorityLabel}
              </EllipsisTooltip>
            </div>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      };
    }

    if (columnKey === accountPrioritizationColumnsKeys.recentSignal) {
      newColumn.inlineFiltersParams = null;
      newColumn.sortMethod = (a, b) => new Date(a) - new Date(b);
      newColumn.priority = 4;
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          const date = moment(cellItem).format(signalDateFormat);
          return (
            <>
              <EllipsisTooltip text={date}>
                {date}
              </EllipsisTooltip>
            </>
          );
        }
        return <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />;
      };
    }

    if (columnKey === accountPrioritizationColumnsKeys.activeContacts) {
      newColumn.priority = 3;
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          return `${cellItem} ${cellItem > 1 ? 'Contacts' : 'Contact'}`;
        }
        return <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />;
      };
    }

    if (columnKey === accountPrioritizationColumnsKeys.openPipeline) {
      newColumn.priority = 6;
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          return formatBudget(cellItem, true, true);
        }
        return <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />;
      };
    }

    if (signalIds.includes(columnKey)) {
      const columnFiltersOptions = getColumnOptionsByData({
        selectedSegments: {}, tableData: data, columnKey,
      });
      newColumn.header = getSignalNameById({ mySignals, signalId: columnKey });
      newColumn.priority = 5;
      newColumn.inlineFiltersParams = {
        ...newColumn.inlineFiltersParams,
        isNumeric: true,
        columnFiltersOptions,
      };
    }

    return newColumn;
  }

  const tableColumns = activeColumns.map((columnKey) => getTableColumnByKey({ columnKey }));

  return (
    <div className={styles.accountPrioritization}>
      <div className={styles.absoluteExportButton}>
        <Button
          type="primaryBlue"
        >
          Export
        </Button>
      </div>
      <WidgetHeader
        widgetHeaderConfig={{
          title: accountPrioritizationTitle,
          subTitle: accountPrioritizationSubTitle,
          type: widgetTypes.salesEnablement,
        }}
        classNameWidgetTitle={styles.salesEnablementWidgetTitle}
        isHideAddToReport
      >
        <CheckListWithPresets
          options={columnsOptionsForCheckList}
          selectedOptions={selectedColumns}
          setSelectedOptions={(options) => onSelectedColumns({ options })}
          checkListToggleLabel="Select Columns"
          checkListTitle="Manage columns and metrics"
        />
        <TimeframeDropdown
          classNamePopup={styles.timeframeDropdownPopup}
          isShowPopupTitle={false}
          titlePrefix="Timeframe: "
          onChange={(newTimeFrame) => {
            setTimeFrame(newTimeFrame.value, newTimeFrame);
          }}
          timeframe={timeFrame}
        />
        <Button
          icon={iconXBlue}
          iconClassName={styles.plusIcon}
          type="secondaryBlue"
        >
          Add filter
        </Button>
      </WidgetHeader>
      <InfiniteScrollTable
        tableHeight={740}
        tableData={filterDataAccordingActiveColumns({ tableData, activeColumns })}
        tableColumns={orderBy(tableColumns, ['priority'], ['asc'])}
        isLoaded={isLoaded}
        sortByColumn={sortByColumn}
      />
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    attributionStore: {
      timeFrame,
      setTimeFrame,
    },
  }) => ({
    timeFrame,
    setTimeFrame,
  }),
  observer
)(AccountPrioritization));
