import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';
import Dropdown from 'components/controls/Dropdown';
import {
  sourcesLabels,
  unclickableSourceToOptionValue,
  optionsForSignalSource,
} from 'components/widgets/signals/enums';

import style from 'styles/salesEnablement/createSignalPopup.css';

const styles = style.locals || {};

function SignalSourceSection({ setSignalSource, signalSource, flags }) {
  useStyles([style]);
  const sourceOptions = optionsForSignalSource.map((sourceValue) => ({ value: sourceValue, label: sourcesLabels[sourceValue] }));

  function handleChangeSource({ newSource }) {
    for (const [unclickableSource, matchedOptionValue] of Object.entries(unclickableSourceToOptionValue)) {
      if (matchedOptionValue === newSource && flags.salesEnablementUnclickableItems[unclickableSource]) {
        return;
      }
    }

    setSignalSource(newSource);
  }

  return (
    <div className={styles.controllerWrapper}>
      <Dropdown
        selectedKey={signalSource}
        options={sourceOptions}
        onChange={(newSource) => handleChangeSource({ newSource: newSource.value })}
      />
    </div>
  );
}

export default withLDConsumer()(SignalSourceSection);
