import {
  action, computed, decorate, observable,
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';
import serverCommunication from 'data/serverCommunication';
import timeFrameModule from 'modules/timeframe';

import { getWidgetDefinitions } from 'widgetDefinitions';
import { getWidgetsDataV2 } from 'components/pages/analyze/widgetsRequest';
import { widgetsForPollingRequest, widgetsConfig, widgetTypes } from 'components/pages/analyze/enums';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { isEmpty } from 'lodash';

export class QuestionsStore {
  constructor() {
    this.questionText = '';
    this.questionSuggestions = [];
    this.questionWidgetsMetadataResults = {};
    this.questionWidgetsDataResults = {};
    this.questionId = '';
    this.aiAnswersDataResults = {};
    this.sessionId = uuidv4();
    this.currentRequestId = null;
  }

  setQuestionText({ newQuestionText }) {
    this.questionText = newQuestionText;
  }

  async updateWidgetConfig({ configKey, configValue, questionId }) {
    const updatedQuestionId = questionId || this.questionId;
    const widget = { ...this.questionWidgetsMetadataResults[updatedQuestionId] };
    widget.configuration[configKey] = configValue;
    const shouldAppendDataById = !!questionId;
    await this.setQuestionWidgetsMetadataResults({ widget, questionId: updatedQuestionId, shouldAppendDataById });
  }

  async getQuestionSuggestions({ questionText }) {
    this.questionText = questionText;
    this.questionSuggestions = [];
    const region = userStore.userMonthPlan?.region;
    const body = {
      questionText,
      sessionId: this.sessionId,
    };
    let response;
    try {
      response = await serverCommunication.serverRequest('POST', 'attribution/questions/search', JSON.stringify(body), region);
    } catch (error) {
      servicesStore.logger.error('failed to run question search request from questions store', {
        ...body,
        region,
      });

      return [];
    }
    const responseData = await response.json();
    this.questionSuggestions = responseData.suggestions;
    this.currentRequestId = responseData.requestId;

    return this.questionSuggestions;
  }

  async contextualSuggestionsRequest({ usersChoice, requestId, suggestionType }) {
    try {
      return servicesStore.serverCommunication.serverRequest({
        method: 'post',
        route: 'attribution/questions/submit',
        body: {
          requestId,
          usersChoice,
          sessionId: this.sessionId,
          suggestionType,
        },
        queryParams: {
          region: userStore.userMonthPlan?.region,
        },
      });
    } catch (exception) {
      servicesStore.logger.error('failed to send contextual suggestions request', {
        exception,
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
      });
    }
    return {};
  }

  get selectedQuestionWidgetsMetadataResults() {
    return this.questionWidgetsMetadataResults[this.questionId];
  }

  async setQuestionWidgetsMetadataResults({
    widget, questionId, concatResultsPerWidget = {}, parentRequestId, shouldAppendDataById,
  }) {
    this.questionId = questionId;
    this.questionWidgetsMetadataResults[questionId] = widget;
    this.questionWidgetsDataResults.isLoaded = false;

    for (const filter of widget.configuration.filters) {
      if (filter.data.timeFrame) {
        const { startDate: startTS, endDate: endTS } = timeFrameModule.getTimeframeParams({ ...filter.data.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
        filter.data.timeFrame = {
          value: filter.data.timeFrame.value,
          startTS,
          endTS,
        };
      }
    }

    const { startDate, endDate } = timeFrameModule.getTimeframeParams({ ...widget.configuration.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
    const widgetConfig = {
      ...widget.configuration,
      type: widget.type,
      timeFrame: {
        ...widget.configuration.timeFrame,
        startDate: new Date(getTSForTimezone(startDate)),
        endDate: new Date(getTSForTimezone(endDate)),
      },
    };

    const serverRequestTasks = [];
    const relevantWidgetConfig = Object.values(widgetsConfig).find((config) => config.type === widget.type);
    const relatedWidgetsToRun = relevantWidgetConfig?.widgetsToServerRequest || [];

    for (const widgetToRun of relatedWidgetsToRun) {
      const shouldSkipTrendWidgetRequest = widgetToRun === widgetTypes.trendBySegments && !widget.configuration?.isCalculateAdvancedMetrics;
      const shouldSkipUpliftWidgetRequest = widgetToRun === widgetTypes.upliftBySegments && isEmpty(widget.configuration?.upliftBySegmentsParams);
      if (shouldSkipTrendWidgetRequest || shouldSkipUpliftWidgetRequest) {
        continue;
      }

      const widgetDefinitions = getWidgetDefinitions({ widget: widgetToRun });
      const clonedWidgetConfig = widgetDefinitions ? widgetDefinitions.getWidgetConfig({ widgetConfig }) : widgetConfig;

      if (widgetsForPollingRequest.includes(widgetToRun)) {
        const requestData = {
          widget: widgetToRun,
          widgetConfig: clonedWidgetConfig,
          addBaseAnalyzeConfig: false,
          useAccountViewLogic: false,
        };

        if (concatResultsPerWidget[widgetToRun]) {
          requestData.isConcatResult = true;
          requestData.parentRequestId = parentRequestId;
        }
        getWidgetsDataV2(requestData);
        this.questionWidgetsDataResults.isLoaded = true;
        continue;
      }

      const body = {
        region: userStore.userMonthPlan.region,
        requestStoreParams: {
          widgets: [widgetToRun],
          widgetsConfig: { [widgetToRun]: [clonedWidgetConfig] },
        },
        questionId,
      };

      serverRequestTasks.push(this.handleServerRequest({ body }));
    }

    if (shouldAppendDataById && relatedWidgetsToRun.length > 0) {
      if (!this.aiAnswersDataResults[questionId]) {
        this.aiAnswersDataResults[questionId] = {};
      }
      this.aiAnswersDataResults = {
        ...this.aiAnswersDataResults,
        [questionId]: { isLoaded: false },
      };
    }

    const widgetsDataResponse = await Promise.all(serverRequestTasks);
    let updatedQuestionWidgetsDataResults = {};

    for (const responseItem of widgetsDataResponse) {
      for (const [responseWidgetType, widgetResponse] of Object.entries(responseItem)) {
        let newWidgetData = widgetResponse;
        const currentWidgetData = this.questionWidgetsDataResults[responseWidgetType];

        if (concatResultsPerWidget[responseWidgetType] && currentWidgetData) {
          newWidgetData = [...currentWidgetData, ...newWidgetData];
        }

        updatedQuestionWidgetsDataResults = {
          ...updatedQuestionWidgetsDataResults,
          [responseWidgetType]: newWidgetData,
        };
      }
    }

    this.questionWidgetsDataResults = {
      ...updatedQuestionWidgetsDataResults,
      isLoaded: true,
    };

    if (shouldAppendDataById && relatedWidgetsToRun.length > 0) {
      const updatedAIAnswersLoadingResults = { ...this.aiAnswersDataResults };
      updatedAIAnswersLoadingResults[questionId] = {
        ...updatedQuestionWidgetsDataResults,
        isLoaded: true,
      };
      this.aiAnswersDataResults = updatedAIAnswersLoadingResults;
    }
  }

  async handleServerRequest({ body }) {
    const region = userStore.userMonthPlan.region;
    try {
      const response = await serverCommunication.serverRequest('POST', 'analysis/widgets', JSON.stringify(body), region);
      const responseData = await response.json();
      const { data } = responseData;

      return data;
    } catch (exception) {
      servicesStore.logger.error('failed to get question widget data', {
        UID: userStore.userMonthPlan.UID,
        region,
        questionId: body.questionId,
      });
      return {};
    }
  }

  async updateAudienceTableSearchQuery({ searchQuery }) {
    const widget = { ...this.selectedQuestionWidgetsMetadataResults };
    widget.configuration.searchQuery = searchQuery;
    widget.configuration.clientCursor = 0;

    await this.setQuestionWidgetsMetadataResults({ widget, questionId: this.questionId });
  }

  async updateAudienceTableClientCursor({ clientCursor, parentRequestId }) {
    const widget = { ...this.selectedQuestionWidgetsMetadataResults };
    widget.configuration.clientCursor = clientCursor;

    await this.setQuestionWidgetsMetadataResults({
      widget,
      questionId: this.questionId,
      concatResultsPerWidget: {
        [widgetTypes.journeysTable]: true,
      },
      parentRequestId,
    });
  }

  setAIAnswersDataResults({ data }) {
    this.aiAnswersDataResults = data;
  }

  setCurrentRequestId({ requestId }) {
    this.currentRequestId = requestId;
  }
}

decorate(QuestionsStore, {
  questionText: observable,
  questionId: observable,
  questionSuggestions: observable,
  questionWidgetsMetadataResults: observable,
  questionWidgetsDataResults: observable,
  getQuestionSuggestions: action.bound,
  setQuestionText: action.bound,
  setQuestionWidgetsMetadataResults: action.bound,
  updateWidgetConfig: action.bound,
  selectedQuestionWidgetsMetadataResults: computed,
  updateAudienceTableSearchQuery: action.bound,
  updateAudienceTableClientCursor: action.bound,
  aiAnswersDataResults: observable,
  setAIAnswersDataResults: action.bound,
  contextualSuggestionsRequest: action.bound,
  currentRequestId: observable,
  setCurrentRequestId: action.bound,
});

export default new QuestionsStore();
