exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".breadcrumbsTitle__breadcrumbsTitle-3Xz{font-size:28px;font-weight:600;color:var(--colorsGeneralIgBlack);margin:0 0 32px;display:flex;gap:12px;align-items:center}.breadcrumbsTitle__breadcrumbsTitle-3Xz>div{text-transform:capitalize}.breadcrumbsTitle__breadcrumbsIcon-2Kb{background:var(--colorsGeneralIgBlack);-webkit-mask:url(/assets/analyze-icons/expandIcon.svg) center center no-repeat;mask:url(/assets/analyze-icons/expandIcon.svg) center center no-repeat;height:14px;width:8px;margin-top:4px;transform:rotate(180deg)}", ""]);

// exports
exports.locals = {
	"breadcrumbsTitle": "breadcrumbsTitle__breadcrumbsTitle-3Xz",
	"breadcrumbsTitle": "breadcrumbsTitle__breadcrumbsTitle-3Xz",
	"breadcrumbsIcon": "breadcrumbsTitle__breadcrumbsIcon-2Kb",
	"breadcrumbsIcon": "breadcrumbsTitle__breadcrumbsIcon-2Kb"
};