import { flatMapDeep } from 'lodash';
import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';

const getTitles = ({ data = [] }) => {
  const uniqueTitlesSet = new Set();
  for (const dataItem of data) {
    for (const key of Object.keys(dataItem)) {
      uniqueTitlesSet.add(key);
    }
  }
  return Array.from(uniqueTitlesSet);
};

export function getJourneysEventData({ widgetConfig }) {
  return ({
    widget: widgetConfig.type,
    primaryDimension: null,
    secondaryDimension: null,
    metric: [],
    frequency: null,
    timeFrame: widgetConfig?.timeFrameLabel,
  });
}

export const parsedJourneysArrayData = ({ data = [] }) => {
  const titles = getTitles({ data });
  const parsed = data.map((item) => titles.map((col) => (Array.isArray(item[col]) ? item[col].join(' ,') : item[col])));
  parsed.unshift(titles);
  return parsed;
};

export const filterJourneysColumns = ({ data, selectedColumns, leadSourcesIdToLabelMap }) => data?.map((journey) => {
  const filteredObj = {};
  for (const column of selectedColumns) {
    if (column.key in journey) {
      let value = column.getData ? column.getData(journey[column.key]) : journey[column.key];
      if (value?.length > 32767) {
        servicesStore.logger.info('value too long - trimmed after 32764 characters', {
          UID: userStore.userMonthPlan.UID,
          value,
        });
        value = `${value.substring(0, 32764)}...`;
      }

      filteredObj[column.label] = value;
    } else if (journey?.leadSources && column.key in journey.leadSources) {
      const objectLeadSourcesKey = leadSourcesIdToLabelMap[column.key] || column.key;
      filteredObj[objectLeadSourcesKey] = flatMapDeep(journey.leadSources[column.key]);
    }
  }
  return filteredObj;
});
