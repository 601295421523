import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import { Checkbox } from '@infinigrow/libs';

import attributionStyles from 'styles/analyze/analyze-attribution-table.css';

const attributionStyle = attributionStyles.locals || {};

export default function CheckboxWithFrame({
  checked, onChange, text, disabled,
}) {
  useStyles([attributionStyles]);

  return (
    <div className={classnames(attributionStyle.hideNoValueWrapper, disabled && attributionStyle.hideNoValueWrapperDisabled)}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        className={attributionStyle.checkboxLabel}
        disabled={disabled}
      >
        {text}
      </Checkbox>
    </div>
  );
}
