exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".quickFilters__filterActionMenu-EOr{background:url(/assets/navigationMenu/3dots.svg) 50% no-repeat;width:24px;height:24px;cursor:pointer;margin-top:8px}.quickFilters__filterButton-3r3{flex-wrap:nowrap}.quickFilters__quickFiltersContainer-Fs8{display:flex;flex-wrap:wrap;gap:12px}.quickFilters__filtersContainer-3U4{display:flex;flex-direction:column;gap:12px;position:relative}.quickFilters__filtersContainer-3U4>div{position:revert}", ""]);

// exports
exports.locals = {
	"filterActionMenu": "quickFilters__filterActionMenu-EOr",
	"filterActionMenu": "quickFilters__filterActionMenu-EOr",
	"filterButton": "quickFilters__filterButton-3r3",
	"filterButton": "quickFilters__filterButton-3r3",
	"quickFiltersContainer": "quickFilters__quickFiltersContainer-Fs8",
	"quickFiltersContainer": "quickFilters__quickFiltersContainer-Fs8",
	"filtersContainer": "quickFilters__filtersContainer-3U4",
	"filtersContainer": "quickFilters__filtersContainer-3U4"
};