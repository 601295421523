import React from 'react';
import { inject, observer } from 'mobx-react';
import { orderBy } from 'lodash';

import Component from 'components/Component';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import InfiniteScrollTable from 'components/common/InfiniteScrollTable';
import Skeleton from 'components/common/Skeleton';

import attributionStore from 'stores/attributionStore';

import { compose } from 'components/utils/utils';
import { getWidgetsData } from 'components/pages/analyze/widgetsRequest';
import { widgetTypes } from 'components/pages/analyze/enums';
import { inspectTableLimit } from 'components/pages/analyze/inspectData/enums';
import { getTableColumnsNames } from 'components/pages/analyze/inspectData/logic/InspectAttributionTable';
import { formatIndicatorDisplay, getRevenueFunnel, getPipelineFunnel } from 'components/utils/indicators';
import { getWidgetHashKey } from 'stores/logic/widgetsAnalysisStore';
import { skeletonSmallTableCellSizes } from 'components/common/enums';

import style from 'styles/analyze/inspectData/inspect.css';

const enhance = compose(
  inject(({
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.funnelTransitionsBySegments]: funnelTransitionsBySegments,
      },
      restIsLoadedWidgetIndication,
    },
    userStore: {
      getMetricNickname,
    },
  }) => ({
    funnelTransitionsBySegments,
    restIsLoadedWidgetIndication,
    getMetricNickname,
  })),
  observer
);

class InspectAttributionTable extends Component {
  style = style;

  constructor(props) {
    super(props);
    this.state = {
      isLoadingMore: false,
      inputSearchValue: '',
      resultKeyName: this.getResultKeyName(),
    };
  }

  componentDidMount() {
    const {
      funnelTransitionsBySegments, filters, timeFrame, attributionModel,
    } = this.props;

    if (!funnelTransitionsBySegments?.[this.state.resultKeyName]?.isLoaded) {
      getWidgetsData({
        widgets: [widgetTypes.funnelTransitionsBySegments],
        configPerWidget: {
          [widgetTypes.funnelTransitionsBySegments]: [{
            funnelTransitionsBySegmentsParams: this.getFunnelTransitionsBySegmentsParams({ offset: 0 }),
            filters,
            timeFrame,
            attributionModel,
          }],
        },
        resultKeyName: this.state.resultKeyName,
      });
    }
  }

  getResultKeyName({ inputSearchValue = this.state?.inputSearchValue } = {}) {
    const { filters, timeFrame, attributionModel } = this.props;
    const widgetConfig = {
      funnelTransitionsBySegmentsParams: this.getFunnelTransitionsBySegmentsParams({
        offset: 0,
        searchValue: inputSearchValue,
      }),
      filters,
      timeFrame,
      attributionModel,
    };
    const baseConfig = attributionStore.getAnalyzeBaseConfig({});
    const widgetHashKey = getWidgetHashKey({
      widget: widgetTypes.funnelTransitionsBySegments,
      widgetConfig: {
        ...baseConfig,
        ...widgetConfig,
      },
    });
    return `${widgetHashKey}-${this.props.kpiFocus}-${this.props.firstSegment}-${this.props.secondSegment}-${this.props.firstSegmentValue}-${this.props.secondSegmentValue}-${inputSearchValue}`;
  }

  getFunnelTransitionsBySegmentsParams({ offset = 0, searchValue = this.state?.inputSearchValue }) {
    const {
      firstSegment,
      firstSegmentValue,
      secondSegment,
      secondSegmentValue,
      kpiFocus,
    } = this.props;

    let selectedFunnel = kpiFocus;
    if (this.props.metricType === 'pipeline') {
      selectedFunnel = getPipelineFunnel();
    }
    if (this.props.metricType === 'revenue') {
      selectedFunnel = getRevenueFunnel();
    }

    return {
      firstSegment,
      firstSegmentValue,
      secondSegment,
      secondSegmentValue,
      offset,
      limit: inspectTableLimit,
      searchValue,
      selectedFunnel,
      metricType: this.props.metricType,
    };
  }

  getTableColumnByKey({
    columnKey, firstSegmentLabel, kpiFocus, totalsByMetric, isAttribution, isLoaded,
  }) {
    const { setDrillDownPopupEntityId } = this.props;
    const footerTotal = totalsByMetric.find((total) => total.key === columnKey)?.value;
    const headerMetricLabel = columnKey === 'pipeline' || columnKey === 'revenue' ? columnKey : this.props.getMetricNickname({ segment: columnKey, metric: `${columnKey}${kpiFocus}` });
    const newColumn = {
      id: columnKey,
      priority: 100,
      header: headerMetricLabel,
      footer: footerTotal ? formatIndicatorDisplay(columnKey, footerTotal, false, true, true, 2) : null,
      cell: (item) => {
        const isItemClickable = isAttribution && columnKey === 'entityValue';
        const cellValue = isNaN(item[columnKey]) ? item[columnKey] : formatIndicatorDisplay(columnKey, item[columnKey], false, true, true, 2);
        if (isLoaded) {
          return (
            <div>
              <EllipsisTooltip
                text={cellValue}
                onClick={isItemClickable ? () => setDrillDownPopupEntityId(item.entityId, item[columnKey]) : null}
                className={isItemClickable ? this.classes.clickable : null}
              >
                {cellValue}
              </EllipsisTooltip>
            </div>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      },
    };

    if (columnKey === 'entityValue') {
      return {
        ...newColumn,
        header: firstSegmentLabel,
        footer: 'Total',
        priority: 1,
      };
    }

    if (columnKey === 'touched') {
      return {
        ...newColumn,
        priority: 2,
      };
    }

    return newColumn;
  }

  async handleShowMoreClick() {
    this.setState({ isLoadingMore: true });
    const { filters, timeFrame, attributionModel } = this.props;
    await getWidgetsData({
      widgets: [widgetTypes.funnelTransitionsBySegments],
      configPerWidget: {
        [widgetTypes.funnelTransitionsBySegments]: [{
          funnelTransitionsBySegmentsParams: this.getFunnelTransitionsBySegmentsParams({
            offset: this.props.funnelTransitionsBySegments?.[this.state.resultKeyName]?.data?.results?.length || 0,
          }),
          filters,
          timeFrame,
          attributionModel,
        }],
      },
      isConcatResult: true,
      resultKeyName: this.state.resultKeyName,
    });
    this.setState({ isLoadingMore: false });
  }

  async handleSearchQuery(value) {
    const newResultKeyName = this.getResultKeyName({ inputSearchValue: value });
    this.setState({ inputSearchValue: value, resultKeyName: newResultKeyName });
    const { filters, timeFrame, attributionModel } = this.props;
    await getWidgetsData({
      widgets: [widgetTypes.funnelTransitionsBySegments],
      configPerWidget: {
        [widgetTypes.funnelTransitionsBySegments]: [{
          funnelTransitionsBySegmentsParams: this.getFunnelTransitionsBySegmentsParams({
            offset: 0,
            searchValue: value,
          }),
          filters,
          timeFrame,
          attributionModel,
        }],
      },
      resultKeyName: newResultKeyName,
    });
  }

  render() {
    const { funnelTransitionsBySegments, kpiFocus, totalsByMetric } = this.props;
    const { isLoadingMore, inputSearchValue, resultKeyName } = this.state;

    const isAllLoaded = funnelTransitionsBySegments?.[resultKeyName]?.isLoaded || isLoadingMore;
    const tableData = funnelTransitionsBySegments?.[resultKeyName]?.data?.results || [];
    const isAttribution = funnelTransitionsBySegments?.[resultKeyName]?.data?.isAttribution;
    const firstSegmentLabel = funnelTransitionsBySegments?.[resultKeyName]?.data?.type;

    let tableDataKeys = getTableColumnsNames({ tableData });
    if (tableDataKeys.length === 0) {
      tableDataKeys = ['entityValue', 'touched'];
    }
    let tableColumns = tableDataKeys.map((key) => this.getTableColumnByKey({
      columnKey: key, firstSegmentLabel, kpiFocus, totalsByMetric, isAttribution, isLoaded: isAllLoaded,
    }));
    tableColumns = orderBy(tableColumns, ['priority'], ['asc']);

    const filteredTableColumns = tableColumns.filter((column) => column.footer != null);
    const isSearchIsDisabled = !isAllLoaded || (tableData.length === 0 && inputSearchValue === '');

    return (
      <InfiniteScrollTable
        tableData={tableData}
        tableColumns={filteredTableColumns}
        isLoadingMore={isLoadingMore}
        handleShowMoreClick={() => this.handleShowMoreClick()}
        hasMoreData={funnelTransitionsBySegments?.[resultKeyName]?.data?.hasMore}
        defaultPageSize={funnelTransitionsBySegments?.[resultKeyName]?.data?.results?.length}
        onSearch={(value) => this.handleSearchQuery(value)}
        isSearchIsDisabled={isSearchIsDisabled}
        isLoaded={isAllLoaded}
        skeletonRowsCount={5}
      />
    );
  }
}

export default enhance(InspectAttributionTable);
