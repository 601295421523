exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".regionsMenu__userRegion-3nr{font-size:12px;font-weight:600;color:var(--colorsTextsTextGray);white-space:nowrap}.regionsMenu__dropdownPopup-3_B{min-width:160px;top:56px;right:0;left:auto;padding:4px 0;min-height:auto}.regionsMenu__createNewRow-3pg{border-bottom:1px solid var(--grayLine);padding:12px;color:#707ea7;font-size:14px;line-height:14px}.regionsMenu__optionTagRow-3Wr{display:flex;cursor:pointer;align-items:center;justify-content:space-between;gap:8px;flex:1 0 0;border-radius:4px;padding:12px}.regionsMenu__editButton-3mt{display:none;background:url(/assets/edit-blue.svg) 50% no-repeat;background-size:contain;padding-right:12px;width:16px;height:16px}.regionsMenu__optionTagRow-3Wr:hover .regionsMenu__editButton-3mt{display:block}.regionsMenu__optionTagRow-3Wr:hover{background-color:var(--lightGrayBackground)}.regionsMenu__optionTagName-1Dk{width:100%;text-indent:12px;color:#707ea7;font-size:14px}.regionsMenu__optionTagName-1Dk:hover{cursor:pointer}", ""]);

// exports
exports.locals = {
	"userRegion": "regionsMenu__userRegion-3nr",
	"userRegion": "regionsMenu__userRegion-3nr",
	"dropdownPopup": "regionsMenu__dropdownPopup-3_B",
	"dropdownPopup": "regionsMenu__dropdownPopup-3_B",
	"optionTagRow": "regionsMenu__optionTagRow-3Wr",
	"optionTagRow": "regionsMenu__optionTagRow-3Wr",
	"editButton": "regionsMenu__editButton-3mt",
	"editButton": "regionsMenu__editButton-3mt",
	"createNewRow": "regionsMenu__createNewRow-3pg",
	"createNewRow": "regionsMenu__createNewRow-3pg",
	"optionTagName": "regionsMenu__optionTagName-1Dk",
	"optionTagName": "regionsMenu__optionTagName-1Dk"
};