import React from 'react';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';
import Dropdown from 'components/controls/Dropdown';
import Textfield from 'components/controls/Textfield';
import ContainsSelect from 'components/pages/users/Filters/FilterPanel/UI/ContainsSelect';

import { COMPARISON_TOGGLE, CONTAINS } from 'components/utils/filters';
import {
  signalsActivityTypesLabels,
  signalsActivityKeys,
  unclickableItemsKeys,
  signalActivityOriginWithEqualsToDropdown,
  signalActivityComparatorWithDropdown,
  unclickableActivityOriginToOptionValue,
  getOptionsByKey,
  unclickableSignalOperatorOptionValue,
} from 'components/widgets/signals/enums';

import iconXBlue from 'assets/x-blue.svg';
import style from 'styles/salesEnablement/createSignalPopup.css';

const styles = style.locals || {};

function SignalActivitySection({ newActivities, setNewActivities, flags }) {
  useStyles([style]);

  const activityOptions = Object.keys(signalsActivityKeys).map((sourceValue) => ({ value: sourceValue, label: signalsActivityTypesLabels[sourceValue] }));

  function onChangeContainsValues({ values = [], index }) {
    const containsItems = values.map((item) => item.label);
    addSignalActivityProperty({ activityProperty: { value: containsItems }, index });
  }

  function handleSignalActivityChange({ activityProperty, index }) {
    const allUnclickableItemsValues = [...Object.entries(unclickableActivityOriginToOptionValue), ...Object.entries(unclickableSignalOperatorOptionValue)];
    for (const [unclickableOrigin, matchedOptionValue] of allUnclickableItemsValues) {
      if ((matchedOptionValue === activityProperty.key || matchedOptionValue === activityProperty.operator)
          && flags.salesEnablementUnclickableItems[unclickableOrigin]) {
        return;
      }
    }
    addSignalActivityProperty({ activityProperty, index });
  }

  function addSignalActivityProperty({ activityProperty, index }) {
    if (index === undefined) {
      setNewActivities([...newActivities, activityProperty]);
      return;
    }
    const currentActivity = { ...newActivities[index], ...activityProperty };
    const newActivityName = getActivityName({ activity: currentActivity });
    const updatedActivities = [...newActivities];
    updatedActivities[index] = { ...currentActivity, name: newActivityName };
    setNewActivities(updatedActivities);
  }

  function getActivityName({ activity }) {
    if (activity.key && activity.value && activity.occurrence && activity.operator) {
      if (activity.operator === CONTAINS) {
        return `Visited ${activity.value.join(', ')}`;
      }
    }
    return '';
  }

  return (
    <>
      <div className={styles.controllerTitle}>
        An account had interacted with
      </div>
      <>
        {newActivities.map((activity, index) => (
          <>
            <div className={styles.controllerWrapper} key={`${activity}-key`}>
              <Dropdown
                selectedKey={activity.key}
                options={activityOptions}
                onChange={(item) => handleSignalActivityChange({ activityProperty: { key: item.value }, index })}
              />
            </div>
            {signalActivityOriginWithEqualsToDropdown.includes(activity.key) ? (
              <>
                <div className={styles.controllerWrapper} key={`${activity}-comparison`}>
                  <Dropdown
                    selectedKey={activity.operator}
                    options={Object.entries(COMPARISON_TOGGLE).map(([value, label]) => ({ value, label }))}
                    onChange={(item) => handleSignalActivityChange({ activityProperty: { operator: item.value }, index })}
                  />
                </div>
                <div className={styles.controllerWrapper} key={`${activity}-value`}>
                  {signalActivityComparatorWithDropdown.includes(activity.operator)
                    ? (
                      <Dropdown
                        selectedKey={activity.value}
                        options={getOptionsByKey({ key: activity.key })}
                        onChange={(item) => addSignalActivityProperty({ activityProperty: { value: item.value }, index })}
                      />
                    )
                    : (
                      <ContainsSelect
                        controlWidth="100%"
                        containsValue={activity.value?.map((item) => ({
                          label: item, value: item,
                        }))}
                        containsValueHandler={(values) => onChangeContainsValues({ values, index })}
                      />

                    )}
                </div>
              </>
            ) : null}
            <div className={classnames(styles.inputWrapper, styles.additionalConfigurationComplexText)}>
              Occurs
              <Textfield
                value={activity.occurrence}
                style={{ width: 60 }}
                onChange={(event) => addSignalActivityProperty({ activityProperty: { occurrence: event.target.value }, index })}
                type="number"
                min="0"
              />
              times
            </div>
          </>
        ))}
        <div className={styles.endOfSection}>
          <Button
            type="secondaryBlue"
            icon={iconXBlue}
            iconClassName={styles.icon}
            onClick={() => (flags.salesEnablementUnclickableItems[unclickableItemsKeys.newSignalCreationAddActivity] ? null : addSignalActivityProperty({ key: '', value: 0 }))}
          >
            AND
          </Button>
        </div>
      </>
    </>
  );
}

export default withLDConsumer()(SignalActivitySection);
