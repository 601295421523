import React, {
  useEffect, useRef, useState,
} from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import history from 'history';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import FollowUpQuestionBox from 'components/pages/questions/FollowUpQuestionBox';
import AskQuestionInput from 'components/pages/questions/AskQuestionInput';
import Suggestions from 'components/pages/questions/Suggestions';
import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';
import servicesStore from 'stores/servicesStore';
import BetaTag from 'components/common/BetaTag';

import { Events } from 'trackers/analytics/enums';
import {
  defaultSuggestionsBox,
  suggestionsResultsByType,
  getMockSuggestionsTypeByText,
  suggestionsFloatBoxByType,
} from 'components/pages/questions/logic/mockAIAnswers';
import { getComponentAccordingWidgetType, getComponentProps } from 'components/pages/reports/logic/reports';
import { getWidgetConfigWithPlaceHolderValues } from 'components/pages/questions/logic/askQuestion';
import { splitSuggestionTextToObjects, getWidgetTitle } from 'components/pages/questions/logic/suggestionItem';
import { suggestionTypes } from 'components/pages/questions/enums';

import style from 'styles/questions/ai-answers.css';
import questionsStyle from 'styles/questions/questions.css';

const styles = style.locals || {};
const questionsStyles = questionsStyle.locals || {};

function AIAnswers({
  flags,
  setQuestionWidgetsMetadataResults,
  aiAnswersDataResults,
  questionWidgetsMetadataResults,
  getQuestionSuggestions,
  contextualSuggestionsRequest,
  currentRequestId,
}) {
  useStyles([style, questionsStyle]);

  if (!flags.aiAnswersNewUi) {
    history.push('/questions');
    return null;
  }

  const [answersWidgets, setAnswersWidgets] = useState([]);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [isSuggestionsPopupOpen, setIsSuggestionsPopupOpen] = useState(false);
  const [questionInputText, setQuestionInputText] = useState('');
  const [isLoadedFollowUpQuestions, setIsLoadedFollowUpQuestions] = useState(true);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);

  const askQuestionRef = useRef(null);
  const lastWidgetRef = useRef(null);

  useOnClickOutside(askQuestionRef, () => {
    setIsSuggestionsPopupOpen(false);
  });

  useEffect(() => {
    getFollowUpQuestions({});
  }, []);

  useEffect(() => {
    if (isEmpty(aiAnswersDataResults)) {
      setAnswersWidgets([]);
    }
  }, [aiAnswersDataResults]);

  useEffect(() => {
    if (questionInputText !== '') {
      const delayDebounce = setTimeout(async () => {
        setSuggestionsData([]);
        setIsSuggestionsPopupOpen(true);
        trackQuestion({ eventName: Events.questionSearched });

        if (flags.aiAnswersDemoOnly) {
          if (suggestionsData.length > 0) {
            setSuggestionsData([]);
          }
          const suggestionsType = getMockSuggestionsTypeByText({ text: questionInputText });
          setTimeout(() => setSuggestionsData(suggestionsResultsByType[suggestionsType]), 500);
          return;
        }

        const questionSuggestions = await getQuestionSuggestions({ questionText: questionInputText });
        setSuggestionsData(questionSuggestions);
      }, 1000);
      return () => clearTimeout(delayDebounce);
    }
    return () => ({});
  }, [questionInputText]);

  useEffect(() => {
    if (lastWidgetRef?.current) {
      lastWidgetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [answersWidgets]);

  // eslint-disable-next-line no-unused-vars
  async function getRequestFollowUpQuestions({ questionText }) {
    setIsLoadedFollowUpQuestions(false);
    // // TODO: add ep to get follow up questions by questionText
    setIsLoadedFollowUpQuestions(true);
    return [];
  }

  async function getFollowUpQuestions({ question, placeHoldersValues }) {
    if (flags.aiAnswersDemoOnly) {
      if (!question) {
        setFollowUpQuestions(defaultSuggestionsBox);
        return;
      }
      const questionType = question.questionType;
      setFollowUpQuestions(suggestionsFloatBoxByType[questionType] || []);
      return;
    }

    let questionText = '';
    if (question && placeHoldersValues) {
      const questionTextArray = splitSuggestionTextToObjects({ text: question.questionText, placeHoldersTypes: question.placeHoldersTypes, placeHoldersValues });
      questionText = getWidgetTitle({ questionTextArray });
    }
    const requestFollowUpQuestions = await getRequestFollowUpQuestions({ questionText });
    setFollowUpQuestions(requestFollowUpQuestions);
  }

  function trackQuestion({ eventName, additionalProperties = {} }) {
    servicesStore.eventTracker.track({
      eventName,
      properties: {
        searchInput: questionInputText,
        ...additionalProperties,
      },
    });
  }

  function sendContextualSuggestionsRequest({ question, placeHoldersValues, suggestionType }) {
    const questionTextArray = splitSuggestionTextToObjects({ text: question.questionText, placeHoldersTypes: question.placeHoldersTypes, placeHoldersValues });
    const questionText = getWidgetTitle({ questionTextArray });
    contextualSuggestionsRequest({ usersChoice: questionText, requestId: currentRequestId, suggestionType });
  }

  function onSelectedSuggestion({ questionId, placeHoldersValues }) {
    const questionData = suggestionsData.find((question) => question.questionId === questionId);
    if (questionData?.widget) {
      const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
      setQuestionWidgetsMetadataResults({ widget: widgetAsJson, questionId, shouldAppendDataById: true });
      setIsSuggestionsPopupOpen(false);
      setAnswersWidgets([...answersWidgets, { ...questionData.widget, questionId }]);
      setQuestionInputText('');
      trackQuestion({ eventName: Events.questionAsked, additionalProperties: { question: questionData } });
      getFollowUpQuestions({ question: questionData, placeHoldersValues });
      sendContextualSuggestionsRequest({ question: questionData, placeHoldersValues, suggestionType: suggestionTypes.search });
    }
  }

  function onSelectedSuggestionFloatBox({ questionData, placeHoldersValues }) {
    const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
    setQuestionWidgetsMetadataResults({ widget: widgetAsJson, questionId: questionData.questionId, shouldAppendDataById: true });
    setAnswersWidgets([...answersWidgets, { ...questionData.widget, questionId: questionData.questionId }]);
    getFollowUpQuestions({ question: questionData, placeHoldersValues });
    sendContextualSuggestionsRequest({ question: questionData, placeHoldersValues, suggestionType: suggestionTypes.followup });
  }

  const shouldShowFollowUpQuestions = answersWidgets.length > 0 && followUpQuestions.length > 0 && questionInputText === '' && !isSuggestionsPopupOpen;

  return (
    <div className={styles.answersSuggestionWrapper}>
      {answersWidgets.length === 0 ? (
        <div className={styles.answersInitialState}>
          <div className={styles.answersTitle}>
            <div className={styles.answersIcon} />
            AI Answers
            <BetaTag />
          </div>
          <div className={styles.answersSubTitle}>Ask questions in natural language, and InfiniGrow will automatically generate an answer and suggest follow-up questions.</div>
          <div className={styles.followUpQuestionBoxWrapper}>
            {followUpQuestions.map((suggestion) => (
              <FollowUpQuestionBox
                key={suggestion.id}
                content={suggestion.content}
                onClick={suggestion.question ? () => {
                  onSelectedSuggestionFloatBox({ questionData: suggestion.question, placeHoldersValues: suggestion.question.defaultPlaceHoldersValues });
                } : null}
                isLoaded={isLoadedFollowUpQuestions}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.answersResults}>
          {answersWidgets.map((widget, index) => {
            const currentAIAnswersLoadingResults = aiAnswersDataResults[widget.questionId] || {};
            const currentWidgetsMetadataResults = questionWidgetsMetadataResults[widget.questionId] || {};
            const widgetProps = getComponentProps({ widgetData: { ...widget, ...currentWidgetsMetadataResults, ...currentAIAnswersLoadingResults }, isInReports: false, isShowTimeframeSelect: true });
            const WidgetComponent = getComponentAccordingWidgetType({ widgetType: widget.type });
            const answerKey = `widget-${currentWidgetsMetadataResults.type}-${index}`;
            return (
              <div key={answerKey}>
                {index === answersWidgets.length - 1 && answersWidgets.length > 1 && <div ref={lastWidgetRef} />}
                <WidgetComponent
                  {...widgetProps}
                  isLoaded={currentAIAnswersLoadingResults.isLoaded}
                  isLoadedTrendData={currentAIAnswersLoadingResults.isLoadedTrendData}
                  isReadOnly
                  emptyStateComponent={(
                    <EmptyStateWithImage
                      imageClassName={styles.answersEmptyStateImage}
                      title="This question did not return any results"
                      subTitle="Try changing the filters or the timeframe"
                      height="460px"
                      isContentCentered
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
      )}

      <div className={styles.aiAnswersAskWrapper} ref={askQuestionRef}>
        {shouldShowFollowUpQuestions ? (
          <div className={styles.followUpQuestionBoxWrapper}>
            {followUpQuestions.map((suggestion) => (
              <FollowUpQuestionBox
                key={suggestion.id}
                content={suggestion.content}
                iconPosition="left"
                onClick={suggestion.question ? () => {
                  onSelectedSuggestionFloatBox({ questionData: suggestion.question, placeHoldersValues: suggestion.question.defaultPlaceHoldersValues });
                } : null}
                isLoaded={isLoadedFollowUpQuestions}
              />
            ))}
          </div>
        ) : null}
        <div className={styles.aiAnswersAskInputWrapper}>
          <AskQuestionInput
            value={questionInputText}
            placeholder={answersWidgets.length === 0 ? 'Ask any question' : 'Ask any follow-up question'}
            onFocus={() => (isSuggestionsPopupOpen || !questionInputText ? null : setIsSuggestionsPopupOpen(true))}
            onChange={(event) => setQuestionInputText(event.target.value)}
          />
          <div className={classnames(styles.aiAnswersResultsWrapper, questionsStyles.questionResultsWrapper, isSuggestionsPopupOpen && styles.aiAnswersResultsHeight)}>
            <Suggestions
              data={suggestionsData}
              onSelectSuggestion={({ questionId, placeHoldersValues }) => onSelectedSuggestion({ questionId, placeHoldersValues })}
              isShowHeaderTitle={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    questionsStore: {
      setQuestionWidgetsMetadataResults,
      questionWidgetsMetadataResults,
      aiAnswersDataResults,
      getQuestionSuggestions,
      contextualSuggestionsRequest,
      currentRequestId,
    },
  }) => ({
    setQuestionWidgetsMetadataResults,
    questionWidgetsMetadataResults,
    aiAnswersDataResults,
    getQuestionSuggestions,
    contextualSuggestionsRequest,
    currentRequestId,
  }),
  observer
)(AIAnswers));
