import React from 'react';

import useStyles from 'hooks/useStyles';

import ToggleWithIcons from 'components/controls/ToggleWithIcons';
import Textfield from 'components/controls/Textfield';
import SelectTimeFrameWithCustom from 'components/controls/SelectTimeFrameWithCustom';
import CustomFiltersPanel from 'components/pages/users/Filters/CustomFiltersPanel';

import { widgetSelectionToggleIcons } from 'components/pages/widgetBuilder/enums';

import style from 'styles/reports/widget-builder.css';

const styles = style.locals || {};

function WidgetBuilderProperties({
  selectedWidgetProperties,
  onSelectedWidgetProperties,
  PropertiesComponent,
  widgetName,
  setWidgetName,
  isAllFilledAreFilledForRequest,
  onChangeWidgetKind,
  widgetProps,
}) {
  useStyles([style]);

  return (
    <div className={styles.widgetProperties}>
      <div className={styles.row}>
        <div className={styles.title}>Widget name</div>
        <Textfield
          dataTestId="widgetTitle"
          value={widgetName.name}
          onChange={(event) => setWidgetName({ value: event.target.value })}
          placeHolder="Enter widget name"
          isShowError={widgetName.errorMessage}
          inputErrorMessage={widgetName.errorMessage}
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>Select visualization</div>
        <ToggleWithIcons
          options={widgetSelectionToggleIcons}
          selected={selectedWidgetProperties.widgetKind}
          onChange={(kind) => onChangeWidgetKind(kind)}
        />
      </div>

      <PropertiesComponent
        selectedWidgetProperties={selectedWidgetProperties}
        onSelectedWidgetProperties={onSelectedWidgetProperties}
        widgetProps={widgetProps}
      />

      <div className={styles.row}>
        <div className={styles.title}>Timeframe</div>
        <SelectTimeFrameWithCustom
          widgetTimeFrame={selectedWidgetProperties.timeFrame}
          updateWidgetTimeframe={(timeFrame) => onSelectedWidgetProperties({ values: { timeFrame } })}
          isShowShortLabel
          isDropdownAsLink={false}
          disabled={!isAllFilledAreFilledForRequest}
        />
      </div>

      <div className={styles.row}>
        <CustomFiltersPanel
          filters={selectedWidgetProperties.filters}
          setFilters={(newFilters) => onSelectedWidgetProperties({ values: { filters: newFilters } })}
          buttonText="Add Filters (optional)"
          filtersPopupStyle={styles.filtersPopup}
          disabled={!isAllFilledAreFilledForRequest}
        />
      </div>
    </div>
  );
}

export default WidgetBuilderProperties;
