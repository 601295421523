import {
  action, computed, decorate, observable,
} from 'mobx';
import { startCase, camelCase } from 'lodash';

import config from 'components/utils/Configuration';
import { Coralogix } from 'modules/infra/coralogix';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';

import { getFunnelsFromUMP } from 'stores/logic/userStore';
import { getChannelsWithProps } from 'components/utils/channels';

class UserStore {
  userAccount = null;

  userMonthPlan = null;

  currencies = null;

  userChannelsSchema = null;

  attributionMappingRules = undefined;

  userFunnels = [];

  userMetrics = [];

  commonSegments = [];

  integrationsLastUpdateTime = {
    crm: {},
  };

  userRegionsNicknames = [];

  userBusinessUnitsWithIds = [];

  userOnboardingPreset = null;

  integrationsConfig = null;

  constructor() {
    this.workflows = {};
  }

  setUserAccount(data) {
    this.userAccount = data;

    servicesStore.createEventTracker({ userAccount: data });
  }

  updateUserAccountByKey({ key, value }) {
    this.userAccount[key] = value;
  }

  setUserRegionsNicknames({ userRegions }) {
    this.userRegionsNicknames = userRegions.filter((region) => region);
  }

  setUserBusinessUnitsWithIds({ businessUnit }) {
    this.userBusinessUnitsWithIds = businessUnit;
  }

  setUserMonthPlan(data) {
    this.userMonthPlan = data;
    const userProfile = servicesStore.authService.getProfileSync();
    servicesStore.logger.setDefaultLogParams({
      logParams: {
        UID: data.UID,
        region: data.region,
        email: userProfile.email,
      },
    });

    Coralogix.init({
      config: config.coralogixRUM,
      userProfile: servicesStore.authService.getProfileSync(),
      region: data.region,
    });
  }

  setUserChannelsSchema(data) {
    this.userChannelsSchema = data;
  }

  get userPaidChannels() {
    const paidChannels = [];
    const channelsSchema = getChannelsWithProps();

    for (const channel in channelsSchema) {
      if (channelsSchema[channel].channelKind === 'paid') {
        paidChannels.push(channel);
      }
    }

    return paidChannels;
  }

  setUserFunnels(data) {
    this.userFunnels = getFunnelsFromUMP(data);
  }

  setCurrencies(data) {
    this.currencies = data;
  }

  setApiMethods(APIMethods) {
    this.APIMethods = APIMethods;
  }

  setUserCrmLastUpdateTimes(crmLastUpdateTimes) {
    this.integrationsLastUpdateTime.crm = crmLastUpdateTimes;
  }

  async getAttributionMappingRules() {
    this.isLoadingAttributionMappingRules = true;
    try {
      const serverResponse = await serverCommunication.serverRequest('GET', 'attributionMappingRules');
      this.attributionMappingRules = await serverResponse.json();
    } catch (error) {
      servicesStore.logger.error('failed to get attributionMappingRules', {
        error,
        UID: this.userMonthPlan?.UID,
      });
    }
    this.isLoadingAttributionMappingRules = false;
  }

  get funnels() {
    return this.userFunnels;
  }

  get funnelsOptions() {
    return this.userFunnels.map((funnel) => ({ value: funnel, label: this.getMetricNickname({ metric: funnel }) }));
  }

  async requestUserActions() {
    try {
      this.isLoadingActions = true;
      const userActions = await serverCommunication.serverRequest('GET', 'actions');
      this.actions = await userActions.json();
      this.isLoadingActions = false;
    } catch (error) {
      servicesStore.logger.error('failed to get user actions from server', {
        error,
        UID: this.userMonthPlan?.UID,
      });
      this.isLoadingActions = false;
    }
  }

  async deleteActionRequest({ actionId }) {
    try {
      this.actions = this.actions.filter((userAction) => userAction.actionId !== actionId);
      await serverCommunication.serverRequest('DELETE', 'actions', JSON.stringify({ actionId }));
    } catch (error) {
      servicesStore.logger.error('failed to delete action server request', {
        error,
        UID: this.userMonthPlan?.UID,
        actionId,
      });
    }
  }

  async updateActionRequest({ actionId, properties }) {
    try {
      const newActionResponse = await serverCommunication.serverRequest('PUT', 'actions', JSON.stringify({ actionId, ...properties }));
      const newAction = await newActionResponse.json();
      const updatedActions = [...this.actions];
      const actionToUpdateIndex = updatedActions.findIndex((updatedAction) => updatedAction.actionId === actionId);
      updatedActions[actionToUpdateIndex] = newAction;
      this.actions = updatedActions;
    } catch (error) {
      servicesStore.logger.error('failed to update action server request', {
        error,
        UID: this.userMonthPlan?.UID,
        actionId,
      });
    }
  }

  async createNewAction({ newAction }) {
    try {
      this.isLoadingActions = true;
      const profile = servicesStore.authService.getProfileSync();
      const createdBy = profile.email;

      newAction.active = true;
      newAction.createdBy = createdBy;

      let newActionResponse = await serverCommunication.serverRequest('POST', 'actions', JSON.stringify({ actionBody: newAction }));
      newActionResponse = await newActionResponse.json();

      this.actions = [...this.actions, newActionResponse];
      this.isLoadingActions = false;
    } catch (error) {
      servicesStore.logger.error('failed to create new action server request', {
        error,
        UID: this.userMonthPlan?.UID,
      });
      this.isLoadingActions = false;
    }
  }

  isActionExists({ actionId }) {
    if (this.actions?.length > 0) {
      return this.actions.find((existAction) => existAction.actionId === actionId);
    }
    return false;
  }

  async requestUserWorkflows() {
    try {
      const userWorkflows = await serverCommunication.serverRequest('GET', 'opsHub');
      this.workflows = await userWorkflows.json();
    } catch (error) {
      servicesStore.logger.error('failed to get user workflows from server', {
        error,
        UID: this.userMonthPlan?.UID,
      });
    }
  }

  getUserMetricsWithNew({ exceptionFilter } = {}) {
    if (!this.userMetrics) {
      return [];
    }

    const metricsWithNew = [];

    for (const metricData of this.userMetrics) {
      if (exceptionFilter && !exceptionFilter({ metricData })) {
        continue;
      }

      const {
        title,
        nickname,
        metricName,
        metricType,
      } = metricData;

      const isSingular = metricType === 'costPerFunnelStage';
      const isAddNew = metricType === 'funnelStage' && !metricName.startsWith('new');
      const newMetricName = isAddNew ? camelCase(`new ${metricName}`) : metricName;
      const newNickname = this.getMetricNickname({ metric: metricName, isSingular });

      const result = {
        ...metricData,
        metricName: newMetricName,
        nickname: isSingular ? newNickname : nickname,
        title: isSingular ? newNickname : title,
      };

      if (isAddNew) {
        result.isRelevantForTarget = true;
        result.relevantFor = metricName;
      }

      metricsWithNew.push(result);
    }

    return metricsWithNew;
  }

  async getUserMetrics() {
    try {
      const serverResponse = await serverCommunication.serverRequest('GET', 'userMetrics', undefined, this.userMonthPlan?.region);

      this.userMetrics = await serverResponse.json();
    } catch (error) {
      servicesStore.logger.error('failed to get user metrics', {
        error,
        UID: this.userMonthPlan?.UID,
        region: this.userMonthPlan?.region,
      });
    }
  }

  async getCommonSegments() {
    try {
      const serverResponse = await serverCommunication.serverRequest('GET', 'commonSegments', undefined, this.userMonthPlan?.region);

      this.commonSegments = await serverResponse.json();
    } catch (error) {
      servicesStore.logger.error('failed to get common segments', {
        error,
        UID: this.userMonthPlan?.UID,
        region: this.userMonthPlan?.region,
      });
    }
  }

  getMetricSchema({ metric }) {
    let metricValue = metric;

    if (metric === 'pipeline' || metric === 'touchedPipeline') {
      metricValue = 'newPipeline';
    }

    if (metric === 'revenue' || metric === 'touchedRevenue') {
      metricValue = 'newBookings';
    }

    if (metric.startsWith('newFunnel')) {
      metricValue = metric.replace('new', '').toLowerCase();
    }
    return this.userMetrics.find((userMetric) => userMetric.metricName === metricValue) || {};
  }

  getMetricType({ metric }) {
    const metricSchema = this.getMetricSchema({ metric });
    return metricSchema.metricType;
  }

  getDisplayTypeByMetricType({ metricType }) {
    const metricSchema = this.userMetrics.find((userMetric) => userMetric.metricType === metricType) || {};
    return metricSchema.displayType;
  }

  getMetricRelevantFor({ metric }) {
    const metricSchema = this.getMetricSchema({ metric });
    return metricSchema.relevantFor;
  }

  getAllOptimizationForMetric({ metric }) {
    return this.userMetrics.filter((userMetric) => userMetric.optimizationFor?.includes(metric) && userMetric.enabled);
  }

  getAllMetricsByMetricType({ metricType }) {
    return this.userMetrics.filter((userMetric) => userMetric.metricType === metricType);
  }

  getMetricNickname({ metric, isSingular = false }) {
    if (!metric) {
      return '';
    }

    let nicknameResponse = '';
    let metricSchema = this.getMetricSchema({ metric });

    if (metric.includes('attributed') && metric !== 'attributed') {
      const funnelStage = metric.replace('attributed', '').toLowerCase();
      metricSchema = this.getMetricSchema({ metric: funnelStage });
      nicknameResponse = 'Attributed ';
    }

    if (metric.includes('touched') && metric !== 'touched') {
      const funnelStage = metric.replace('touched', '').toLowerCase();
      metricSchema = this.getMetricSchema({ metric: funnelStage });
      nicknameResponse = 'Touched ';
    }

    if (metric.includes('influenced')) {
      const funnelStage = metric.replace('influenced', '').toLowerCase();
      metricSchema = this.getMetricSchema({ metric: funnelStage });
      nicknameResponse = 'Touched ';
    }

    const { nicknameSingular } = metricSchema;
    let { nickname } = metricSchema;
    if (!nickname) {
      nickname = startCase(metric);
    }

    if (isSingular) {
      if (nicknameSingular) {
        return nicknameResponse.concat(nicknameSingular);
      } else {
        if (nickname.slice(-1) === 's') {
          return nicknameResponse.concat(nickname.slice(0, -1));
        } else if (nickname.includes('s-')) {
          return nicknameResponse.concat(nickname.replace('s-', '-'));
        }
        return nicknameResponse.concat(nickname);
      }
    } else {
      return nicknameResponse.concat(nickname);
    }
  }

  getUserDynamicMetricsPerFunnel() {
    const userDynamicMetricsPerFunnel = {};
    for (const metric of this.userMetrics) {
      if (!metric.enabled) {
        continue;
      }
      const metricFunnel = metric.relevantFor;
      if (!userDynamicMetricsPerFunnel[metricFunnel]) {
        userDynamicMetricsPerFunnel[metricFunnel] = [];
      }
      userDynamicMetricsPerFunnel[metricFunnel].push(metric.metricName);
    }

    return userDynamicMetricsPerFunnel;
  }

  async getUserOnboardingPreset() {
    if (this.userOnboardingPreset) {
      return this.userOnboardingPreset;
    }

    try {
      const userProfile = servicesStore.authService.getProfileSync();
      const response = await serverCommunication.serverRequest('POST', 'onboarding/preset', JSON.stringify(
        {
          email: userProfile.email,
        }
      ));
      const responseData = await response.json();
      this.userOnboardingPreset = responseData;

      return responseData;
    } catch (error) {
      servicesStore.logger.error('failed to get onboarding preset', {
        error,
        UID: this.userMonthPlan?.UID,
        region: this.userMonthPlan?.region,
      });
    }
    return {};
  }

  async requestUserIntegrationsConfig() {
    if (this.integrationsConfig) {
      return;
    }

    try {
      const serverResponse = await serverCommunication.serverRequest('GET', 'integrations/config', undefined, this.userMonthPlan?.region);
      this.integrationsConfig = await serverResponse.json();
    } catch (error) {
      servicesStore.logger.error('failed to get user integrations config from the server', {
        error,
        UID: this.userMonthPlan?.UID,
        region: this.userMonthPlan?.region,
      });
    }
  }

  updateIntegrationsConfig({ newIntegrationsConfig }) {
    this.integrationsConfig = {
      ...this.integrationsConfig,
      ...newIntegrationsConfig,
    };
  }

  getRegionNickname({ region }) {
    return this.userBusinessUnitsWithIds.find((businessUnit) => businessUnit.region === region)?.name;
  }

  getRegionValue({ regionNickname }) {
    return this.userBusinessUnitsWithIds.find((businessUnit) => businessUnit.name === regionNickname)?.region;
  }

  async updateAttributionMappingRulesRequest({ newRules, deletedRuleIds, updatedRules }) {
    const body = {};
    if (newRules?.length > 0) {
      body.newRules = newRules;
    }
    if (deletedRuleIds?.length > 0) {
      body.deletedRuleIds = deletedRuleIds;
    }
    if (updatedRules?.length > 0) {
      body.updatedRules = updatedRules;
    }

    try {
      const serverResponse = await serverCommunication.serverRequest('PUT', 'attributionMappingRules', JSON.stringify(body), this.userMonthPlan?.region);
      this.attributionMappingRules = await serverResponse.json();
    } catch (error) {
      servicesStore.error('failed to update attribution mapping rules from the server', {
        error,
        UID: this.userMonthPlan?.UID,
        region: this.userMonthPlan?.region,
      });
    }
  }
}

decorate(UserStore, {
  funnels: computed,
  funnelsOptions: computed,
  userAccount: observable.ref,
  userRegionsNicknames: observable.ref,
  userBusinessUnitsWithIds: observable.ref,
  APIMethods: observable.ref,
  userMonthPlan: observable.ref,
  integrationsLastUpdateTime: observable.ref,
  userChannelsSchema: observable.ref,
  attributionMappingRules: observable,
  currencies: observable.ref,
  setUserAccount: action,
  setUserRegionsNicknames: action.bound,
  setUserBusinessUnitsWithIds: action.bound,
  setUserMonthPlan: action,
  setUserChannelsSchema: action,
  setUserFunnels: action,
  setCurrencies: action,
  setUserCrmLastUpdateTimes: action,
  getAttributionMappingRules: action.bound,
  requestUserWorkflows: action.bound,
  getUserMetrics: action.bound,
  getMetricNickname: action.bound,
  requestUserActions: action.bound,
  deleteActionRequest: action.bound,
  updateActionRequest: action.bound,
  isActionExists: action.bound,
  createNewAction: action.bound,
  getMetricType: action.bound,
  getUserDynamicMetricsPerFunnel: action.bound,
  userFunnels: observable,
  workflows: observable,
  userMetrics: observable,
  actions: observable,
  isLoadingActions: observable,
  isLoadingAttributionMappingRules: observable,
  integrationsConfig: observable,
  getUserMetricsWithNew: action.bound,
  getCommonSegments: action.bound,
  getMetricRelevantFor: action.bound,
  getAllOptimizationForMetric: action.bound,
  updateUserAccountByKey: action.bound,
  getUserOnboardingPreset: action.bound,
  requestUserIntegrationsConfig: action.bound,
  updateIntegrationsConfig: action.bound,
  getRegionNickname: action.bound,
  getRegionValue: action.bound,
  getDisplayTypeByMetricType: action.bound,
  updateAttributionMappingRulesRequest: action.bound,
  userPaidChannels: computed,
  getAllMetricsByMetricType: action.bound,
});

export default new UserStore();
