exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".salesEnablement__wrapper-1ul{display:flex;flex-direction:column;gap:40px}.salesEnablement__header-2Oz{display:flex;justify-content:space-between;align-items:center}.salesEnablement__title-1e2{font-size:16px;font-weight:600;color:var(--colorsGeneralIgBlack)}.salesEnablement__description-30b{color:var(--colorsTextsTextGray)}.salesEnablement__cellWithIcon-1Iq{display:flex;gap:4px;align-items:center}.salesEnablement__ai-2j7{color:var(--colorsTagsAiPurpleText)}.salesEnablement__timeframeDropdownPopup-3gt{right:unset!important;width:340px!important}.salesEnablement__plusIcon-2jM{transform:rotate(45deg)}.salesEnablement__absoluteExportButton-3mX{position:absolute;right:0;top:0}.salesEnablement__accountPrioritization-1tv{position:relative}.salesEnablement__salesEnablementWidgetTitle-2YC{max-width:unset}", ""]);

// exports
exports.locals = {
	"wrapper": "salesEnablement__wrapper-1ul",
	"wrapper": "salesEnablement__wrapper-1ul",
	"header": "salesEnablement__header-2Oz",
	"header": "salesEnablement__header-2Oz",
	"title": "salesEnablement__title-1e2",
	"title": "salesEnablement__title-1e2",
	"description": "salesEnablement__description-30b",
	"description": "salesEnablement__description-30b",
	"cellWithIcon": "salesEnablement__cellWithIcon-1Iq",
	"cellWithIcon": "salesEnablement__cellWithIcon-1Iq",
	"ai": "salesEnablement__ai-2j7",
	"ai": "salesEnablement__ai-2j7",
	"timeframeDropdownPopup": "salesEnablement__timeframeDropdownPopup-3gt",
	"timeframeDropdownPopup": "salesEnablement__timeframeDropdownPopup-3gt",
	"plusIcon": "salesEnablement__plusIcon-2jM",
	"plusIcon": "salesEnablement__plusIcon-2jM",
	"absoluteExportButton": "salesEnablement__absoluteExportButton-3mX",
	"absoluteExportButton": "salesEnablement__absoluteExportButton-3mX",
	"accountPrioritization": "salesEnablement__accountPrioritization-1tv",
	"accountPrioritization": "salesEnablement__accountPrioritization-1tv",
	"salesEnablementWidgetTitle": "salesEnablement__salesEnablementWidgetTitle-2YC",
	"salesEnablementWidgetTitle": "salesEnablement__salesEnablementWidgetTitle-2YC"
};