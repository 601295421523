import React from 'react';

import useStyles from 'hooks/useStyles';

import style from 'styles/common/breadcrumbsTitle.css';

const styles = style.locals || {};

export default function BreadcrumbsTitle({ breadcrumbs = [] }) {
  useStyles([style]);

  return (
    <div className={styles.breadcrumbsTitle}>
      {breadcrumbs.map((breadcrumb, index) => {
        const shouldShowIcon = index > 0;
        const breadcrumbKey = `${breadcrumb}-${index}`;
        return (
          <React.Fragment key={breadcrumbKey}>
            {shouldShowIcon ? (
              <div
                className={styles.breadcrumbsIcon}
                data-testid="breadcrumbs-icon"
              />
            ) : null}
            <div data-testid="breadcrumbs-title">
              {breadcrumb}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
