export const widgetHeaderConfigKeys = {
  filters: 'filters',
  timeframe: 'timeFrame',
  attributionModel: 'attributionModel',
  conversionIndicator: 'conversionIndicator',
  isCompareToPreviousEnabled: 'isCompareToPreviousEnabled',
};

export const upliftRangeLimit = {
  min: -10000,
  max: 10000,
};
