import React from 'react';
import history from 'history';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';

import AttributionTable from 'components/pages/analyze/AttribuitonTable/AttributionTable';
import Component from 'components/Component';
import FeatureFlags from 'components/common/FeatureFlags';
import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import Spotlight from 'components/pages/insights/overviewTab/Spotlight';
import filterStore from 'stores/filterStore';
import ImpactBySegment from 'components/pages/analyze/SegmentsTab/ImpactBySegment';
import EmptyStateGoalsAnalysis from 'components/pages/insights/overviewTab/goals/EmptyStateGoalsAnalysis';
import GoalsAnalysis from 'components/pages/insights/overviewTab/goals/GoalsAnalysis';
import OptimizationMetrics from 'components/pages/insights/overviewTab/goals/OptimizationMetrics';
import Dropdown from 'components/controls/Dropdown';
import InfoMarker from 'components/pages/InfoMarker';
import timeFrameModule from 'modules/timeframe';

import getWidgetsData from 'components/pages/insights/widgetsRequest';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import { attributionModelsItems } from 'attribution/models';
import { compose, ATTRIBUTION_TYPES } from 'components/utils/utils';
import { getFilteredSegmentAnalysisRecords, getFilteredImpactBySegmentRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getInsightsTimeframeOptions } from 'components/utils/timeframe';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { goalBusinessTypes } from 'components/pages/insights/overviewTab/goals/enums';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { makeFilters } from 'components/utils/filters';
import { navigateBetweenAnalyzeTabs, scrollIntoAnchorTag } from 'stores/analyze/logic/navigations';
import { spotlightRequestLimit } from 'components/pages/insights/logic/enums';
import { widgetTypes, flagsNames } from 'components/pages/analyze/enums';
import { getWidgetsDataV2, getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';
import { getImpactByFieldsToEnrichAccordingSegment } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { activityStatusValues } from 'components/pages/settings/goals/logic/enums';

import style from 'styles/insightsPage/insights.css';

const enhance = compose(
  inject(({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
      getAllOptimizationForMetric,
    },
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.spotlight]: spotlight,
        [widgetTypes.trendBySegments]: trendBySegments,
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
      },
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.impactBySegment]: impactBySegment,
        [widgetTypes.historicalPerformance]: historicalPerformance,
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
    },
    insightsStore: {
      timeFrame,
      updateTimeFrame,
      optimizationMetric,
      volumeMetric,
      updateVolumeMetric,
      updateOptimizationMetric,
    },
    attributionStore: {
      metricsOptions,
      isAccountMode,
    },
  }) => ({
    volumeMetric,
    updateVolumeMetric,
    impactBySegment,
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
    spotlight,
    segmentsAnalysis,
    timeFrame,
    updateTimeFrame,
    optimizationMetric,
    trendBySegments,
    historicalPerformance,
    metricsOptions,
    isAccountMode,
    accountOptimizationMetrics,
    goalsAnalysis,
    updateOptimizationMetric,
    fiscalYearFirstMonth,
    getWidgetRequestId,
    getAllOptimizationForMetric,
    upliftBySegments,
  })),
  observer
);

class InsightsOverview extends Component {
  style = style;

  constructor() {
    super();
    this.state = {
      segmentsAnalysisSelectedParams: null,
      filters: [],
      hideNoValuesRows: false,
      impactBySegmentFrequency: FREQUENCY_VALUES.MONTH,
      loadingMoreKey: null,
      drilldownMetric: null,
      shouldUseRelativeTimeframe: false,
      optimizationMetricsToShow: [],
      upliftColumnsKeys: [],
      upliftRequestId: null,
    };

    this.pageKeyName = analyzeWidgetsResultKeyNames.insightsPage;

    const insightsOnboarding = getQueryParams({ queryParamKey: 'insightsOnboarding' });
    if (insightsOnboarding) {
      this.isOnboarding = true;
    }
  }

  componentDidMount() {
    const {
      timeFrame,
      accountOptimizationMetrics,
      volumeMetric,
      spotlight,
      optimizationMetric,
      flags,
      trendBySegments,
    } = this.props;

    getWidgetsDataV2({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: {
        goalsAnalysisParams: {
          timeframe: timeFrame,
          businessType: goalBusinessTypes.volume,
          supportFilters: false,
          status: activityStatusValues.ACTIVE,
          type: 'main',
          shouldCalculateTrend: true,
        },
      },
      addBaseAnalyzeConfig: false,
    });

    if (!accountOptimizationMetrics?.[this.pageKeyName]?.isLoaded) {
      getWidgetsData({
        timeFrame,
        widgets: [widgetTypes.accountOptimizationMetrics],
      });
    }

    if (!trendBySegments?.[`optimizationMetrics-${this.pageKeyName}`]?.isLoaded && !isEmpty(volumeMetric)) {
      const optimizationMetrics = this.props.getAllOptimizationForMetric({ metric: volumeMetric.value });
      const optimizationMetricsForRequest = optimizationMetrics.map((metric) => metric.metricName);
      this.setState({ optimizationMetricsToShow: optimizationMetrics });

      getWidgetsDataV2({
        widget: widgetTypes.historicalPerformance,
        widgetConfig: {
          ...this.getBaseConfig({ timeFrame, filters: this.state.filters }),
          selectedMetrics: optimizationMetricsForRequest,
        },
        isPreviousTimeFrame: false,
        addBaseAnalyzeConfig: false,
      });

      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        timeFrame,
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: {
              selectedMetrics: optimizationMetricsForRequest,
            },
          }],
        },
        resultKeyName: `optimizationMetrics-${this.pageKeyName}`,
      });
    }

    if (isFlagActive({ flag: flags.insightsTabSpotlight })) {
      if (!spotlight?.[this.pageKeyName]?.isLoaded && !isEmpty(optimizationMetric)) {
        this.getSpotlightData({ offset: 0 });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      optimizationMetric,
      volumeMetric,
      timeFrame,
      isAccountMode,
    } = this.props;

    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });
    const didOptimizationMetricChanged = optimizationMetric !== prevProps.optimizationMetric;
    const didVolumeMetricChanged = volumeMetric !== prevProps.volumeMetric;

    if ((didOptimizationMetricChanged || didVolumeMetricChanged) && this.state.filters?.length > 0) {
      this.setState({ filters: [] });
    }

    if (didAccountModeChanged || didTimeFrameChanged) {
      getWidgetsData({
        timeFrame,
        widgets: [widgetTypes.accountOptimizationMetrics],
      });
      this.updateVolumeMetricWithScroll(volumeMetric);
    }
  }

  getBaseConfig({ timeFrame, filters }) {
    return {
      filters: filters ? makeFilters(filters, filterStore.filtersData) : [],
      timeFrame: {
        ...timeFrameModule.getTimeframeParams({ ...timeFrame, fiscalYearFirstMonth: this.props.fiscalYearFirstMonth }),
        value: timeFrame.rollingValue || timeFrame.value,
      },
      attributionModel: attributionModelsItems[0],
      shouldUsePredefinedFilters: true,
    };
  }

  async handleShowMoreClick({ offset, loadMoreKey }) {
    this.setState({ loadingMoreKey: loadMoreKey });
    await this.getSpotlightData({ offset, isConcatResult: true });
    this.setState({ loadingMoreKey: null });
  }

  onStartCreateNewGoal() {
    this.props.updateVolumeMetric({});
  }

  async getSpotlightData({ offset, isConcatResult = false }) {
    const { timeFrame, optimizationMetric } = this.props;
    this.setState({ drilldownMetric: null });
    await getWidgetsData({
      widgets: [widgetTypes.spotlight],
      timeFrame,
      configPerWidget: {
        [widgetTypes.spotlight]: [{
          metric: optimizationMetric.value,
          spotlightParams: {
            metricValue: optimizationMetric.actualValue,
            limit: spotlightRequestLimit,
            offset,
          },
        }],
      },
      isConcatResult,
    });
  }

  updateSegmentsAnalysisParam = ({ firstSegment, secondSegment, filters = [] }) => {
    const { timeFrame, optimizationMetric, flags } = this.props;

    const newSegmentsAnalysisSelectedParams = { firstSegment, secondSegment };
    this.setState({ segmentsAnalysisSelectedParams: newSegmentsAnalysisSelectedParams });

    if (isFlagActive({ flag: flags.insightsOverviewSegmentAnalysis })) {
      getWidgetsDataV2({
        widget: widgetTypes.segmentsAnalysis,
        widgetConfig: {
          ...this.getBaseConfig({ timeFrame, filters }),
          segmentsAnalysisParams: newSegmentsAnalysisSelectedParams,
          conversionIndicator: optimizationMetric.originFunnel,
        },
        addBaseAnalyzeConfig: false,
      });

      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        timeFrame,
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: newSegmentsAnalysisSelectedParams,
            conversionIndicator: optimizationMetric.originFunnel,
          }],
        },
      });
    }

    if (isFlagActive({ flag: flags.insightsOverviewImpactBySegment })) {
      getWidgetsDataV2({
        widget: widgetTypes.impactBySegment,
        widgetConfig: {
          ...this.getBaseConfig({ timeFrame, filters }),
          impactBySegmentParams: {
            firstSegment: newSegmentsAnalysisSelectedParams.firstSegment,
            segmentFrequency: this.state.impactBySegmentFrequency,
            fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: newSegmentsAnalysisSelectedParams.firstSegment } }),
            shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
          },
          conversionIndicator: optimizationMetric.originFunnel,
          kpiFocus: this.state.drilldownMetric ?? optimizationMetric.value,
        },
        addBaseAnalyzeConfig: false,
      });
    }

    if (secondSegment) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        timeFrame,
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: { firstSegment, secondSegment: null },
            conversionIndicator: optimizationMetric.originFunnel,
          }],
        },
        resultKeyName: `chart${this.pageKeyName}`,
      });
    }
  };

  navigateToAnalyzeSegmentFunction = () => navigateBetweenAnalyzeTabs({
    filters: this.state.filters,
    tabName: 'segments',
    anchor: 'attribution-table',
    conversionIndicator: this.props.optimizationMetric.originFunnel,
    timeFrame: this.props.timeFrame,
    attributionModel: attributionModelsItems[0],
    includeAttributionStoreFilters: false,
    segments: this.state.segmentsAnalysisSelectedParams,
  });

  innerPageNavigation = ({
    firstSegment, tabName, anchor, filters = [],
  }) => {
    this.setState({ filters });
    if (firstSegment) {
      this.updateSegmentsAnalysisParam({
        firstSegment,
        secondSegment: null,
        filters,
      });
    }
    if (anchor) {
      history.push({
        pathname: `/insights/${tabName}`,
        hash: `#${anchor}`,
      });
      scrollIntoAnchorTag(anchor);
    }
  };

  updateCrmParam = ({ key, value }) => {
    const { optimizationMetric, timeFrame } = this.props;
    switch (key) {
      case 'groupBy':
        this.updateSegmentsAnalysisParam({ firstSegment: value, secondSegment: null });
        break;
      case 'frequency':
        this.setState({ impactBySegmentFrequency: value });
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            ...this.getBaseConfig({ timeFrame, filters: this.state.filters }),
            impactBySegmentParams: {
              firstSegment: this.state.segmentsAnalysisSelectedParams.firstSegment,
              segmentFrequency: value,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.segmentsAnalysisSelectedParams.firstSegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: optimizationMetric.originFunnel,
            kpiFocus: this.state.drilldownMetric ?? optimizationMetric.value,
          },
          addBaseAnalyzeConfig: false,
        });
        break;
      default:
        break;
    }
  };

  updateDrilldownMetric({ metric }) {
    const { optimizationMetric, timeFrame } = this.props;
    this.setState({ drilldownMetric: metric?.value });

    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        ...this.getBaseConfig({ timeFrame, filters: this.state.filters }),
        impactBySegmentParams: {
          firstSegment: this.state.segmentsAnalysisSelectedParams.firstSegment,
          segmentFrequency: this.state.impactBySegmentFrequency,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.segmentsAnalysisSelectedParams.firstSegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: optimizationMetric.originFunnel,
        kpiFocus: metric?.value ?? optimizationMetric.value,
      },
      addBaseAnalyzeConfig: false,
    });
  }

  updateHideNoValuesRows = () => {
    this.setState((prevState) => ({ hideNoValuesRows: !prevState.hideNoValuesRows }));
  };

  async updateOptimizationMetricWthScroll(newOptimizationMetric) {
    if (this.state.segmentsAnalysisSelectedParams) {
      this.setState({ segmentsAnalysisSelectedParams: null });
    }
    await this.props.updateOptimizationMetric(newOptimizationMetric);
    await this.getSpotlightData({ offset: 0 });

    history.push({
      pathname: '/insights/overview',
      hash: '#spotlight',
    });
    scrollIntoAnchorTag('spotlight');
  }

  updateVolumeMetricWithScroll(newVolumeMetric) {
    const {
      timeFrame, updateVolumeMetric, updateOptimizationMetric, optimizationMetric, getAllOptimizationForMetric,
    } = this.props;

    updateVolumeMetric(newVolumeMetric);
    if (!isEmpty(optimizationMetric)) {
      updateOptimizationMetric({});
    }
    if (this.state.segmentsAnalysisSelectedParams) {
      this.setState({ segmentsAnalysisSelectedParams: null });
    }

    const optimizationMetrics = getAllOptimizationForMetric({ metric: newVolumeMetric.value });
    const optimizationMetricsForRequest = optimizationMetrics.map((metric) => metric.metricName);
    this.setState({ optimizationMetricsToShow: optimizationMetrics });

    getWidgetsDataV2({
      widget: widgetTypes.historicalPerformance,
      widgetConfig: {
        ...this.getBaseConfig({ timeFrame, filters: this.state.filters }),
        selectedMetrics: optimizationMetricsForRequest,
      },
      isPreviousTimeFrame: false,
      addBaseAnalyzeConfig: false,
    });

    getWidgetsData({
      widgets: [widgetTypes.trendBySegments],
      timeFrame,
      configPerWidget: {
        [widgetTypes.trendBySegments]: [{
          trendBySegmentsParams: {
            selectedMetrics: optimizationMetricsForRequest,
          },
        }],
      },
      resultKeyName: `optimizationMetrics-${this.pageKeyName}`,
    });

    history.push({
      pathname: '/insights/overview',
      hash: '#optimization-metric',
    });
    scrollIntoAnchorTag('optimization-metric');
  }

  onChangeTimeFrame(newTimeFrame) {
    this.setState({ segmentsAnalysisSelectedParams: null });
    this.props.updateTimeFrame(newTimeFrame);
  }

  updateShouldUseRelativeTimeframe() {
    const { optimizationMetric, timeFrame } = this.props;
    this.setState((prevState) => ({ shouldUseRelativeTimeframe: !prevState.shouldUseRelativeTimeframe }), () => {
      getWidgetsDataV2({
        widget: widgetTypes.impactBySegment,
        widgetConfig: {
          ...this.getBaseConfig({ timeFrame, filters: this.state.filters }),
          impactBySegmentParams: {
            firstSegment: this.state.segmentsAnalysisSelectedParams.firstSegment,
            segmentFrequency: this.state.impactBySegmentFrequency,
            fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.segmentsAnalysisSelectedParams.firstSegment } }),
            shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
          },
          conversionIndicator: optimizationMetric.originFunnel,
          kpiFocus: this.state.drilldownMetric ?? optimizationMetric.value,
        },
        addBaseAnalyzeConfig: false,
      });
    });
  }

  updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId }) {
    this.setState({ upliftColumnsKeys: columnsKeys, upliftRequestId });

    const upliftBySegmentsData = this.props.upliftBySegments?.[upliftRequestId]?.result?.data;
    const shouldRequestUpliftBySegments = !upliftBySegmentsData && !columnsKeys.every((key) => upliftBySegmentsData?.[key]);
    if (shouldRequestUpliftBySegments) {
      getWidgetsDataV2({
        widget: widgetTypes.upliftBySegments,
        widgetConfig: {
          upliftBySegmentsParams: {
            selectedMetrics: columnsKeys,
            firstSegment: this.state.segmentsAnalysisSelectedParams.firstSegment,
            secondSegment: this.state.segmentsAnalysisSelectedParams.secondSegment,
          },
        },
        parentRequestId: upliftRequestId,
      });
    }
  }

  render() {
    const {
      spotlight,
      flags,
      segmentsAnalysis,
      trendBySegments,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      optimizationMetric,
      impactBySegment,
      metricsOptions,
      timeFrame,
      volumeMetric,
      goalsAnalysis,
      accountOptimizationMetrics,
      getWidgetRequestId,
      historicalPerformance,
      upliftBySegments,
    } = this.props;

    const {
      filters,
      loadingMoreKey,
      drilldownMetric,
      hideNoValuesRows,
      impactBySegmentFrequency,
      segmentsAnalysisSelectedParams,
      shouldUseRelativeTimeframe,
      optimizationMetricsToShow,
    } = this.state;

    const segmentsAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        ...this.getBaseConfig({ timeFrame, filters }),
        segmentsAnalysisParams: segmentsAnalysisSelectedParams,
        conversionIndicator: optimizationMetric.originFunnel,
      },
      addBaseAnalyzeConfig: false,
    });
    const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: segmentsAnalysisFullWidgetConfig });

    const impactBySegmentFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        ...this.getBaseConfig({ timeFrame, filters }),
        impactBySegmentParams: {
          firstSegment: segmentsAnalysisSelectedParams?.firstSegment,
          segmentFrequency: impactBySegmentFrequency,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: segmentsAnalysisSelectedParams?.firstSegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        kpiFocus: drilldownMetric ?? optimizationMetric.value,
        conversionIndicator: optimizationMetric.originFunnel,
      },
      addBaseAnalyzeConfig: false,
    });
    const impactBySegmentRequestId = getWidgetRequestId({ widget: widgetTypes.impactBySegment, widgetConfig: impactBySegmentFullWidgetConfig });

    const historicalPerformanceFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        ...this.getBaseConfig({ timeFrame, filters: this.state.filters }),
        selectedMetrics: optimizationMetricsToShow.map((metric) => metric.metricName),
      },
      isPreviousTimeFrame: false,
      addBaseAnalyzeConfig: false,
    });
    const historicalPerformanceRequestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: historicalPerformanceFullWidgetConfig });

    const goalsAnalysisFullConfig = getWidgetFullConfig({
      widgetConfig: {
        goalsAnalysisParams: {
          timeframe: timeFrame,
          businessType: goalBusinessTypes.volume,
          supportFilters: false,
          status: activityStatusValues.ACTIVE,
          type: 'main',
          shouldCalculateTrend: true,
        },
      },
      addBaseAnalyzeConfig: false,
    });
    const goalsAnalysisRequestId = getWidgetRequestId({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: goalsAnalysisFullConfig,
    });

    const isGoalsAnalysisFailedToLoad = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'failed';
    const isGoalsAnalysisLoaded = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'finished' || isGoalsAnalysisFailedToLoad;
    const goalsAnalysisData = goalsAnalysis?.[goalsAnalysisRequestId]?.result?.goals ?? [];

    const conversionIndicator = optimizationMetric.originFunnel;
    const kpiFocusIndicator = optimizationMetric.value;
    const impactBySegmentData = impactBySegment?.[impactBySegmentRequestId]?.result?.impactData;
    const accountOptimizationMetricsData = accountOptimizationMetrics?.[this.pageKeyName]?.data;

    const historicalPerformanceData = historicalPerformance?.[historicalPerformanceRequestId]?.result;
    const optimizationMetricsData = optimizationMetricsToShow.map((metric) => {
      const metricData = historicalPerformanceData?.[metric.metricName];
      return {
        ...metric,
        ...metricData,
      };
    });

    const updateFilters = makeFilters(filters, filterStore.filtersData);
    const metadataBySegment = impactBySegment?.[impactBySegmentRequestId]?.result?.metadataBySegment;
    const filteredImpactBySegment = getFilteredImpactBySegmentRecords({
      data: impactBySegmentData, filters: updateFilters, segment: segmentsAnalysisSelectedParams?.firstSegment, metadataBySegment,
    });
    const segmentsAnalysisTableData = {};
    const segmentAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};
    for (const [stageName, stageData] of Object.entries(segmentAnalysisData)) {
      segmentsAnalysisTableData[stageName] = getFilteredSegmentAnalysisRecords({
        data: stageData,
        filters,
        segments: segmentsAnalysisSelectedParams,
      });
    }

    const mergedFunnelsSegmentsAnalysisTableData = getFilteredSegmentAnalysisRecords({
      data: segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.mergedFunnelsTableData ?? [],
      filters: updateFilters,
      segments: segmentsAnalysisSelectedParams,
    });

    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    const trendBySegmentsData = trendBySegments?.[this.pageKeyName]?.data;
    const trendBySegmentsChartData = segmentsAnalysisSelectedParams?.secondSegment ? trendBySegments?.[`chart${this.pageKeyName}`]?.data?.[conversionIndicator]?.segments : trendBySegmentsData?.[conversionIndicator]?.segments;
    const trendBySegmentsTotals = trendBySegments?.[this.pageKeyName]?.data?.[conversionIndicator]?.totals;
    const trendBySegmentsOptimizationMetricsData = trendBySegments?.[`optimizationMetrics-${this.pageKeyName}`]?.data;

    const isSpotlightLoaded = spotlight?.[this.pageKeyName]?.isLoaded || loadingMoreKey !== null;

    const timeFrameOptions = getInsightsTimeframeOptions();

    const upliftBySegmentsFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        upliftBySegmentsParams: {
          selectedMetrics: this.state.upliftColumnsKeys,
          firstSegment: segmentsAnalysisSelectedParams?.firstSegment,
          secondSegment: segmentsAnalysisSelectedParams?.secondSegment,
        },
      },
    });
    const upliftBySegmentsRequestId = this.state.upliftRequestId || getWidgetRequestId({ widget: widgetTypes.upliftBySegments, widgetConfig: upliftBySegmentsFullWidgetConfig });
    const upliftBySegmentsData = upliftBySegments?.[upliftBySegmentsRequestId]?.result?.data;

    const stagesAmount = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.countPerStage ?? {};
    const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
    const isSegmentAnalysisFailedToLoad = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'failed';
    const isImpactBySegmentLoaded = impactBySegment?.[impactBySegmentRequestId]?.status === 'finished';
    const isImpactBySegmentFailedToLoad = impactBySegment?.[impactBySegmentRequestId]?.status === 'failed';
    const isHistoricalPerformanceLoaded = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'finished';
    const isHistoricalPerformanceFailedToLoad = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'failed';
    const isLoadedUpliftData = upliftBySegments?.[upliftBySegmentsRequestId]?.status === 'finished';

    return (
      <>
        <div className={this.classes.insighitsContainer}>
          <div className={this.classes.relative}>
            {goalsAnalysisData?.length > 0 ? (
              <GoalsAnalysis
                isLoaded={isGoalsAnalysisLoaded}
                goals={goalsAnalysisData}
                selectedGoal={volumeMetric}
                setSelectedGoal={({ id, value }) => this.updateVolumeMetricWithScroll({ value, id })}
                onStartCreateNewGoal={() => this.onStartCreateNewGoal()}
                isHideAddToReport
                isInsightsPage
                isEnableCreateNewGoal
                isOnboarding={this.isOnboarding}
                isFailedToLoad={isGoalsAnalysisFailedToLoad}
                goalsAnalysisRequestId={goalsAnalysisRequestId}
              />
            ) : (
              <EmptyStateGoalsAnalysis
                isLoaded={isGoalsAnalysisLoaded}
                volumeMetric={volumeMetric}
                updateVolumeMetric={({ value }) => this.updateVolumeMetricWithScroll({ value })}
                onFinishCreateNewGoal={() => this.onFinishCreateNewGoal()}
                onStartCreateNewGoal={() => this.onStartCreateNewGoal()}
                goalsList={goalsAnalysisData}
                goalsAnalysisRequestId={goalsAnalysisRequestId}
              />
            )}
          </div>
        </div>

        {!isEmpty(volumeMetric) ? (
          <div className={this.classes.grayBackground}>
            <div className={this.classes.insighitsContainer}>
              <div id="optimization-metric" className={this.classes.relative}>
                <div className={classNames(this.classes.timeFrameDropdown, this.classes.timeFrameDropdown)}>
                  <InfoMarker
                    tooltipText="The analysis period had been extended to the last 12 months to identify longer-term optimization opportunities and reduce anomalies."
                    place="left"
                  />
                  <Dropdown
                    options={timeFrameOptions}
                    selectedKey={timeFrame.rollingValue || timeFrame.value}
                    onChange={(newTimeFrame) => this.onChangeTimeFrame(newTimeFrame)}
                    controlWidth={210}
                    findSelectedByKey={timeFrame.rollingValue && 'rollingValue'}
                  />
                </div>
                <OptimizationMetrics
                  isLoaded={isHistoricalPerformanceLoaded}
                  isLoadedTrend={trendBySegments?.[`optimizationMetrics-${this.pageKeyName}`]?.isLoaded}
                  isFailedToLoad={isHistoricalPerformanceFailedToLoad}
                  data={optimizationMetricsData}
                  trendData={trendBySegmentsOptimizationMetricsData}
                  selectedMetric={optimizationMetric}
                  setSelectedMetric={({ value, id, actualValue }) => this.updateOptimizationMetricWthScroll({ value, id, actualValue })}
                  isOnboarding={this.isOnboarding}
                />
              </div>

              {!isEmpty(optimizationMetric) ? (
                <div className={classNames(this.classes.relative, this.classes.widgetSpace)}>
                  <FeatureFlags flag={flags.insightsTabSpotlight}>
                    <div id="spotlight">
                      <Spotlight
                        isLoaded={isSpotlightLoaded}
                        spotlightData={spotlight?.[this.pageKeyName]?.data || {}}
                        selectedOptimizationMetric={optimizationMetric}
                        innerPageNavigation={this.innerPageNavigation}
                        timeFrame={timeFrame}
                        getSpotlightData={() => this.getSpotlightData({ offset: 0 })}
                        loadingMoreKey={loadingMoreKey}
                        handleShowMoreClick={({ offset, loadMoreKey }) => this.handleShowMoreClick({ offset, loadMoreKey })}
                        isOnboarding={this.isOnboarding}
                      />
                    </div>
                  </FeatureFlags>

                  {segmentsAnalysisSelectedParams ? (
                    <>
                      <FeatureFlagsWithBlur flag={flags.insightsOverviewImpactBySegment} name={flagsNames.insightsOverviewImpactBySegment}>
                        <div id="impact-by-segment">
                          <ImpactBySegment
                            widgetHeaderProps={{
                              classNameWidgetTitle: this.classes.widgetTitle,
                            }}
                            showNoValueCheckbox
                            showSegmentDropdown
                            filters={filters}
                            isLoaded={isImpactBySegmentLoaded}
                            isImpactBySegmentFailedToLoad={isImpactBySegmentFailedToLoad}
                            metricsOptions={metricsOptions}
                            segmentsOptionsFields={segmentsOptionsFields}
                            segmentData={filteredImpactBySegment ?? []}
                            totalByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.totalByFrequency}
                            totalBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.totalBySegment}
                            metricType={impactBySegment?.[impactBySegmentRequestId]?.result?.segmentKey ?? 'attributed'}
                            firstSegment={segmentsAnalysisSelectedParams.firstSegment}
                            conversionIndicator={conversionIndicator}
                            currentFrequency={impactBySegmentFrequency}
                            frequencyControl={({ value }) => this.updateCrmParam({ key: 'frequency', value })}
                            relatedEntitiesIndication={impactBySegment?.[impactBySegmentRequestId]?.result?.shouldUseRelatedEntities}
                            trendData={trendBySegmentsChartData}
                            isLoadedTrendData={trendBySegments?.[this.pageKeyName]?.isLoaded}
                            onChangeHideNoValuesRows={this.updateHideNoValuesRows}
                            hideNoValuesRows={hideNoValuesRows}
                            kpiFocus={drilldownMetric || kpiFocusIndicator}
                            innerPageNavigation={this.innerPageNavigation}
                            isInsightsTab
                            dataTotals={accountOptimizationMetricsData ?? {}}
                            timeFrame={timeFrame}
                            attributionModel={attributionModelsItems[0]}
                            isShowInspectInNavigationMenu={false}
                            updateDrilldownMetric={({ metric }) => this.updateDrilldownMetric({ metric })}
                            isCalculateAdvancedMetrics
                            onChangeCheckedSegments={({ segments }) => this.setState({ checkedSegments: segments })}
                            onChangeUncheckedSegments={({ segments }) => this.setState({ uncheckedSegments: segments })}
                            checkedSegments={this.state.checkedSegments || []}
                            uncheckedSegments={this.state.uncheckedSegments || []}
                            shouldUseRelativeTimeframe={shouldUseRelativeTimeframe}
                            updateShouldUseRelativeTimeframe={() => this.updateShouldUseRelativeTimeframe()}
                            exportMode={false}
                          />
                        </div>
                      </FeatureFlagsWithBlur>

                      <FeatureFlagsWithBlur flag={flags.insightsOverviewSegmentAnalysis} name={flagsNames.insightsOverviewSegmentAnalysis}>
                        <div id="attribution-table">
                          <AttributionTable
                            widgetHeaderProps={{
                              classNameWidgetTitle: this.classes.widgetTitle,
                            }}
                            onChangeHideNoValuesRows={this.updateHideNoValuesRows}
                            hideNoValuesRows={hideNoValuesRows}
                            isHideAddToReport
                            navigateToAnalyzeSegmentFunction={this.navigateToAnalyzeSegmentFunction}
                            expendColumnsKeys={[optimizationMetric.value]}
                            isShowHideNoValue
                            isShowNavigateToAnalyze
                            isLoaded={isSegmentAnalysisLoaded}
                            data={segmentsAnalysisTableData ?? {}}
                            mergedFunnelsTableData={mergedFunnelsSegmentsAnalysisTableData}
                            trendData={trendBySegmentsData}
                            isLoadedTrendData={trendBySegments?.[this.pageKeyName]?.isLoaded}
                            prevData={{}}
                            segmentsOptionsFields={segmentsOptionsFields}
                            key={conversionIndicator}
                            defaultStageKey={conversionIndicator}
                            dataNickname="Segment Analysis"
                            type={ATTRIBUTION_TYPES.OVERVIEW}
                            title="Segment Analysis"
                            updateSegmentsAnalysisParam={this.updateSegmentsAnalysisParam}
                            segmentsAnalysisParams={segmentsAnalysisSelectedParams}
                            timeframe={timeFrame}
                            filters={filters}
                            innerPageNavigation={this.innerPageNavigation}
                            dataTotals={accountOptimizationMetricsData ?? {}}
                            trendDataTotals={trendBySegmentsTotals}
                            isShowStageSelector={false}
                            attributionModel={attributionModelsItems[0]}
                            stagesAmount={stagesAmount}
                            isSegmentAnalysisFailedToLoad={isSegmentAnalysisFailedToLoad}
                            isAttribution={segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution}
                            isCalculateAdvancedMetrics
                            upliftColumnsKeys={this.state.upliftColumnsKeys}
                            updatedUpliftColumnsKeys={({ columnsKeys }) => this.updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId: upliftBySegmentsRequestId })}
                            upliftData={upliftBySegmentsData}
                            isLoadedUpliftData={isLoadedUpliftData}
                          />
                        </div>
                      </FeatureFlagsWithBlur>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default withLDConsumer()(enhance(InsightsOverview));
