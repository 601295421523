import React, { useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import Popup from 'components/Popup';
import MenuButton from 'components/common/MenuButton';

import { lastUpdatedTimesFormatDate } from 'components/navigationMenu/enums';
import { getLastCRMIntegrationDate, getLastAdsIntegrationDate } from 'components/navigationMenu/logic/lastUpdatedTimes';

import style from 'styles/navigationMenu/lastUpdatedTimes.css';
import headerBarStyle from 'styles/navigationMenu/headerBar.css';

const styles = style.locals || {};
const headerBarStyles = headerBarStyle.locals || {};

function LastUpdatedTimes({
  integrationsLastUpdateTime,
  userMonthPlan,
}) {
  useStyles([style, headerBarStyle]);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const crmDate = getLastCRMIntegrationDate({ integrationsLastUpdate: integrationsLastUpdateTime.crm });
  const adsIntegrationsDate = getLastAdsIntegrationDate({ userMonthPlan });

  return (
    <div className={headerBarStyles.relative}>
      <MenuButton
        onClick={() => setIsShowPopup(!isShowPopup)}
        rightIconRerender={(
          <div className={headerBarStyles.buttonIcon} data-icon="headerBar:lastUpdated" />
        )}
        className={headerBarStyles.menuButton}
        withArrowIndication={false}
        dataTestId="last-updated-times-button"
      />

      <Popup
        className={styles.popupLastUpdatedTimes}
        hidden={!isShowPopup}
        onClose={() => setIsShowPopup(false)}
      >
        <div className={styles.boxHeader}>
          Last data update times
        </div>
        {crmDate ? (
          <div className={styles.boxRow}>
            <b>
              CRM -
            </b>
            <div>{moment(crmDate).format(lastUpdatedTimesFormatDate)}</div>
          </div>
        ) : null}
        {adsIntegrationsDate ? (
          <div className={styles.boxRow}>
            <b>
              Ad platforms -
            </b>
            <div>{moment(adsIntegrationsDate).format(lastUpdatedTimesFormatDate)}</div>
          </div>
        ) : null}
        <div className={styles.boxFooter}>
          * Local time
        </div>
      </Popup>
    </div>
  );
}

export default inject(
  ({
    userStore: {
      integrationsLastUpdateTime,
      userMonthPlan,
    } = {},
  }) => ({
    integrationsLastUpdateTime,
    userMonthPlan,
  }),
  observer
)(LastUpdatedTimes);
