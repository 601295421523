import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import history from 'history';

import useStyles from 'hooks/useStyles';

import SignalAnalytics from 'components/widgets/signals/SignalAnalytics';
import MySignals from 'components/widgets/signals/MySignals';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { getSignalAnalyticsMockData, getMySignalsMockData } from 'components/widgets/signals/logic/signals';
import { pageLabels } from 'enums';

import style from 'styles/salesEnablement/salesEnablement.css';

const styles = style.locals || {};

function IntentSignalsPage({ flags }) {
  useStyles([style]);

  if (!flags.headerSalesEnablementTab) {
    history.push('/dashboard/navigate');
    return null;
  }

  const [signalAnalyticsData, setSignalAnalyticsData] = useState([]);
  const [mySignalData, setMySignalData] = useState([]);

  useEffect(() => {
    if (flags.salesEnablementDemoOnly) {
      setMySignalData(getMySignalsMockData());
    }
  }, []);

  useEffect(() => {
    if (flags.salesEnablementDemoOnly) {
      const signalAnalyticsMockData = signalAnalyticsData.length ? signalAnalyticsData : getSignalAnalyticsMockData();
      setSignalAnalyticsData(signalAnalyticsMockData.map((signal) => {
        const matchingSignal = mySignalData?.find((item) => item.id === signal.id);
        const signalSource = matchingSignal ? matchingSignal.source : '';
        const signalName = matchingSignal ? matchingSignal.name : '';
        return ({
          ...signal,
          signal: signalName,
          source: signalSource,
        });
      }));
    }
  }, [mySignalData]);

  return (
    <>
      <BreadcrumbsTitle breadcrumbs={[pageLabels.salesEnablement, pageLabels.intentSignals]} />
      <div className={styles.wrapper}>
        <MySignals
          mySignals={mySignalData}
          setMySignals={setMySignalData}
          setSignalAnalyticsData={setSignalAnalyticsData}
          isLoaded
        />
        <SignalAnalytics
          mySignals={mySignalData}
          data={signalAnalyticsData}
          isLoaded
        />
      </div>
    </>
  );
}

export default withLDConsumer()(IntentSignalsPage);
