import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import FilterPopup from 'components/pages/users/Filters/FilterPopup';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';

import { Events } from 'trackers/analytics/enums';
import { isUiFilter, getRawFilter } from 'components/utils/filters';
import { filterKinds, isCostCustomFieldsFilter } from 'components/utils/filters/logic';
import { filterButtonPositions } from 'components/pages/users/Filters/FilterPanel/enums';
import { getUpdatedFiltersItems, getCombineSelectedFilters } from 'components/pages/users/Filters/logic/customFiltersPanel';

import servicesStore from 'stores/servicesStore';

import style from 'styles/users/filters.css';

const styles = style.locals || {};

function CustomFiltersPanel({
  disabled,
  buttonText = 'Add Custom Filter',
  filters,
  setFilters,
  savedFilters,
  filtersData,
  filtersPopupStyle,
  filterTagClassName,
  addFilterButtonClassName,
  clearTagsDebounce = 1000,
  buttonPosition = filterButtonPositions.start,
  shouldShowClearAll = true,
}) {
  useStyles([style]);

  const [showFiltersPopup, setShowFiltersPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filtersItems, setFiltersItems] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);

  useEffect(() => {
    if (tagsToRemove.length > 0) {
      const delayDebounce = setTimeout(() => {
        let isAllFiltersToRemovedIsUIOnly = true;
        const filteredItems = [];
        for (const filterItem of filters) {
          if (tagsToRemove.includes(filterItem.id)) {
            if (!filterItem.isUiOnly) {
              isAllFiltersToRemovedIsUIOnly = false;
            }
            continue;
          }
          filteredItems.push(filterItem);
        }
        setFilters(filteredItems, !isAllFiltersToRemovedIsUIOnly);
        setTagsToRemove([]);
      }, clearTagsDebounce);
      return () => clearTimeout(delayDebounce);
    }
    return () => ({});
  }, [tagsToRemove]);

  function onAddNewFilters({ newFilters, configs }) {
    const { combinedFilters, isFiltersAreUIOnly } = getCombineSelectedFilters({
      filters, selectedFilter, newFilters, configs,
    });

    if (combinedFilters.length > 0) {
      setFilters(combinedFilters, !isFiltersAreUIOnly);
    }
    closePopup();
  }

  function openPopup() {
    setFiltersItems([]);
    setShowFiltersPopup(true);

    servicesStore.eventTracker.track({
      eventName: Events.filtersAddFilter,
    });
  }

  function closePopup() {
    setShowFiltersPopup(false);
    setSelectedFilter(null);
  }

  function handleClear() {
    const isAllFiltersAreUIOnly = filters.every((filterItem) => filterItem.isUiOnly);
    setFiltersItems([]);
    setFilters([], !isAllFiltersAreUIOnly);
  }

  function updatePopupFiltersItems({ newFilter, shouldRemoveBeforeFunnelFilter }) {
    const newFiltersItems = getUpdatedFiltersItems({ filtersItems, newFilter, shouldRemoveBeforeFunnelFilter });
    setFiltersItems(newFiltersItems);
  }

  const filterConfigs = useMemo(() => {
    if (!isEmpty(savedFilters)) {
      const savedFilter = {
        kind: filterKinds.SAVED,
        options: savedFilters.map((filterItem) => filterItem?.alias || ''),
      };
      return [...filtersData, savedFilter];
    }
    return filtersData;
  }, [filtersData, savedFilters]);

  function renderAddFilterButton() {
    return (
      <Button
        type="primaryBlue"
        onClick={() => openPopup()}
        className={addFilterButtonClassName}
        disabled={disabled}
        dataTestId="addFilters"
      >
        <div className={styles.addFilterButtonIcon} />
        {buttonText}
      </Button>
    );
  }

  return (
    <div
      data-testid="custom-filters-panel"
      className={classnames(styles.panel)}
    >
      <div className={styles.filterTags}>
        {buttonPosition === filterButtonPositions.start ? (
          renderAddFilterButton()
        ) : null}

        {filters.length > 1 && shouldShowClearAll && (
        <div className={classnames(styles.filterPlainLabel, disabled && styles.clearAllDisabled)} onClick={() => (disabled ? null : handleClear())}>
          Clear all
        </div>
        )}

        {filters.map((filterItem) => {
          if (filterItem.isQuickFilter) {
            return null;
          }

          const isUiOnly = isUiFilter(filterItem.data);
          const kind = filterItem?.config?.kind || '';
          const isCustomFieldsFilter = isCostCustomFieldsFilter(kind);
          const selectedOptions = isCustomFieldsFilter ? (filterItem?.data?.rules || []) : (filterItem?.data?.selectedOptions || []);

          return (
            <FilterTag
              key={filterItem.id}
              label={filterItem.label}
              selectedOptions={selectedOptions}
              kind={kind}
              onRemove={() => setTagsToRemove([...tagsToRemove, filterItem.id])}
              onClick={() => {
                setSelectedFilter(filterItem);
                setShowFiltersPopup(true);
                setFiltersItems([]);
              }}
              isUiFilter={isUiOnly}
              className={filterTagClassName}
              isDisabled={filterItem.isDisabled || disabled}
              colorTheme={filterItem.filterTagColorTheme}
            />
          );
        })}

        {buttonPosition === filterButtonPositions.end ? (
          renderAddFilterButton()
        ) : null}

      </div>
      {showFiltersPopup ? (
        <FilterPopup
          opened
          onClose={() => closePopup()}
          updateSavedFiltersList={(newFilters) => setFilters(newFilters)}
          filterConfigs={filterConfigs}
          onFilterAdd={(newFilters) => onAddNewFilters({ newFilters, configs: filterConfigs })}
          savedFilters={savedFilters}
          filterFromTag={selectedFilter ? getRawFilter(selectedFilter) : null}
          isTagPopup={!!selectedFilter}
          setFiltersItems={(newFilter, shouldRemoveBeforeFunnelFilter) => updatePopupFiltersItems({ newFilter, shouldRemoveBeforeFunnelFilter })}
          filtersItems={filtersItems}
          localSavedFilters={filters}
          className={filtersPopupStyle}
        />
      ) : null}
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    filterStore: {
      filtersData,
    } = {},
    userStore: {
      userMonthPlan: {
        savedFilters,
      } = {},
    } = {},
  }) => ({
    filtersData,
    savedFilters,
  }),
  observer
)(CustomFiltersPanel));
