import React from 'react';
import { inject, observer } from 'mobx-react';
import ReactCountryFlag from 'react-country-flag';
import PropTypes from 'prop-types';
import {
  groupBy, intersection, isEmpty, memoize, sortBy, uniqBy, get,
} from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import ReadMoreReact from 'read-more-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { Coralogix } from 'modules/infra/coralogix';
import { customMetricsNames } from 'enums';
import Loader from 'components/controls/Loader';
import serverCommunication from 'data/serverCommunication';
import EventsTimeline from 'components/pages/users/EventsTimeline';
import countryCode from 'data/countryCode';
import Component from 'components/Component';
import Page from 'components/Page';
import CompanyLogo from 'components/pages/users/CompanyLogo';
import Tooltip from 'components/controls/Tooltip';
import ChannelIcon from 'components/common/ChannelIcon';
import Toggle from 'components/controls/Toggle';
import JourneyStatsTab from 'components/pages/users/journeyStatsTab/JourneyStatsTab';
import UsersPopupFilter from 'components/pages/users/UsersPopupFilter';
import UserPopupTooltip from 'components/pages/users/UserPopupTooltip';
import FeatureFlags from 'components/common/FeatureFlags';
import AIAnalysis from 'components/common/AIAnalysis';
import servicesStore from 'stores/servicesStore';

import { getChannelIcon } from 'components/utils/filters/channels';
import {
  DIRECT_CHANNEL, DIRECT_LABEL, getNickname as getChannelNickname,
} from 'components/utils/channels';
import { LOST_FUNNEL } from 'components/utils/indicators';
import { compose } from 'components/utils/utils';
import { getCustomValue, GROUP_BY } from 'components/utils/users';
import { getFilteredOptionsForRequest, splitFunnelTransitions } from 'components/pages/users/logic/usersPopup';
import { stringifyDateWithTimeZone } from 'components/utils/date';
import { formatBudget } from 'components/utils/budget';
import { journeyTabsOptions, statsTab, timelineTab } from 'components/pages/users/logic/enums';
import { getAttributionModelsWithFlags } from 'attribution/models';
import { queryParamsNames, widgetTypes } from 'components/pages/analyze/enums';
import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { getAIPropsByType } from 'components/common/logic/AIAnalysis';

import pageStyles from 'styles/page.css';
import style from 'styles/users/users-popup.css';
import tablePopup from 'styles/plan/table-popup.css';

const readMoreProps = {
  min: 10,
  readMoreText: '... see more',
};
const EVENT_PAGE_SIZE = 50;

const enhance = compose(
  inject((stores) => {
    const {
      userStore,
      attributionStore: {
        attributionModel,
        dateRange,
        filters,
        autoFilter,
        formattedTimeParams: timeFrame,
        metricsOptions,
      },
    } = stores;
    const {
      funnels,
      userAccount: {
        leadSourcesIdToLabelMap,
        customFieldsIdToLabelMap,
        onboardingConfig,
      },
      userMonthPlan: {
        region,
        UID,
        salesforceapi: {
          tokens: {
            instance_url,
          } = {},
        } = {},
        hubspotapi: {
          portalId,
        } = {},
      },
      APIMethods,
      getMetricNickname,
    } = userStore;

    return {
      region,
      funnels,
      leadSourcesIdToLabelMap,
      customFieldsIdToLabelMap,
      salesforceInstanceUrl: instance_url,
      hubspotPortalId: portalId,
      APIMethods,
      attributionModel,
      dateRange,
      filters,
      autoFilter,
      timeFrame,
      getMetricNickname,
      metricsOptions,
      UID,
      onboardingConfig,
    };
  }),
  observer
);

const provideArray = (items) => (Array.isArray(items) ? items : [items]);

const someIntersection = (list, other) => intersection(provideArray(list), other).length > 0;

class UsersPopup extends Component {
  style = style;

  styles = [tablePopup, pageStyles];

  static defaultProps = {
    selectedUser: {
      user: '',
      accountName: '',
      journey: [],
      countries: [],
    },
  };

  static propTypes = {
    getMetricNickname: PropTypes.func.isRequired,
    selectedUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.attributionModelsOptions = getAttributionModelsWithFlags(props.flags);

    this.kpiFocusOptions = props.metricsOptions.filter((option) => option.value !== 'revenue' && option.value !== 'pipeline');
    let mostAdvancedStage = props.selectedUser.mostAdvancedStage;

    if ((props.selectedUser.mostAdvancedStage === LOST_FUNNEL) || mostAdvancedStage === undefined) {
      mostAdvancedStage = this.kpiFocusOptions[this.kpiFocusOptions.length - 1].value;
    }

    const journeyTabQueryParam = getQueryParams({ queryParamKey: queryParamsNames.journeyTab });
    const preventClickOutside = getQueryParams({ queryParamKey: queryParamsNames.preventClickOutside });
    this.preventClickOutsideJourneyPopup = preventClickOutside === 'true';

    this.state = {
      eventsLimit: EVENT_PAGE_SIZE,
      groupBy: GROUP_BY.ACCOUNTS,
      selectedGroupByValues: [],
      journeySessions: [],
      funnelTransitions: [],
      pendingFunnelTransitions: [],
      forms: [],
      isLoadingJourneySessions: false,
      isLoadingFilteredJourneySessions: false,
      userSelectedFilterOptions: [],
      funnelTransitionsOffset: 0,
      journeySessionsOffset: 0,
      hasMoreJourneySessionsToLoad: true,
      selectedUser: props.selectedUser,
      journeyFilterOptions: [],
      selectedJourneyTab: journeyTabQueryParam || statsTab,
      isExpandJourney: false,
      loadingInitialJourneySessions: false,
      isLoadingJourneyStats: false,
      isLoadingJourneyImpactBy: false,
      journeyStatsEngagementData: {},
      journeyImpactByData: [],
      journeyStatsSelectedAttributionModel: this.attributionModelsOptions?.[0]?.value,
      journeyStatsSelectedKpiFocus: mostAdvancedStage,
      journeyStatsSelectedSegment: 'channel',
    };
    this.elem = React.createRef();
    this.memoizedGroupUserEvents = memoize(this.groupUserEventsInit);
  }

  async setSelectedJourney() {
    const startTime = moment();
    const journey = { ...this.state.selectedUser };
    this.setState({
      isLoadingJourneySessions: true,
      isLoadingFilteredJourneySessions: true,
    }, async () => {
      const journeySessions = await this.getJourneySessions({ identityId: journey.identityId });
      const userJourneySessions = get(journeySessions, 'sessions', []);
      const funnelTransitions = get(journeySessions, 'funnelTransitions', []);
      const journeyMetadata = get(journeySessions, 'journeyMetadata', {});
      const journeyCustomFields = get(journeySessions, 'customFields', {});
      const userForms = get(journeySessions, 'journeyMetadata.forms', []);

      Object.assign(journey, journeyMetadata);
      journey.customFields = journeyCustomFields;
      if (userJourneySessions.length || Object.values(funnelTransitions).length) {
        journey.sessions = userJourneySessions;
        journey.funnelTransitions = funnelTransitions;
      }

      this.setState({
        forms: userForms,
        selectedUser: journey,
        journeySessions: userJourneySessions,
        funnelTransitions,
        isLoadingJourneySessions: false,
        isLoadingFilteredJourneySessions: false,
      });

      Coralogix.sendTimingCustomMetric({
        metric: customMetricsNames.performancePerWidget,
        startTime,
        attributes: {
          widget: widgetTypes.journeyPopup,
          status: 'finished',
        },
      });
    });
  }

  async getJourneySessionsFilterOptions() {
    const identityId = this.state.selectedUser.identityId;
    const requestStoreParams = {
      filters: this.props.filters,
      timeFrame: this.props.timeFrame,
      attributionModel: this.props.attributionModel,
    };

    const requestBody = {
      identityId,
      shouldUsePredefinedFilters: this.props.autoFilter,
      requestStoreParams,
    };

    const response = await serverCommunication.serverRequest('post', 'journeySessionsFilterOptions', JSON.stringify(requestBody), this.props.region);
    if (response.ok) {
      const journeySessionsFilterOptions = await response.json();
      this.setState({ journeyFilterOptions: journeySessionsFilterOptions });
    }
  }

  get filterForEvents() {
    const lookup = {
      [GROUP_BY.ACCOUNTS]: () => true,
      [GROUP_BY.DEALS]: (session) => session.isRevenue || someIntersection(session.selectedChangedBy, this.state.selectedGroupByValues),
      [GROUP_BY.USERS]: (session) => someIntersection(session.email, this.state.selectedGroupByValues) || someIntersection(session.selectedChangedBy, this.state.selectedGroupByValues),
    };

    if (lookup[this.state.groupBy]) {
      return lookup[this.state.groupBy];
    }
    return () => true;
  }

  setUserSelectedFilterOptions = (updatedFilterOptions) => {
    this.setState({ userSelectedFilterOptions: updatedFilterOptions });
  };

  groupUserEventsInit(user, dateRange) {
    const { funnels, getMetricNickname } = this.props;
    const stagesOrder = {};
    const stagesOrderFlexible = {};
    const stagesOrderKeys = [
      ...funnels,
      LOST_FUNNEL,
    ];
    stagesOrderKeys.forEach((key, index) => {
      stagesOrder[key] = index;
      stagesOrderFlexible[`funnel${index + 1}`] = index;
    });

    const {
      revenue,
    } = user;
    const { journeySessions, funnelTransitions, forms } = this.state;

    const funnelStageChanges = funnelTransitions.map((funnelTransition) => {
      const timestamp = funnelTransition.changeDate;
      const funnelStage = funnelTransition.funnel;
      const { amount } = funnelTransition;
      let index = stagesOrder[funnelStage];
      if (index === undefined) {
        index = stagesOrderFlexible[funnelStage];
      }
      let previousFunnelStageNickname = null;
      if (index && index > 0) {
        previousFunnelStageNickname = getMetricNickname({ metric: stagesOrderKeys[index - 1], isSingular: true });
      }
      const funnelStageEventObject = {
        startTime: timestamp,
        endTime: timestamp,
        isFunnelStage: true,
        funnelStage,
        nickname: getMetricNickname({ metric: funnelStage, isSingular: true }),
        previousFunnelStageNickname,
        startTimeDate: new Date(timestamp),
        eventTypeOrder: 4,
        index,
        amount,
      };
      if (Array.isArray(funnelTransition.createdBy) && funnelTransition.createdBy.length) {
        return funnelTransition.createdBy.map((funnelChangeBy) => ({
          selectedChangedBy: funnelChangeBy,
          ...funnelStageEventObject,
        }));
      } else if (typeof funnelTransition.createdBy === 'string') {
        return {
          selectedChangedBy: funnelTransition.createdBy,
          ...funnelStageEventObject,
        };
      } else {
        return funnelStageEventObject;
      }
    });

    const beginningOfTimePeriod = {
      isStartOfPeriod: true,
      startTime: dateRange.start,
      startTimeDate: new Date(dateRange.start),
      eventTypeOrder: 1,
    };
    const endingOfPeriod = {
      isEndOfPeriod: true,
      startTime: dateRange.end,
      startTimeDate: new Date(dateRange.end),
      eventTypeOrder: 1,
    };

    const touchPoints = journeySessions.map((session, index) => ({
      ...session,
      eventIndex: index,
      startTime: session.minStartTime,
      startTimeDate: new Date(session.minStartTime),
      eventTypeOrder: 3,
    }));

    const revenueChanges = Object.keys(revenue || []).map((item) => {
      const {
        createDate: startTime,
        closeDate,
        amount,
        status = '',
        dealName = '',
      } = (revenue[item] || {});
      return {
        startTime,
        closeDate: closeDate ? new Date(closeDate) : null,
        startTimeDate: new Date(startTime),
        amount,
        isRevenue: true,
        selectedChangedBy: dealName,
        eventTypeOrder: 5,
        status,
        dealName,
      };
    });

    const formSubmissions = forms.map((item) => ({
      ...item,
      isForm: true,
      startTimeDate: new Date(item.timestamp),
      eventTypeOrder: 2,
    }));

    const events = [
      ...revenueChanges,
      ...touchPoints,
      ...funnelStageChanges,
      beginningOfTimePeriod,
      endingOfPeriod,
      ...formSubmissions,
    ];

    // Sort by index is used for multiple funnel stage transitions on the exact same time
    return sortBy(events, ['startTimeDate', 'eventTypeOrder', 'index']).reverse();
  }

  async getTimelineAndStatsRequests() {
    this.setState({ loadingInitialJourneySessions: true }, async () => {
      await Promise.all([
        this.getJourneyStats({
          kpiFocus: this.state.journeyStatsSelectedKpiFocus,
          attributionModel: this.state.journeyStatsSelectedAttributionModel,
        }),
        this.getJourneyImpactByData({
          segment: this.state.journeyStatsSelectedSegment,
        }),
      ]);
      this.setState({ loadingInitialJourneySessions: false });
      await Promise.all([
        this.setSelectedJourney(),
        this.getJourneySessionsFilterOptions(),
      ]);
    });
  }

  componentDidMount() {
    this.getTimelineAndStatsRequests();
    if (!this.preventClickOutsideJourneyPopup) {
      document.addEventListener('mousedown', this.onOutsideClick, true);
      document.addEventListener('touchstart', this.onOutsideClick, true);
      document.addEventListener('keydown', this.handleKeyPress);
    }
  }

  componentWillUnmount() {
    if (!this.preventClickOutsideJourneyPopup) {
      document.removeEventListener('mousedown', this.onOutsideClick, true);
      document.removeEventListener('touchstart', this.onOutsideClick, true);
      document.removeEventListener('keydown', this.handleKeyPress);
    }
  }

  onOutsideClick = (e) => {
    const elem = this.elem.current;
    if (elem !== e.target && !elem.contains(e.target)) {
      this.onCloseHandler();
    }
  };

  onCloseHandler = () => {
    this.props.close();
  };

  handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      this.onCloseHandler();
    }
  };

  getChannelNicknameWithDirect = (channel) => (channel === DIRECT_CHANNEL ? DIRECT_LABEL : getChannelNickname(channel));

  groupUserEvents = () => {
    const { dateRange, timezone } = this.props;
    const { selectedUser } = this.state;
    this.memoizedGroupUserEvents = memoize(this.groupUserEventsInit);
    const _eventsList = this.memoizedGroupUserEvents(selectedUser, dateRange);
    const eventsList = _eventsList.filter(this.filterForEvents);
    const limit = Math.min(this.state.eventsLimit, eventsList.length);
    const moreToRender = limit < eventsList.length;
    const eventsForRendering = eventsList.slice(0, limit);
    const filteredEvents = eventsForRendering.filter((event) => !isNaN(event.startTimeDate));
    const userEvents = groupBy(filteredEvents, (event) => {
      const mDate = moment(event.startTime || event.timestamp);
      const stringifyDate = stringifyDateWithTimeZone(timezone);
      return stringifyDate(mDate, 'MMM D, YYYY');
    });
    return { userEvents, moreToRender };
  };

  fetchMoreJourneySessions = async () => {
    if (this.state.isLoadingJourneySessions || !this.state.hasMoreJourneySessionsToLoad) {
      return;
    }

    const {
      journeySessions, funnelTransitions, forms, selectedUser,
    } = this.state;
    const filteredOptionsForRequest = getFilteredOptionsForRequest(this.state.userSelectedFilterOptions);

    this.setState({
      isLoadingJourneySessions: true,
    }, async () => {
      const moreJourneySessions = await this.getJourneySessions({
        identityId: selectedUser.identityId,
        filteredOptions: filteredOptionsForRequest,
      });
      const userJourneySessions = get(moreJourneySessions, 'sessions', []);
      const userFunnelTransitions = get(moreJourneySessions, 'funnelTransitions', []);
      const userForms = get(moreJourneySessions, 'journeyMetadata.forms', []);
      if (userJourneySessions.length > 0) {
        const updatedJourneySessions = [...journeySessions];
        updatedJourneySessions.push(...userJourneySessions);
        this.setState({
          journeySessions: updatedJourneySessions,
        });
      }
      if (userFunnelTransitions.length > 0) {
        const updatedFunnelTransitions = [...funnelTransitions];
        updatedFunnelTransitions.push(...userFunnelTransitions);
        this.setState({
          funnelTransitions: updatedFunnelTransitions,
        });
      }
      if (userForms > 0) {
        const updatedForms = [...forms, userForms];
        this.setState({ forms: updatedForms });
      }
      this.setState({ isLoadingJourneySessions: false });
    });
  };

  fetchFilteredJourneySessions = async (filteredOptions = []) => {
    this.setState({ isLoadingFilteredJourneySessions: true });
    await Promise.all([
      this.getJourneyStats({
        kpiFocus: this.state.journeyStatsSelectedKpiFocus,
        attributionModel: this.state.journeyStatsSelectedAttributionModel,
        filteredOptions,
      }),
      this.getJourneyImpactByData({
        segment: this.state.journeyStatsSelectedSegment,
        filteredOptions,
      }),
    ]);

    const filteredOptionsJourneySessions = await this.getJourneySessions({
      identityId: this.state.selectedUser.identityId,
      filteredOptions,
      isRestOffset: true,
    });
    const userJourneySessions = get(filteredOptionsJourneySessions, 'sessions', []);
    const userFunnelTransitions = get(filteredOptionsJourneySessions, 'funnelTransitions', []);
    const userForms = get(filteredOptionsJourneySessions, 'journeyMetadata.forms', []);
    this.setState({
      isLoadingFilteredJourneySessions: false,
      funnelTransitions: userFunnelTransitions,
      journeySessions: userJourneySessions,
      forms: userForms,
    });
  };

  getMappedJourneyFilterOptions = () => {
    const { journeyFilterOptions } = this.state;
    const mappedFilterOptions = [];
    for (const options of journeyFilterOptions) {
      const values = options.values.map((optionValue) => ({
        isSelected: false,
        value: optionValue,
      }));
      mappedFilterOptions.push({ ...options, values });
    }
    return mappedFilterOptions;
  };

  async getJourneySessions({ identityId, filteredOptions, isRestOffset }) {
    let journeySessionsOffset = this.state.journeySessionsOffset;
    let funnelTransitionsOffset = this.state.funnelTransitionsOffset;
    let pendingFunnelTransitions = this.state.pendingFunnelTransitions;

    if (isRestOffset) {
      journeySessionsOffset = 0;
      funnelTransitionsOffset = 0;
      pendingFunnelTransitions = [];
      this.setState({ journeySessionsOffset, funnelTransitionsOffset, pendingFunnelTransitions });
    }
    const requestStoreParams = {
      filters: this.props.filters,
      timeFrame: this.props.timeFrame,
      attributionModel: this.props.attributionModel,
    };

    const requestBody = {
      limit: EVENT_PAGE_SIZE,
      funnelTransitionsOffset,
      offset: journeySessionsOffset,
      identityId,
      shouldUsePredefinedFilters: this.props.autoFilter,
      filteredOptions,
      region: this.props.region,
      requestStoreParams,
    };

    const response = await serverCommunication.serverRequest('post', 'journeySessions', JSON.stringify(requestBody), this.props.region);
    if (response.ok) {
      const responseData = await response.json();
      const { funnelTransitions, sessions } = responseData.data;

      const dateToSplit = sessions?.[sessions.length - 1]?.minStartTime;
      const { transitionsAfterSplittingDate, transitionsBeforeSplittingDate } = splitFunnelTransitions({
        funnelTransitions,
        dateToSplit,
        pendingTransitions: pendingFunnelTransitions,
        hasMoreToLoad: responseData.hasMore,
      });

      this.setState({
        hasMoreJourneySessionsToLoad: responseData.hasMore,
        journeySessionsOffset: journeySessionsOffset + EVENT_PAGE_SIZE,
        funnelTransitionsOffset: responseData.data.funnelTransitionsOffset,
        pendingFunnelTransitions: transitionsBeforeSplittingDate,
      });

      return {
        ...responseData.data,
        funnelTransitions: transitionsAfterSplittingDate,
      };
    }
    return {};
  }

  async getJourneyImpactByData({
    segment,
    filteredOptions = getFilteredOptionsForRequest(this.state.userSelectedFilterOptions),
    kpiFocus = this.state.journeyStatsSelectedKpiFocus,
  }) {
    this.setState({ isLoadingJourneyImpactBy: true });
    try {
      const requestBody = {
        identityId: this.state.selectedUser.identityId,
        shouldUsePredefinedFilters: this.props.autoFilter,
        filteredOptions,
        region: this.props.region,
        requestStoreParams: {
          timeFrame: this.props.timeFrame,
        },
        segment,
        funnel: kpiFocus,
        frequency: 'week',
      };
      const response = await serverCommunication.serverRequest('post', 'attribution/journey/stats/interactions', JSON.stringify(requestBody), this.props.region);
      if (response.ok) {
        const responseData = await response.json();
        this.setState({ isLoadingJourneyImpactBy: false, journeyImpactByData: responseData });
      }
    } catch (error) {
      this.setState({ isLoadingJourneyImpactBy: false });
      servicesStore.logger.error('failed to get attribution/journey/stats/interactions', {
        error,
        UID: this.props.UID,
        identityId: this.state.selectedUser.identityId,
      });
    }
  }

  async getJourneyStats({
    kpiFocus,
    attributionModel,
    filteredOptions = getFilteredOptionsForRequest(this.state.userSelectedFilterOptions),
  }) {
    this.setState({ isLoadingJourneyStats: true });
    try {
      const requestBody = {
        identityId: this.state.selectedUser.identityId,
        shouldUsePredefinedFilters: this.props.autoFilter,
        filteredOptions,
        region: this.props.region,
        requestStoreParams: {
          timeFrame: this.props.timeFrame,
        },
        attributionModel,
        funnel: kpiFocus,
      };
      const response = await serverCommunication.serverRequest('post', 'attribution/journey/stats/engagement', JSON.stringify(requestBody), this.props.region);
      if (response.ok) {
        const responseData = await response.json();
        this.setState({ journeyStatsEngagementData: responseData, isLoadingJourneyStats: false });
      }
    } catch (error) {
      this.setState({ isLoadingJourneyStats: false });
      servicesStore.logger.error('failed to get attribution/journey/stats/engagement', {
        error,
        UID: this.props.UID,
        identityId: this.state.selectedUser.identityId,
      });
    }
  }

  setJourneyStatsState({ state, value }) {
    this.setState({ [state]: value });
  }

  render() {
    const {
      timezone,
      leadSourcesIdToLabelMap = {},
      customFieldsIdToLabelMap = {},
      salesforceInstanceUrl,
      hubspotPortalId,
      getMetricNickname,
    } = this.props;
    const {
      journeySessions,
      isLoadingFilteredJourneySessions,
      selectedUser,
    } = this.state;
    const {
      timeSinceFirst,
      timeSinceLast,
      emails,
      countries,
      devices,
      displayName,
      domain,
      currentStage,
      mostAdvancedStage,
      customFields = {},
      leadSources = {},
    } = selectedUser;

    const clearbitUrl = 'https://logo.clearbit.com';
    const imageSrc = `${clearbitUrl}/${domain}`;
    const fallbackSrc = `${clearbitUrl}/${displayName}`;

    const crmLeadSourceSection = [];
    for (const [fieldId, fieldLabel] of Object.entries(leadSourcesIdToLabelMap)) {
      if (leadSources[fieldId]) {
        crmLeadSourceSection.push((
          <div className={this.classes.label}>
            {fieldLabel}
            <div className={classnames(this.classes.row, this.classes.text)}>
              <ReadMoreReact
                {...readMoreProps}
                text={getCustomValue(leadSources[fieldId])}
              />
            </div>
          </div>
        ));
      }
    }

    const getObjectLine = (object) => {
      const objectLineNames = {
        revenueForAccount: 'Total Revenue',
        pipelineForAccount: 'Total Pipeline',
        MRR: this.props.getMetricNickname({ metric: 'MRR' }),
      };
      return selectedUser[object]
        ? (
          <div className={this.classes.label}>
            {objectLineNames[object]}
            <div className={this.classes.text}>
              {formatBudget(selectedUser[object], true, true, false, false, false)}
            </div>
          </div>
        ) : null;
    };

    const customFieldOnboardingConfig = this.props.onboardingConfig?.journeys?.customField;
    const customFieldElements = [];
    for (const [customFieldId, customFieldValue] of Object.entries(customFields)) {
      const customFieldLabel = customFieldsIdToLabelMap[customFieldId] || customFieldId;
      customFieldElements.push((
        <div className={this.classes.label} key={customFieldId} id={customFieldOnboardingConfig === customFieldId ? 'onboardingCustomField' : null}>
          {customFieldLabel}
          <div className={classnames(this.classes.row, this.classes.text)}>
            <ReadMoreReact
              text={customFieldValue.join(', ')}
              {...readMoreProps}
            />
          </div>
        </div>
      ));
    }

    const { userEvents, moreToRender } = this.groupUserEvents();
    const currentStageNickname = getMetricNickname({ metric: currentStage, isSingular: true });
    const mostAdvancedStageNickname = getMetricNickname({ metric: mostAdvancedStage, isSingular: true });
    return (
      <div>
        <Page
          popup
          width="860px"
          contentClassName={this.classes.content}
          innerClassName={this.classes.inner}
          otherProps={{
            id: 'user-journeys-popup',
          }}
        >
          {(this.state.loadingInitialJourneySessions) ? (
            <div className={pageStyles.locals.loader}>
              <Loader newStyle />
            </div>
          ) : (
            <div ref={this.elem} className={this.classes.container}>
              <div className={this.classes.userJourneyWhiteBack} />
              <div className={this.classes.topRight}>
                <div
                  className={this.classes.close}
                  onClick={this.onCloseHandler}
                />
              </div>
              <div className={classnames(this.classes.journeyTopBar, this.state.isExpandJourney ? this.classes.journeyTopBarExpandJourney : null)}>
                <Toggle
                  options={journeyTabsOptions}
                  selectedValue={this.state.selectedJourneyTab}
                  onClick={(value) => this.setState({ selectedJourneyTab: value })}
                />
                <div className={this.classes.journeyFilterWrapper}>
                  {this.state.journeyFilterOptions.length > 0 && (
                  <UsersPopupFilter
                    setUserSelectedFilterOptions={(updatedFilterOptions) => this.setUserSelectedFilterOptions(updatedFilterOptions)}
                    userSelectedFilterOptions={this.state.userSelectedFilterOptions}
                    journeyFilterOptions={this.getMappedJourneyFilterOptions()}
                    fetchFilteredJourneySessions={(filteredOptions) => this.fetchFilteredJourneySessions(filteredOptions)}
                  />
                  )}
                  <FeatureFlags flag={this.props.flags.aiAnalysisButtonDemoOnly}>
                    <AIAnalysis
                      {...getAIPropsByType({ type: widgetTypes.journeyPopup })}
                    />
                  </FeatureFlags>
                </div>
              </div>

              {!isEmpty(this.state.journeyImpactByData) && (
              <JourneyStatsTab
                key="journey-stats-tab"
                journeyStatsEngagementData={this.state.journeyStatsEngagementData}
                getJourneyStatsData={({ kpiFocus, attributionModel }) => this.getJourneyStats({
                  kpiFocus,
                  attributionModel,
                })}
                journeyImpactByData={this.state.journeyImpactByData}
                getJourneyImpactByData={({ segment, kpiFocus }) => this.getJourneyImpactByData({ segment, kpiFocus })}
                isLoadingJourneyStats={this.state.isLoadingJourneyStats}
                isLoadingJourneyImpactBy={this.state.isLoadingJourneyImpactBy}
                attributionModel={this.state.journeyStatsSelectedAttributionModel}
                attributionModelsOptions={this.attributionModelsOptions}
                kpiFocusOptions={this.kpiFocusOptions}
                selectedKpiFocus={this.state.journeyStatsSelectedKpiFocus}
                selectedSegment={this.state.journeyStatsSelectedSegment}
                setJourneyStatsState={({ state, value }) => this.setJourneyStatsState({ state, value })}
                hidden={this.state.selectedJourneyTab !== statsTab}
              />
              )}

              <EventsTimeline
                key="events-timeline"
                timezone={timezone}
                groupedEvents={userEvents}
                emails={emails}
                moreToRender={moreToRender}
                journeySessionsOffset={this.state.journeySessionsOffset}
                showMoreEvents={() => this.setState((prevState) => ({ eventsLimit: prevState.eventsLimit + EVENT_PAGE_SIZE }))}
                isLoadingFilteredJourneySessions={isLoadingFilteredJourneySessions}
                fetchMoreJourneySessions={this.fetchMoreJourneySessions}
                hidden={this.state.selectedJourneyTab !== timelineTab}
                isLoadingJourneySessions={this.state.hasMoreJourneySessionsToLoad ? this.state.isLoadingJourneySessions : false}
              />

              <div className={classnames(this.classes.userInfo, this.state.isExpandJourney ? this.classes.isExpandJourney : null)}>
                <section className={this.classes.section}>
                  <CompanyLogo
                    src={imageSrc}
                    fallbackSrc={fallbackSrc}
                    className={this.classes.icon}
                  />
                  {!this.state.isExpandJourney && (
                  <>
                    <div className={this.classes.displayName}>{displayName}</div>
                    <div className={this.classes.email}>
                      <Tooltip
                        id="email-tooltip"
                        tip={UserPopupTooltip({
                          user: selectedUser,
                          salesforceInstanceUrl,
                          hubspotPortalId,
                        })}
                        html
                        TooltipProps={{
                          multiline: true,
                          clickable: true,
                          event: 'click',
                          globalEventOff: 'click',
                          className: this.classes.tooltip,
                        }}
                      >
                        <span style={{ cursor: 'pointer' }}>
                          {(emails && emails.length === 1) ? emails[0] : 'Multiple users'}
                        </span>
                      </Tooltip>
                    </div>
                    <div className={this.classes.channels}>
                      {journeySessions && uniqBy(journeySessions, 'channel').map((item, index) => {
                        const journeySessionKey = `journeySession-${item.channel}-${index}`;
                        return (
                          <Tooltip
                            key={journeySessionKey}
                            id={item.channel}
                            tip={this.getChannelNicknameWithDirect(item.channel)}
                          >
                            <ChannelIcon
                              className={this.classes.channelBox}
                              channelIcon={getChannelIcon(item.channel)}
                              channel={item.channel}
                            />
                          </Tooltip>
                        );
                      })}
                    </div>
                  </>
                  )}
                </section>
                {!this.state.isExpandJourney && (
                <>
                  <section className={this.classes.section}>
                    <div className={this.classes.label}>
                      Current stage
                      <div className={this.classes.text}>
                        {currentStageNickname}
                      </div>
                    </div>
                    <div className={this.classes.label}>
                      Most advanced stage
                      <div className={this.classes.text}>
                        {mostAdvancedStageNickname}
                      </div>
                    </div>
                    <div className={this.classes.label}>
                      First Touch
                      <div className={this.classes.text}>
                        {timeSinceFirst}
                      </div>
                    </div>
                    <div className={this.classes.label}>
                      Last Touch
                      <div className={this.classes.text}>
                        {timeSinceLast}
                      </div>
                    </div>
                  </section>
                  <section className={this.classes.section}>
                    {!isEmpty(countries) && (
                    <div className={this.classes.label}>
                      Country
                      <div
                        className={classnames(this.classes.row, this.classes.text)}
                      >
                        {countries.map((item, index) => {
                          const countryKey = `flag-${item}-${index}`;
                          const flagKey = `flag-${item}-${index}`;
                          return (
                            <Tooltip
                              key={countryKey}
                              id={item}
                              tip={countryCode[item] || item}
                            >
                              <div key={flagKey} className={this.classes.flag}>
                                <ReactCountryFlag
                                  code={item}
                                  svg
                                  styleProps={{
                                    width: '24px',
                                    height: '18px',
                                  }}
                                />
                              </div>
                            </Tooltip>
                          );
                        })}
                      </div>
                    </div>
                    )}
                    {!isEmpty(devices) && (
                    <div className={this.classes.label}>
                      Device
                      <div
                        className={classnames(this.classes.row, this.classes.text)}
                      >
                        {devices.map((item, index) => {
                          const deviceKey = `device-${item}-${index}`;
                          return (
                            <Tooltip
                              key={deviceKey}
                              id={item}
                              tip={item}
                            >
                              <div
                                className={this.classes.device}
                                data-icon={`device:${item}`}
                              />
                            </Tooltip>
                          );
                        })}
                      </div>
                    </div>
                    )}
                    {getObjectLine('MRR')}
                    {customFieldElements}
                    {crmLeadSourceSection}
                  </section>
                </>
                )}
              </div>
            </div>
          )}
        </Page>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(UsersPopup));
