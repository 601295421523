import { isEmpty, camelCase, isNaN } from 'lodash';

import userStore from 'stores/userStore';
import { isShowCostMetric } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { mergeAllFunnelsTableData, getAllColumnOptionsAndGroupedOptions } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { mapChartIndicator } from 'components/utils/indicators';
import base from './base';

function getWidgetConfig({ widgetConfig } = {}) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  const baseConfig = base.getWidgetConfig({ widgetConfig });
  delete baseConfig.conversionIndicator;
  delete baseConfig.isOtherCampaignsHidden;

  return baseConfig;
}

function processResponseFromServer({ result, widgetConfig }) {
  if (isEmpty(result)) {
    return {};
  }

  const updatedResponse = {
    countPerStage: result.countPerStage,
    isAttribution: result.isAttribution,
    dataByFunnel: result,
  };

  updatedResponse.mergedFunnelsTableData = mergeAllFunnelsTableData({ tableDataByFunnel: result, selectedStageKey: mapChartIndicator(widgetConfig.conversionIndicator) });

  return updatedResponse;
}

function getWidgetConfigurationForQuestionResult({ widgetConfig }) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  const baseConfig = base.getWidgetConfigurationForQuestionResult({ widgetConfig });

  const {
    activeColumns,
    isAttribution,
    defaultStageKey = 'funnel1',
    segmentsAnalysisParams,
  } = baseConfig;

  if (activeColumns?.length > 0) {
    const { allColumnOptions } = getAllColumnOptionsAndGroupedOptions({
      isAttribution,
      selectedSegments: segmentsAnalysisParams,
    });

    const isShowCost = isShowCostMetric({
      firstSegmentValue: segmentsAnalysisParams.firstSegment,
      secondSegmentValue: segmentsAnalysisParams.secondSegment,
    });

    if (isShowCost) {
      allColumnOptions.push({ value: 'cost' });
      allColumnOptions.push({ value: 'costPerWebvisits' }, { value: 'costPerPageviews' });
      for (const funnel of userStore.userFunnels) {
        allColumnOptions.push({ value: camelCase(`cost per ${funnel}`) });
      }
    }

    allColumnOptions.push(
      { value: 'newPipeline' },
      { value: 'touchedPipeline' },
      { value: 'newBookings' },
      { value: 'touchedRevenue' }
    );

    baseConfig.reorderColumns = {
      [defaultStageKey]: [...activeColumns],
    };

    for (const metric of activeColumns) {
      if (metric === 'newPipeline') {
        baseConfig.reorderColumns[defaultStageKey].push('touchedPipeline');
        if (!isAttribution) {
          baseConfig.reorderColumns[defaultStageKey] = baseConfig.reorderColumns[defaultStageKey].filter((columnName) => columnName !== 'newPipeline');
        }
        continue;
      }
      if (metric === 'newBookings') {
        baseConfig.reorderColumns[defaultStageKey].push('touchedRevenue');
        if (!isAttribution) {
          baseConfig.reorderColumns[defaultStageKey] = baseConfig.reorderColumns[defaultStageKey].filter((columnName) => columnName !== 'newBookings');
        }
        continue;
      }
      const metricType = userStore.getMetricType({ metric });
      if (metricType === 'funnelStage') {
        baseConfig.reorderColumns[defaultStageKey] = baseConfig.reorderColumns[defaultStageKey].filter((columnName) => columnName !== metric);
        if (isAttribution) {
          baseConfig.reorderColumns[defaultStageKey].unshift(camelCase(`attributed ${metric}`));
        }
        baseConfig.reorderColumns[defaultStageKey].unshift(camelCase(`touched ${metric}`));
      }
    }

    baseConfig.inactiveColumns[defaultStageKey] = allColumnOptions.filter(({ value }) => !baseConfig.reorderColumns[defaultStageKey].includes(value)).map(({ value }) => value);
  }

  return baseConfig;
}

function isWidgetEssentialDataLoaded({ result }) {
  if (!result) {
    return false;
  }

  const funnelData = result.funnel1;
  if (!funnelData) {
    return false;
  }

  return !isNaN(funnelData[0]?.attributed);
}

export default {
  ...base,
  getWidgetConfig,
  processResponseFromServer,
  getWidgetConfigurationForQuestionResult,
  eventsWidgetName: 'Impact Table',
  isWidgetEssentialDataLoaded,
};
