import {
  action, decorate, observable, computed,
} from 'mobx';
import { isEqual, isEmpty } from 'lodash';

import * as timeUtils from 'stores/analyze/timeUtils';
import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import timeFrameModule from 'modules/timeframe';
import servicesStore from 'stores/servicesStore';

import { getWidgetDefinitions } from 'widgetDefinitions';
import { widgetsConfig, widgetTypes, widgetsForPollingRequest } from 'components/pages/analyze/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { folderOfUnassignedReports } from 'components/pages/reports/enums';
import { getWidgetsDataV2 } from 'components/pages/analyze/widgetsRequest';
import { isFilterChangesRequireServerRequest, getTimeframeObjectToSave } from 'stores/logic/reportsStore';
import { unifyWidgetWithTheSameConfig } from 'components/pages/reports/logic/reports';
import { widgetHeaderConfigKeys } from 'components/common/logic/enums';

class ReportsStore {
  constructor() {
    this.selectedReportId = null;
    this.reportsWithWidgetsData = [];
    this.reportsFolders = [];
    this.isReportsRequestLoaded = false;
    this.isReportHasNewChanges = {};
    this.isSaveWidgetsReportsLoaded = false;
    this.isUpdatingWidgetsReportsLoading = false;
    this.searchQuery = '';
  }

  updateReportFolder({ reportId, folderId }) {
    const reportToUpdateIndex = this.reportsWithWidgetsData.findIndex((report) => report.id === reportId);
    this.reportsWithWidgetsData[reportToUpdateIndex].folderId = folderId;
  }

  get selectedReport() {
    return this.reportsList.find((report) => report.value === this.selectedReportId) || {};
  }

  async setSelectedReport(selectedReport) {
    this.selectedReportId = selectedReport.value;
    const widgetsOfSelectedReport = this.reportsWithWidgetsData.find((reportsWithWidgets) => reportsWithWidgets.name === selectedReport.label && reportsWithWidgets.id === selectedReport.value);
    if (widgetsOfSelectedReport) {
      await this.getWidgetsDataForSelectedReport({ reportId: selectedReport.value });
    }
  }

  get reportsList() {
    return (
      this.reportsWithWidgetsData.map((report) => ({
        value: report.id,
        label: report.name,
        folderId: report.folderId,
        updatedDate: report.updatedDate,
        filters: report.configuration?.filters,
        timeframe: report.configuration?.timeframe,
        isCompareToPreviousEnabled: report.configuration?.isCompareToPreviousEnabled,
        sourceType: report.sourceType,
        templateName: report.templateName,
      })) || []
    );
  }

  get foldersList() {
    const foldersList = this.reportsFolders.map((folder) => ({
      label: folder.name,
      value: folder.id,
      updatedDate: folder.updatedDate,
    }));
    return foldersList.concat(folderOfUnassignedReports);
  }

  get widgetsOfSelectedReport() {
    const widgetsOfSelectedReport = this.reportsWithWidgetsData.find((reportsWithWidgets) => reportsWithWidgets.name === this.selectedReport.label && reportsWithWidgets.id === this.selectedReport.value);
    if (!widgetsOfSelectedReport) {
      return [];
    }

    return widgetsOfSelectedReport.widgets.sort((a, b) => {
      if (a.orderNumber === -1) { return 1; }
      if (b.orderNumber === -1) { return -1; }

      return a.orderNumber - b.orderNumber;
    });
  }

  reorderReportWidgets({ from, to }) {
    const orderedWidgetIds = this.widgetsOfSelectedReport.map((widget) => widget.id);

    const [movedWidgetId] = orderedWidgetIds.splice(from, 1);
    orderedWidgetIds.splice(to, 0, movedWidgetId);

    const reportIndex = this.reportsWithWidgetsData.findIndex(
      ({ id }) => id === this.selectedReport.value
    );

    const widgetOrderConfig = {};
    for (let i = 0; i < orderedWidgetIds.length; i++) {
      widgetOrderConfig[orderedWidgetIds[i]] = i;
    }
    for (const widget of this.reportsWithWidgetsData[reportIndex].widgets) {
      widget.orderNumber = widgetOrderConfig[widget.id];
    }

    this.reorderWidgetsServerRequest({ widgetOrderConfig });
  }

  async reorderWidgetsServerRequest({ widgetOrderConfig }) {
    const region = userStore.userMonthPlan.region;

    const requestBody = {
      reportId: this.selectedReport.value,
      widgetIdToOrderNumber: widgetOrderConfig,
    };
    await serverCommunication.serverRequest('POST', 'reports/updateReportWidgetsOrder', JSON.stringify(requestBody), region);
  }

  updateReportWidgets({ reportId, widgets }) {
    const newReportsWithWidgetsData = [...this.reportsWithWidgetsData];
    let reportIdToUpdate = reportId;
    if (!reportIdToUpdate) {
      reportIdToUpdate = this.selectedReport.value;
    }
    const reportsWithWidgetsDataIndex = newReportsWithWidgetsData.findIndex((reportsWithWidgets) => reportsWithWidgets.id === reportIdToUpdate);
    for (const widget of widgets) {
      const widgetIndex = newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets.findIndex((reportWidget) => reportWidget.id === widget.id);
      if (widgetIndex !== -1) {
        newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets[widgetIndex] = widget;
        continue;
      }
      newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets.push(widget);
    }
    this.reportsWithWidgetsData = newReportsWithWidgetsData;
  }

  async getWidgetsDataForSelectedReport({ reportId }) {
    const widgetsMetadataForRequest = this.reportsWithWidgetsData.find((reportsWithWidgets) => reportsWithWidgets.id === reportId);
    const unifyWidgetsMetadataForRequest = unifyWidgetWithTheSameConfig({ widgets: widgetsMetadataForRequest.widgets });
    const { widgetsArrayForServerRequest, widgetsConfigForRequest } = this.widgetsDataForServerRequest({ widgetsMetadataForRequest: unifyWidgetsMetadataForRequest || [] });

    await this.getWidgetsDataServerRequest({ widgetsArrayForServerRequest, widgetsConfigForRequest, reportId });
  }

  async getReportsWithWidgetsMetadataRequest() {
    this.isReportsRequestLoaded = false;
    try {
      let response = await serverCommunication.serverRequest('GET', 'reports/widgetsPerReport');
      response = await response.json();
      this.reportsWithWidgetsData = response.widgetsPerReport.allWidgetsPerReport || [];
      this.reportsFolders = response.widgetsPerReport.allFolders || [];
      this.isReportsRequestLoaded = true;
    } catch (exception) {
      this.isReportsRequestLoaded = true;
      servicesStore.logger.error('failed to get reports with widgets metadata from the server', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
      });
    }
  }

  isHavingReportWithWidgets() {
    return this.reportsWithWidgetsData.length > 0 && this.reportsWithWidgetsData.some((reportWithWidgetsMetadata) => reportWithWidgetsMetadata.widgets.length > 0);
  }

  widgetsDataForServerRequest({ widgetsMetadataForRequest, shouldPullDataFromServer = true }) {
    const widgetsForServerRequest = [];
    const widgetsConfigForServerRequest = {};

    for (const widget of widgetsMetadataForRequest) {
      const reportId = widget.reportId;
      const reportToUpdateIndex = this.reportsWithWidgetsData.findIndex((report) => report.id === reportId);
      const widgetFilters = [...widget.configuration[widgetHeaderConfigKeys.filters], ...(this.reportsWithWidgetsData[reportToUpdateIndex].configuration?.filters || [])];

      const timeFrameParams = timeFrameModule.getTimeframeParams({
        ...widget.configuration[widgetHeaderConfigKeys.timeframe],
        fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
      });
      const { startDate, endDate } = timeFrameParams;

      const isCustomDateTimeframe = widget.configuration[widgetHeaderConfigKeys.timeframe].value === TIMEFRAME_VALUES.CUSTOM;
      const widgetConfig = {
        ...widget.configuration,
        filters: widgetFilters,
        id: widget.id,
        [widgetHeaderConfigKeys.timeframe]: {
          ...widget.configuration[widgetHeaderConfigKeys.timeframe],
          startDate: isCustomDateTimeframe ? startDate : new Date(timeUtils.getTSForTimezone(startDate)),
          endDate: isCustomDateTimeframe ? endDate : new Date(timeUtils.getTSForTimezone(endDate)),
        },
      };

      if (widget.unifyIds) {
        widgetConfig.unifyIds = widget.unifyIds;
      }

      if (widget.configuration.isCompareToPreviousEnabled) {
        const { previousEndDate, previousStartDate } = timeFrameParams;
        widgetConfig.compareToPreviousTimeFrame = true;
        widgetConfig.previousTimeFrame = {
          endDate: new Date(timeUtils.getTSForTimezone(previousEndDate)),
          startDate: new Date(timeUtils.getTSForTimezone(previousStartDate)),
        };
      }

      if (widgetConfig.filters) {
        for (const filter of widgetConfig.filters) {
          if (filter.data.timeFrame) {
            const { startDate: startTS, endDate: endTS } = timeFrameModule.getTimeframeParams({ ...filter.data.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
            filter.data.timeFrame = {
              value: filter.data.timeFrame.value,
              startTS,
              endTS,
            };
          }
        }
      }

      widget.isLoaded = {};

      const relevantWidgetConfig = Object.values(widgetsConfig).find((config) => config.type === widget.type);
      const relatedWidgetsToRun = relevantWidgetConfig?.widgetsToServerRequest || [];
      for (const relatedWidget of relatedWidgetsToRun) {
        const shouldSkipTrendWidgetRequest = relatedWidget === widgetTypes.trendBySegments && !widget.configuration?.isCalculateAdvancedMetrics;
        const shouldSkipUpliftWidgetRequest = relatedWidget === widgetTypes.upliftBySegments && isEmpty(widget.configuration?.upliftBySegmentsParams);
        if (shouldSkipTrendWidgetRequest || shouldSkipUpliftWidgetRequest) {
          continue;
        }

        if (!widgetsConfigForServerRequest[relatedWidget]) {
          widgetsForServerRequest.push(relatedWidget);
          widgetsConfigForServerRequest[relatedWidget] = [];
        }
        widgetsConfigForServerRequest[relatedWidget].push(widgetConfig);
        if (shouldPullDataFromServer && !widgetsForPollingRequest.includes(relatedWidget)) {
          widget.isLoaded[relatedWidget] = false;
        }
      }
    }

    return {
      widgetsArrayForServerRequest: widgetsForServerRequest,
      widgetsConfigForRequest: widgetsConfigForServerRequest,
      widgetsMetadata: widgetsMetadataForRequest,
    };
  }

  async getWidgetsDataServerRequest({
    widgetsArrayForServerRequest,
    widgetsConfigForRequest,
    reportId,
    concatResultsPerWidget = {},
    parentRequestId,
  }) {
    const region = userStore.userMonthPlan.region;

    for (const widgetForServerRequest of widgetsArrayForServerRequest) {
      const widgetConfigs = widgetsConfigForRequest[widgetForServerRequest];
      if (!widgetConfigs) {
        continue;
      }
      for (const widgetConfig of widgetConfigs) {
        const widgetDefinitions = getWidgetDefinitions({ widget: widgetForServerRequest });
        const clonedWidgetConfig = widgetDefinitions ? widgetDefinitions.getWidgetConfig({ widgetConfig }) : widgetConfig;

        if (widgetsForPollingRequest.includes(widgetForServerRequest)) {
          const requestData = {
            widget: widgetForServerRequest,
            widgetConfig: clonedWidgetConfig,
            addBaseAnalyzeConfig: false,
          };

          if (concatResultsPerWidget[widgetForServerRequest]) {
            requestData.isConcatResult = true;
            requestData.parentRequestId = parentRequestId;
          }
          getWidgetsDataV2(requestData);
          continue;
        }

        const body = {
          region,
          requestStoreParams: {
            widgets: [widgetForServerRequest],
            widgetsConfig: { [widgetForServerRequest]: [clonedWidgetConfig] },
          },
        };
        this.handleServerRequestWithoutWaiting({
          body, region, reportId, concatResultsPerWidget,
        });
      }
    }
  }

  async handleServerRequestWithoutWaiting({
    body, region, reportId, concatResultsPerWidget = {},
  }) {
    const response = await serverCommunication.serverRequest('POST', 'analysis/widgets', JSON.stringify(body), region);
    const responseData = await response.json();
    const { data } = responseData;
    const { id: widgetId, ...payload } = data;

    const allReportWidgets = this.reportsWithWidgetsData.find((report) => report.id === reportId).widgets;
    const widgetsOfRequest = allReportWidgets.filter((reportWidget) => widgetId === reportWidget.id);
    const updateWidgets = [...widgetsOfRequest];

    for (const [widgetType, widgetResponse] of Object.entries(payload)) {
      for (const widget of updateWidgets) {
        if (widget.id === widgetId) {
          let newWidgetData = widgetResponse;
          const currentWidgetData = widget[widgetType];
          if (concatResultsPerWidget[widgetType] && currentWidgetData) {
            newWidgetData = [...currentWidgetData, ...newWidgetData];
          }
          widget[widgetType] = newWidgetData;
          if (!widget.isLoaded) {
            widget.isLoaded = {};
          }
          widget.isLoaded[widgetType] = true;
        }
      }
      this.updateReportWidgets({ widgets: updateWidgets, reportId });
    }
  }

  async addWidgetToReportRequest({
    reportId,
    title,
    type,
    timeFrame,
    isCompareToPreviousEnabled,
    filters,
    attributionModel,
    attributionCredit,
    widgetConfig,
  }) {
    const profile = servicesStore.authService.getProfileSync();
    const createdBy = profile.email;

    for (const filter of filters) {
      if (filter.data.timeFrame) {
        filter.data.timeFrame = getTimeframeObjectToSave({ timeframe: filter.data.timeFrame });
      }
    }

    const body = {
      UID: userStore.userMonthPlan.UID,
      region: userStore.userMonthPlan.region,
      reportId,
      createdBy,
      title,
      type,
      orderNumber: -1,
      configuration: {
        [widgetHeaderConfigKeys.timeframe]: getTimeframeObjectToSave({ timeframe: timeFrame }),
        isCompareToPreviousEnabled,
        [widgetHeaderConfigKeys.filters]: filters,
        attributionModel,
        attributionCredit,
        ...widgetConfig,
      },
    };

    try {
      let response = await serverCommunication.serverRequest('POST', 'reports/addWidgetToReport', JSON.stringify(body), userStore.userMonthPlan.region);
      response = await response.json();
      const newWidgetMetadata = response.newWidget;
      if (reportId === this.selectedReport.value) {
        const {
          widgetsArrayForServerRequest,
          widgetsConfigForRequest,
          widgetsMetadata,
        } = this.widgetsDataForServerRequest({ widgetsMetadataForRequest: [newWidgetMetadata] });
        this.updateReportWidgets({ widgets: widgetsMetadata, reportId });

        await this.getWidgetsDataServerRequest({
          widgetsArrayForServerRequest, widgetsConfigForRequest, reportId,
        });
      } else {
        this.updateReportWidgets({ widgets: [newWidgetMetadata], reportId });
      }
    } catch (exception) {
      servicesStore.logger.error('failed to add widget to report request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        reportId,
        createdBy,
        title,
        type,
      });
    }
  }

  async createNewReportRequest({
    name, folderId, configuration, widgets, sourceType, templateName,
  }) {
    const body = {
      name,
      folderId,
    };

    if (configuration) {
      body.configuration = configuration;
    }

    if (widgets) {
      body.widgets = widgets;
    }

    if (sourceType) {
      body.sourceType = sourceType;
    }

    if (templateName) {
      body.templateName = templateName;
    }

    try {
      let response = await serverCommunication.serverRequest('POST', 'reports/createNewReport', JSON.stringify(body), userStore.userMonthPlan.region);
      response = await response.json();
      const newCreatedReport = {
        value: response.newReport.id,
        label: response.newReport.name,
      };

      this.reportsWithWidgetsData.push({
        id: response.newReport.id,
        name: response.newReport.name,
        folderId,
        configuration: response.newReport.configuration,
        widgets: [],
        sourceType,
        templateName,
      });

      return newCreatedReport;
    } catch (exception) {
      servicesStore.logger.error('failed to create new report request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        reportName: name,
      });
      return {};
    }
  }

  addReportToFolderRequest({ reportId, folderId }) {
    const body = {
      folderId,
      id: reportId,
    };
    try {
      const reportToUpdateIndex = this.reportsWithWidgetsData.findIndex(
        (reportWithWidgetsMetadata) => reportWithWidgetsMetadata.id === reportId
      );
      this.reportsWithWidgetsData[reportToUpdateIndex].folderId = folderId;
      serverCommunication.serverRequest('POST', 'reports/addReportToFolder', JSON.stringify(body), userStore.userMonthPlan.region);
    } catch (exception) {
      servicesStore.logger.error('failed to add report to folder request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
      });
    }
  }

  async deleteWidgetRequest({ reportId, widgetId }) {
    const body = {
      id: widgetId,
      reportId,
    };

    try {
      const newReportsWithWidgetsData = [...this.reportsWithWidgetsData];
      const reportsWithWidgetsDataIndex = newReportsWithWidgetsData.findIndex((reportsWithWidgets) => reportsWithWidgets.id === reportId);
      newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets = newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets.filter((widget) => widget.id !== widgetId);
      this.reportsWithWidgetsData = newReportsWithWidgetsData;

      await serverCommunication.serverRequest('DELETE', 'reports/deleteWidget', JSON.stringify(body), userStore.userMonthPlan.region);
    } catch (exception) {
      servicesStore.logger.error('failed to delete widget request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        reportId,
        widgetId,
      });
    }
  }

  async deleteReportRequest({ reportId }) {
    const body = {
      id: reportId,
    };

    try {
      await serverCommunication.serverRequest('DELETE', 'reports/delete', JSON.stringify(body), userStore.userMonthPlan.region);
      this.reportsWithWidgetsData = this.reportsWithWidgetsData.filter((report) => report.id !== reportId);
      if (this.reportsWithWidgetsData.length > 0) {
        const firstReport = this.reportsWithWidgetsData[0];
        await this.setSelectedReport({
          label: firstReport.name,
          value: firstReport.id,
        });
      } else {
        this.selectedReport = null;
      }
    } catch (exception) {
      servicesStore.logger.error('failed to delete report request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        reportId,
      });
    }
  }

  async renameReportRequest({ name, reportId }) {
    const body = {
      name,
      id: reportId,
    };

    try {
      await serverCommunication.serverRequest('POST', 'reports/rename', JSON.stringify(body), userStore.userMonthPlan.region);
      const reportToUpdateIndex = this.reportsWithWidgetsData.findIndex((reportWithWidgetsMetadata) => reportWithWidgetsMetadata.id === reportId);
      this.reportsWithWidgetsData[reportToUpdateIndex].name = name;
    } catch (exception) {
      servicesStore.logger.error('failed to rename report request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        newReportName: name,
        reportId,
      });
    }
  }

  async updateReportConfiguration({ configuration, reportId }) {
    const reportToUpdateIndex = this.reportsWithWidgetsData.findIndex((report) => report.id === reportId);
    let shouldPullDataFromServer = false;

    if (!this.isReportHasNewChanges[reportId]) {
      this.isReportHasNewChanges[reportId] = {};
    }
    if (!this.isReportHasNewChanges[reportId].global) {
      this.isReportHasNewChanges[reportId].global = {};
    }
    if (!this.reportsWithWidgetsData[reportToUpdateIndex].configuration) {
      this.reportsWithWidgetsData[reportToUpdateIndex].configuration = {};
    }

    if (configuration.filters) {
      shouldPullDataFromServer = isFilterChangesRequireServerRequest({ existingFilters: this.reportsWithWidgetsData[reportToUpdateIndex].configuration.filters, updatedFilters: configuration.filters });
      this.reportsWithWidgetsData[reportToUpdateIndex].configuration.filters = configuration.filters;
      this.isReportHasNewChanges[reportId].global.filters = true;
    }

    if (configuration.timeframe) {
      this.reportsWithWidgetsData[reportToUpdateIndex].configuration.timeframe = configuration.timeframe;
      const widgetUpdateTasks = [];
      for (const widget of this.reportsWithWidgetsData[reportToUpdateIndex].widgets) {
        widgetUpdateTasks.push(this.updateWidgetTimeframe({ reportId, widgetId: widget.id, timeFrame: configuration.timeframe }));
      }
      await Promise.all(widgetUpdateTasks);
      this.isReportHasNewChanges[reportId].global.timeframe = true;
    }

    if (!isNaN(configuration.isCompareToPreviousEnabled)) {
      this.reportsWithWidgetsData[reportToUpdateIndex].configuration.isCompareToPreviousEnabled = configuration.isCompareToPreviousEnabled;
      const widgetUpdateTasks = [];
      for (const widget of this.reportsWithWidgetsData[reportToUpdateIndex].widgets) {
        widgetUpdateTasks.push(this.updateWidgetConfig({
          configKey: 'isCompareToPreviousEnabled',
          configValue: configuration.isCompareToPreviousEnabled,
          reportId,
          widgetId: widget.id,
        }));
      }
      await Promise.all(widgetUpdateTasks);
      this.isReportHasNewChanges[reportId].global.isCompareToPreviousEnabled = true;
    }

    if (shouldPullDataFromServer) {
      await this.getWidgetsDataForSelectedReport({ reportId });
    }
  }

  async renameWidgetDescriptionRequest({ description, reportId, widgetId }) {
    const body = {
      description,
      id: widgetId,
      reportId,
    };
    try {
      const newReportsWithWidgetsData = [...this.reportsWithWidgetsData];
      const reportsWithWidgetsDataIndex = newReportsWithWidgetsData.findIndex((reportsWithWidgets) => reportsWithWidgets.id === reportId);
      const widgetIndex = newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets.findIndex((widget) => widget.id === widgetId);
      newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets[widgetIndex].description = description;
      this.reportsWithWidgetsData = newReportsWithWidgetsData;
      await serverCommunication.serverRequest('POST', 'reports/renameWidgetDescription', JSON.stringify(body), userStore.userMonthPlan.region);
    } catch (exception) {
      servicesStore.logger.error('failed to rename widget description request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        newDescription: description,
        reportId,
        widgetId,
      });
    }
  }

  async renameWidgetRequest({ title, reportId, widgetId }) {
    const body = {
      title,
      id: widgetId,
      reportId,
    };
    try {
      const newReportsWithWidgetsData = [...this.reportsWithWidgetsData];
      const reportsWithWidgetsDataIndex = newReportsWithWidgetsData.findIndex((reportsWithWidgets) => reportsWithWidgets.id === reportId);
      const widgetIndex = newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets.findIndex((widget) => widget.id === widgetId);
      newReportsWithWidgetsData[reportsWithWidgetsDataIndex].widgets[widgetIndex].title = title;
      this.reportsWithWidgetsData = newReportsWithWidgetsData;
      await serverCommunication.serverRequest('POST', 'reports/renameWidgetTitle', JSON.stringify(body), userStore.userMonthPlan.region);
    } catch (exception) {
      servicesStore.logger.error('failed to rename widget title request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        newWidgetTitle: title,
        reportId,
        widgetId,
      });
    }
  }

  deleteFolderRequest({ folderId }) {
    const body = {
      id: folderId,
    };
    try {
      const folderIndex = this.reportsFolders.findIndex((folder) => folder.id === folderId);
      this.reportsFolders.splice(folderIndex, 1);
      const reportsByFolders = this.reportsWithWidgetsData.filter((report) => report.folderId === folderId);
      for (const report of reportsByFolders) {
        report.folderId = null;
      }
      serverCommunication.serverRequest('DELETE', 'reports/folders', JSON.stringify(body), userStore.userMonthPlan.region);
    } catch (exception) {
      servicesStore.logger.error('failed to delete folder request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        folderId,
      });
    }
  }

  renameFolderRequest({ name, folderId }) {
    const body = {
      name,
      id: folderId,
    };
    try {
      const folderIndex = this.reportsFolders.findIndex((folder) => folder.id === folderId);
      this.reportsFolders[folderIndex].name = name;
      serverCommunication.serverRequest('PUT', 'reports/folders', JSON.stringify(body), userStore.userMonthPlan.region);
    } catch (exception) {
      servicesStore.logger.error('failed to rename folder request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        folderName: name,
        folderId,
      });
    }
  }

  async createNewFolderRequest({ name, reportId }) {
    const profile = servicesStore.authService.getProfileSync();
    const createdBy = profile.email;
    const body = {
      name,
      createdBy,
    };
    try {
      let response = await serverCommunication.serverRequest(
        'POST',
        'reports/folders',
        JSON.stringify(body),
        userStore.userMonthPlan.region
      );
      response = await response.json();

      if (reportId) {
        await this.addReportToFolderRequest({ reportId, folderId: response.id });
      }
      this.reportsFolders.push({
        id: response.id,
        name,
      });
    } catch (exception) {
      servicesStore.logger.error('failed to create new folder request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
        folderName: name,
        reportId,
      });
    }
  }

  getWidgetData({ reportId, widgetId }) {
    const givenReportWithWidget = this.reportsWithWidgetsData.find((reportWithWidgetsData) => reportWithWidgetsData.id === reportId);
    return givenReportWithWidget.widgets.find((widget) => widget.id === widgetId);
  }

  async requestWidgetDataAfterConfigChange({
    reportId, widgetData, shouldPullDataFromServer = true, concatResultsPerWidget, parentRequestId, shouldForceSaveChanges,
  }) {
    const widgetId = widgetData.id;
    const {
      widgetsArrayForServerRequest,
      widgetsConfigForRequest,
      widgetsMetadata,
    } = this.widgetsDataForServerRequest({ widgetsMetadataForRequest: [widgetData], shouldPullDataFromServer });
    this.updateReportWidgets({ widgets: widgetsMetadata, reportId });

    if (!this.isReportHasNewChanges[reportId]) {
      this.isReportHasNewChanges[reportId] = {};
    }
    this.isReportHasNewChanges[reportId][widgetId] = true;

    if (shouldPullDataFromServer) {
      this.isUpdatingWidgetsReportsLoading = true;
      await this.getWidgetsDataServerRequest({
        widgetsArrayForServerRequest, widgetsConfigForRequest, reportId, concatResultsPerWidget, parentRequestId,
      });
      this.isUpdatingWidgetsReportsLoading = false;
    }

    if (shouldForceSaveChanges) {
      this.saveWidgetsConfigurationChanges({ reportId, widgetId });
    }
  }

  async saveWidgetsConfigurationChanges({ reportId, widgetId }) {
    this.isSaveWidgetsReportsLoaded = true;
    const widgetsConfigurations = {};
    const allReportsWidgets = this.reportsWithWidgetsData.find((reportWithWidgetsData) => reportWithWidgetsData.id === reportId).widgets;
    for (const widget of allReportsWidgets) {
      const currentWidgetId = widget.id;
      if (widgetId && widgetId !== currentWidgetId) {
        continue;
      }

      const isWidgetGotChanged = this.isReportHasNewChanges[reportId][currentWidgetId];
      if (!isWidgetGotChanged) {
        continue;
      }

      const timeframeToKeepTheirObject = [TIMEFRAME_VALUES.CUSTOM, TIMEFRAME_VALUES.ROLLING];
      if (!timeframeToKeepTheirObject.includes(widget.configuration[widgetHeaderConfigKeys.timeframe].value)) {
        widget.configuration[widgetHeaderConfigKeys.timeframe] = {
          value: widget.configuration[widgetHeaderConfigKeys.timeframe].value,
        };
      } else {
        const { startDate, endDate, value } = widget.configuration[widgetHeaderConfigKeys.timeframe];
        widget.configuration[widgetHeaderConfigKeys.timeframe] = getTimeframeObjectToSave({
          timeframe: {
            ...widget.configuration[widgetHeaderConfigKeys.timeframe],
            value,
            startDate,
            endDate,
          },
        });
      }
      widgetsConfigurations[currentWidgetId] = widget.configuration;
    }

    const updateReportWidgetsConfigurationBody = {
      reportId,
      widgetsConfigurations,
    };
    const updateTasks = [serverCommunication.serverRequest('POST', 'reports/updateReportWidgetsConfiguration', JSON.stringify(updateReportWidgetsConfigurationBody), userStore.userMonthPlan.region)];

    if (this.isReportHasNewChanges[reportId].global) {
      const reportToUpdateIndex = this.reportsWithWidgetsData.findIndex((report) => report.id === reportId);
      const updateReportGlobalConfigurationBody = {
        id: reportId,
        configuration: {},
      };
      if (this.isReportHasNewChanges[reportId].global.timeframe) {
        updateReportGlobalConfigurationBody.configuration.timeframe = getTimeframeObjectToSave({ timeframe: this.reportsWithWidgetsData[reportToUpdateIndex].configuration?.timeframe });
      }
      if (this.isReportHasNewChanges[reportId].global.filters) {
        updateReportGlobalConfigurationBody.configuration.filters = this.reportsWithWidgetsData[reportToUpdateIndex].configuration?.filters;
      }
      updateTasks.push(serverCommunication.serverRequest('POST', 'reports/configuration', JSON.stringify(updateReportGlobalConfigurationBody), userStore.userMonthPlan.region));
    }

    try {
      await Promise.all(updateTasks);
      delete this.isReportHasNewChanges[reportId];
      this.isSaveWidgetsReportsLoaded = false;
    } catch (exception) {
      servicesStore.logger.error('failed to update report widgets data', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
      });
    }
  }

  async discardWidgetsConfigurationChanges({ reportId }) {
    delete this.isReportHasNewChanges[reportId];
    try {
      let response = await serverCommunication.serverRequest('GET', 'reports/widgetsPerReport');
      response = await response.json();
      const givenReportData = response.widgetsPerReport.allWidgetsPerReport.find((report) => report.id === reportId);
      const reportIndex = this.reportsWithWidgetsData.findIndex((reportWithWidgetsData) => reportWithWidgetsData.id === reportId);
      this.reportsWithWidgetsData[reportIndex] = givenReportData;
      await this.getWidgetsDataForSelectedReport({ reportId });
    } catch (exception) {
      servicesStore.logger.error('failed to discard report widgets configuration changes', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
      });
    }
  }

  async updateWidgetConfig({
    configKey, configValue, reportId, widgetId, shouldPullDataFromServer,
  }) {
    if (configKey === widgetHeaderConfigKeys.filters) {
      await this.updateWidgetFilters({ filters: configValue, reportId, widgetId });
      return;
    }

    if (configKey === widgetHeaderConfigKeys.timeframe) {
      await this.updateWidgetTimeframe({ reportId, widgetId, timeFrame: configValue });
      return;
    }

    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration[configKey] = configValue;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData, shouldPullDataFromServer });
  }

  async updateWidgetFilters({
    reportId, widgetId, filters, isNewFiltersUIOnly,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    const shouldPullDataFromServer = isNewFiltersUIOnly || isFilterChangesRequireServerRequest({ existingFilters: widgetData.configuration.filters, updatedFilters: filters });

    widgetData.configuration[widgetHeaderConfigKeys.filters] = filters;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData, shouldPullDataFromServer });
  }

  async updateWidgetTimeframe({ reportId, widgetId, timeFrame }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    const reportToUpdateIndex = this.reportsWithWidgetsData.findIndex((report) => report.id === reportId);
    if (!this.reportsWithWidgetsData[reportToUpdateIndex].configuration) {
      this.reportsWithWidgetsData[reportToUpdateIndex].configuration = {};
    }
    if (!isEmpty(this.reportsWithWidgetsData[reportToUpdateIndex].configuration.timeframe) && !isEqual(this.reportsWithWidgetsData[reportToUpdateIndex].configuration.timeframe, timeFrame)) {
      this.reportsWithWidgetsData[reportToUpdateIndex].configuration.timeframe = {};
    }

    const isCustomDateTimeframe = timeFrame.value === TIMEFRAME_VALUES.CUSTOM;
    if (isCustomDateTimeframe) {
      timeFrame.orgStartDate = new Date(timeFrame.startDate);
      timeFrame.orgEndDate = new Date(timeFrame.endDate);
      timeFrame.startDate = new Date(timeUtils.getTSForTimezone(timeFrame.startDate));
      timeFrame.endDate = new Date(timeUtils.getTSForTimezone(timeFrame.endDate));
    }

    widgetData.configuration[widgetHeaderConfigKeys.timeframe] = timeFrame;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateReportWidgetColumns({
    reportId, widgetId, inactiveColumns, reorderColumns,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    if (inactiveColumns) {
      widgetData.configuration.inactiveColumns = inactiveColumns;
    }
    if (reorderColumns) {
      widgetData.configuration.reorderColumns = reorderColumns;
    }
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData, shouldPullDataFromServer: false });
  }

  async updateReportWidgetDefaultStageKey({
    reportId, widgetId, stageKey,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.defaultStageKey = stageKey;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData, shouldPullDataFromServer: false });
  }

  async updateWidgetInlineFiltersByColumn({
    sortBy, filtersBy, reportId, widgetId,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    if (sortBy) {
      widgetData.configuration.sortByColumn = sortBy;
    }
    if (filtersBy) {
      widgetData.configuration.filtersByColumn = filtersBy;
    }
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData, shouldPullDataFromServer: false });
  }

  async updateWidgetImpactByCRMIndicator({
    reportId,
    widgetId,
    crmIndicator,
    originalFunnel,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.conversionIndicator = originalFunnel;
    widgetData.configuration.kpiFocus = crmIndicator;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateWidgetImpactByCRMSegment({ reportId, widgetId, firstSegment }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.impactBySegmentParams.firstSegment = firstSegment;
    widgetData.configuration.trendBySegmentsParams = {
      firstSegment,
      secondSegment: null,
    };
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateWidgetImpactByIndicator({ reportId, widgetId, impactIndicator }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.impactIndicator = impactIndicator;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateWidgetImpactByCRMFrequency({
    reportId,
    widgetId,
    segmentFrequency,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.impactBySegmentParams.segmentFrequency = segmentFrequency;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateShouldUseRelativeTimeframe({
    reportId,
    widgetId,
    shouldUseRelativeTimeframe,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.impactBySegmentParams.shouldUseRelativeTimeframe = shouldUseRelativeTimeframe;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateWidgetImpactByIndicatorFrequency({
    reportId,
    widgetId,
    marketingVsSalesFrequency,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.marketingVsSalesFrequency = marketingVsSalesFrequency;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateWidgetHistoricalPerformanceFrequency({ reportId, widgetId, historicalPerformanceFrequency }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.historicalPerformanceFrequency = historicalPerformanceFrequency;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateHistoricalPerformanceDisplayedMetrics({
    reportId,
    widgetId,
    selectedMetrics,
    shouldForceSaveChanges,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.displayedMetrics = selectedMetrics;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData, shouldForceSaveChanges });
  }

  async updateTrendAnalysisFrequency({
    reportId,
    widgetId,
    trendAnalysisFrequency,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.trendAnalysisParams.frequency = trendAnalysisFrequency;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateTrendAnalysisMetrics({
    reportId,
    widgetId,
    trendAnalysisMetrics,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.trendAnalysisParams.metrics = trendAnalysisMetrics;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateTrendAnalysisShouldUseRelativeTimeframe({
    reportId,
    widgetId,
    trendAnalysisShouldUseRelativeTimeframe,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.trendAnalysisParams.shouldUseRelativeTimeframe = trendAnalysisShouldUseRelativeTimeframe;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateSegmentsAnalysisParams({
    reportId,
    widgetId,
    segmentsAnalysisParams,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.segmentsAnalysisParams = segmentsAnalysisParams;
    widgetData.configuration.trendBySegmentsParams = segmentsAnalysisParams;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateAudienceTableSort({ reportId, widgetId, sort }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.sort = sort;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateAudienceTableSearchQuery({ reportId, widgetId, searchQuery }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.searchQuery = searchQuery;
    widgetData.configuration.clientCursor = 0;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  async updateAudienceTableClientCursor({
    reportId, widgetId, clientCursor, parentRequestId,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.clientCursor = clientCursor;
    await this.requestWidgetDataAfterConfigChange({
      reportId,
      widgetData,
      concatResultsPerWidget: {
        [widgetTypes.journeysTable]: true,
      },
      parentRequestId,
    });
  }

  async updateGoalsAnalysisParams({
    reportId, widgetId, goalsAnalysisParams, timeframe,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.goalsAnalysisParams = goalsAnalysisParams;
    widgetData.configuration[widgetHeaderConfigKeys.timeframe] = timeframe;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }

  updateSearchQuery({ searchQuery }) {
    this.searchQuery = searchQuery;
  }

  async updateWhatIfParams({
    reportId, widgetId, whatIfParams,
  }) {
    const widgetData = this.getWidgetData({ reportId, widgetId });
    widgetData.configuration.whatIfParams = whatIfParams;
    await this.requestWidgetDataAfterConfigChange({ reportId, widgetData });
  }
}

decorate(ReportsStore, {
  searchQuery: observable,
  updateSearchQuery: action.bound,
  selectedReport: computed,
  selectedReportId: observable,
  isReportHasNewChanges: observable,
  isReportsRequestLoaded: observable,
  isSaveWidgetsReportsLoaded: observable,
  reportsWithWidgetsData: observable,
  reportsFolders: observable,
  isUpdatingWidgetsReportsLoading: observable,
  setSelectedReport: action.bound,
  reorderReportWidgets: action.bound,
  createNewReportRequest: action.bound,
  createNewFolderRequest: action.bound,
  renameFolderRequest: action.bound,
  deleteFolderRequest: action.bound,
  addReportToFolderRequest: action.bound,
  addWidgetToReportRequest: action.bound,
  getReportsWithWidgetsMetadataRequest: action.bound,
  deleteReportRequest: action.bound,
  renameReportRequest: action.bound,
  deleteWidgetRequest: action.bound,
  renameWidgetRequest: action.bound,
  getWidgetsDataForSelectedReport: action.bound,
  isHavingReportWithWidgets: action.bound,
  widgetsOfSelectedReport: computed,
  reportsList: computed,
  updateWidgetConfig: action.bound,
  updateWidgetFilters: action.bound,
  updateWidgetTimeframe: action.bound,
  updateReportWidgetColumns: action.bound,
  updateReportWidgetDefaultStageKey: action.bound,
  updateWidgetImpactByCRMSegment: action.bound,
  updateWidgetImpactByCRMIndicator: action.bound,
  updateWidgetImpactByIndicatorFrequency: action.bound,
  updateWidgetImpactByCRMFrequency: action.bound,
  updateWidgetImpactByIndicator: action.bound,
  discardWidgetsConfigurationChanges: action.bound,
  saveWidgetsConfigurationChanges: action.bound,
  updateWidgetHistoricalPerformanceFrequency: action.bound,
  updateHistoricalPerformanceDisplayedMetrics: action.bound,
  updateTrendAnalysisFrequency: action.bound,
  updateTrendAnalysisShouldUseRelativeTimeframe: action.bound,
  updateTrendAnalysisMetrics: action.bound,
  updateSegmentsAnalysisParams: action.bound,
  updateAudienceTableSort: action.bound,
  updateAudienceTableSearchQuery: action.bound,
  updateAudienceTableClientCursor: action.bound,
  updateGoalsAnalysisParams: action.bound,
  updateReportFolder: action.bound,
  updateReportConfiguration: action.bound,
  renameWidgetDescriptionRequest: action.bound,
  updateWidgetInlineFiltersByColumn: action.bound,
  updateShouldUseRelativeTimeframe: action.bound,
  updateWhatIfParams: action.bound,
});

export default new ReportsStore();
