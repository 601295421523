export function isActiveInChildRoutes({ childRoutes, activeTier }) {
  if (!childRoutes) { return false; }

  return childRoutes.some((route) => {
    if (route.tabName === activeTier) {
      return true;
    }
    if (route.childRoutes) {
      return isActiveInChildRoutes({ childRoutes: route, activeTier });
    }
    return false;
  });
}

function findNavigationTiers({ routes, targetPath, currentMainTier = null }) {
  for (const route of routes) {
    const activeMainTier = route.id ? route : currentMainTier;

    if (route.path === targetPath) {
      return {
        mainTier: activeMainTier?.id,
        subTier: route?.tabName,
      };
    }

    if (route.childRoutes?.length) {
      const childResult = findNavigationTiers({
        routes: route.childRoutes,
        targetPath,
        currentMainTier: activeMainTier,
      });
      if (childResult.mainTier || childResult.subTier) {
        return childResult;
      }
    }
  }
  return {
    mainTier: null,
    subTier: null,
  };
}

export const getCurrentTierByPath = ({ globalRoutes, path }) => {
  const normalizedTargetPath = path?.replace(/\/$/, '');

  return findNavigationTiers({
    routes: globalRoutes,
    targetPath: normalizedTargetPath,
  });
};
