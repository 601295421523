import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import history from 'history';

import useStyles from 'hooks/useStyles';

import ButtonWithOptions from 'components/common/ButtonWithOptions';
import ConfirmPopup from 'components/ConfirmPopup';
import FolderActionsPopups from 'components/pages/reports/FolderActionsPopups';
import CreateNewReportPopup from 'components/pages/reports/CreateNewReportPopup';

import { folderActionsKeys } from 'components/pages/reports/enums';
import { getPopupTextContent, toastSuccessMessage } from 'components/pages/reports/logic/reportActionMenu';

import style from 'styles/reports/reports.css';

const styles = style.locals || {};

function ReportActionMenu({
  foldersList,
  selectedReport,
  deleteReportRequest,
  hasReportId,
}) {
  useStyles([style]);

  const [folderActionsPopupType, setFolderActionsPopupType] = useState(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState({ isShow: false, message: '' });
  const [isShowCreateNewReportPopup, setIsShowCreateNewReportPopup] = useState({ isShow: false, isRename: false, isDuplicate: false });

  const reportId = selectedReport.value;

  function onDuplicateReport() {
    setIsShowCreateNewReportPopup({ isShow: true, isRename: false, isDuplicate: true });
  }

  function onRenameReport() {
    setIsShowCreateNewReportPopup({ isShow: true, isRename: true });
  }

  function closeCreateNewReportPopup() {
    setIsShowCreateNewReportPopup({ isShow: false, isRename: false });
  }

  function onDeleteReport() {
    const popupMessage = (
      <>
        {'Are you sure you want to delete '}
        <b>{selectedReport?.label}</b>
        {' report?'}
      </>
    );
    setShowConfirmPopup({ isShow: true, message: popupMessage });
  }

  const confirmDeletePopupCallBack = (abortAction) => {
    if (!abortAction) {
      deleteReportRequest({ reportId });
      history.push('/reports');
    }
    setShowConfirmPopup({ isShow: false, message: '' });
  };

  const actionsOptionsMenu = [
    { label: 'Edit report name', action: () => onRenameReport() },
    { label: 'Duplicate report', action: () => onDuplicateReport() },
    { label: 'Move to folder', action: () => setFolderActionsPopupType(folderActionsKeys.moveToFolder) },
    { label: 'Delete report', action: () => onDeleteReport() },
  ];

  const selectedFolder = foldersList.find((folder) => folder.value === selectedReport.folderId);

  const {
    popupTitle, subTitle, actionText, secondaryText,
  } = getPopupTextContent({ popupType: isShowCreateNewReportPopup });

  return (
    <>
      <ButtonWithOptions
        options={actionsOptionsMenu}
        renderMenuButton={() => (
          <div className={styles.reportActionButton}> </div>
        )}
        dataTestId="report-action-menu"
      />

      <ConfirmPopup
        hidden={!showConfirmPopup.isShow}
        callback={confirmDeletePopupCallBack}
        confirmBtn="Delete"
        title={showConfirmPopup.message}
      />

      {folderActionsPopupType && (
        <FolderActionsPopups
          updateActionType={(action) => setFolderActionsPopupType(action)}
          type={folderActionsPopupType}
          toastSuccessMessage={toastSuccessMessage}
          selectedFolder={selectedFolder}
          selectedReport={selectedReport}
          isAttachCurrentReportToFolder={false}
        />
      )}

      {isShowCreateNewReportPopup.isShow && (
      <CreateNewReportPopup
        title={popupTitle}
        subTitle={subTitle}
        actionText={actionText}
        secondaryText={secondaryText}
        onClosePopup={() => closeCreateNewReportPopup()}
        isRenameMode={isShowCreateNewReportPopup.isRename}
        isDuplicateMode={isShowCreateNewReportPopup.isDuplicate}
        toastSuccessMessage={toastSuccessMessage}
        folderId={hasReportId ? selectedFolder?.value : null}
        selectedReport={selectedReport}
      />
      )}
    </>
  );
}

export default inject(
  ({
    reportsStore: {
      selectedReport,
      deleteReportRequest,
      foldersList,
    },
  }) => ({
    selectedReport,
    deleteReportRequest,
    foldersList,
  }),
  observer
)(ReportActionMenu);
