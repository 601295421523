import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import CustomFiltersPanel from 'components/pages/users/Filters/CustomFiltersPanel';

import { TIMEFRAME_VALUES } from 'components/utils/timeframe';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function AccountEngagementTrigger({
  triggerRules, updateTriggerRules,
}) {
  useStyles([style]);

  function updateTriggerRuleData({ filters }) {
    const filtersForServerRequest = filters.map((filter) => {
      const filterForServerRequest = {
        id: filter.id,
        label: filter.label,
        isUiOnly: filter.isUiOnly,
        config: {
          kind: filter.config.kind,
          isSearchable: filter.config.isSearchable,
        },
        data: filter.data,
      };

      if (filter.data.timeFrame) {
        const timeframeToKeepTheirObject = [TIMEFRAME_VALUES.CUSTOM, TIMEFRAME_VALUES.ROLLING];
        if (!timeframeToKeepTheirObject.includes(filter.data.timeFrame.value)) {
          filterForServerRequest.data.timeFrame = {
            value: filter.data.timeFrame.value,
          };
        }
      }

      return filterForServerRequest;
    });

    const updatedTriggerRules = [{ filters: filtersForServerRequest }];
    updateTriggerRules(updatedTriggerRules);
  }

  const accountEngagementFilters = triggerRules[0]?.filters || [];

  return (
    <div className={styles.ruleWrapper}>
      <div className={classnames(styles.row, styles.accountEngagementFilters)}>
        <div className={classnames(styles.text, styles.textNowrap, styles.textMinHeight)}>
          If an account has a new interaction
        </div>
        <CustomFiltersPanel
          filters={accountEngagementFilters}
          setFilters={(newFilters) => updateTriggerRuleData({ filters: newFilters })}
          buttonText="Add filters"
          addFilterButtonClassName={styles.addFilterButton}
          filtersPopupStyle={styles.filtersPopup}
        />
      </div>
    </div>

  );
}

export default AccountEngagementTrigger;
