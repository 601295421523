import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import useStyles from 'hooks/useStyles';

import PopupWithDrawerSteps from 'components/common/PopupWithDrawerSteps';
import SignalSourceSection from 'components/widgets/signals/createSignalPopup/SignalSourceSection';
import SignalActivitySection from 'components/widgets/signals/createSignalPopup/SignalActivitySection';
import SignalTimeframeSection from 'components/widgets/signals/createSignalPopup/SignalTimeframeSection';
import { nameAlreadyExistsErrorMessage } from 'components/enums';
import { onAddedSignalToastMessage } from 'components/widgets/signals/enums';
import { getSignalAnalyticsMockDataForNewSignal } from 'components/widgets/signals/logic/signals';
import { getUniqueTitle } from 'components/utils/logic/utils';

import style from 'styles/salesEnablement/createSignalPopup.css';

const styles = style.locals || {};

export default function CreateSignalPopup({
  onClose,
  stepIndexToOpen,
  isDisabled,
  signalData = {},
  signals,
  setSignals,
  setSignalAnalyticsData,
}) {
  useStyles([style]);

  const [allSignalsNames, setAllSignalsNames] = useState(signals?.map((signal) => signal.name));
  const [newSignal, setNewSignal] = useState({
    id: uuidv4(),
    name: signalData?.name || getUniqueTitle({ baseTitle: 'Signal', listOfNames: allSignalsNames }),
    error: '',
  });

  useEffect(() => {
    setAllSignalsNames(signals.map((signal) => signal.name));
    let activities = newSignal.rules || signalData?.rules || [];
    activities = activities.length ? activities : [{ key: '', occurrence: 0 }];
    setNewSignal({
      id: newSignal.id || signalData.id,
      name: newSignal.name || signalData?.name || getUniqueTitle({ baseTitle: 'Signal', listOfNames: allSignalsNames }),
      error: newSignal.error || '',
      source: newSignal.source || signalData?.source || '',
      rules: activities,
      active: newSignal.active || signalData?.active || true,
    });
  }, [signals]);

  function changeSignalName({ newName }) {
    const isNameAlreadyExists = allSignalsNames.some((name) => name.toLowerCase() === newName.toLowerCase());
    if (isNameAlreadyExists) {
      setNewSignal({
        ...newSignal,
        name: newName,
        error: nameAlreadyExistsErrorMessage,
      });
      return;
    }

    setNewSignal({
      ...newSignal,
      name: newName,
      error: '',
    });
  }

  function onSaveSignal() {
    setSignals([newSignal, ...signals]);
    const newSignalAnalyticsData = getSignalAnalyticsMockDataForNewSignal({ newSignal });
    setSignalAnalyticsData((prevState) => ([newSignalAnalyticsData, ...prevState]));
    toast.success(onAddedSignalToastMessage, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      toastId: onAddedSignalToastMessage,
      style: { width: '320px', fontSize: '14px' },
    });
    onClose();
  }

  const steps = [{
    title: '1. Source',
    isValid: newSignal.source !== '',
    isShowContinueButton: true,
    content: (
      <SignalSourceSection
        signalSource={newSignal.source}
        setSignalSource={(source) => setNewSignal({
          ...newSignal,
          source,
        })}
      />
    ),
  }, {
    title: '2. Activity',
    isValid: newSignal.rules?.every((activity) => activity.key !== '' && activity.value !== '' && activity.occurrence !== 0 && activity.operator !== ''),
    isShowContinueButton: true,
    content: (
      <SignalActivitySection
        newActivities={newSignal.rules}
        setNewActivities={(activities) => setNewSignal({
          ...newSignal,
          rules: activities,
        })}
      />
    ),
  }, {
    title: '3. Timeframe',
    isValid: newSignal.timeframe,
    isShowContinueButton: false,
    content: (
      <SignalTimeframeSection
        newTimeframe={newSignal.timeframe}
        setNewTimeframe={(timeframe) => setNewSignal({
          ...newSignal,
          timeframe,
        })}
      />
    ),
  }];

  return (
    <PopupWithDrawerSteps
      onClose={onClose}
      title={{ name: newSignal?.name, error: newSignal?.error }}
      updateTitle={({ newTitle }) => changeSignalName({ newName: newTitle })}
      isTitleEditable
      onClickSave={() => onSaveSignal()}
      steps={steps}
      className={styles.pageContainer}
      stepIndexToOpen={stepIndexToOpen}
      disabled={isDisabled}
    />
  );
}
