import userStore from 'stores/userStore';
import { isContentSegment } from 'components/pages/analyze/OverviewTab/logic';

export function getDefaultStageSelectorData({ firstSegment }) {
  const defaultStagesData = [{
    key: 'crossFunnel',
    name: 'Cross Funnel',
    number: '',
    prevNumber: '',
    stageGrowth: '',
    priority: 0,
  }];

  const isShowDefaultPageViews = isContentSegment({ segment: firstSegment });

  if (isShowDefaultPageViews) {
    defaultStagesData.push({
      key: 'pageViews',
      name: 'Page Views',
      number: null,
      prevNumber: null,
      stageGrowth: null,
      intentStatus: 1,
    });
  } else {
    defaultStagesData.push({
      key: 'webVisits',
      name: 'Visits',
      number: null,
      prevNumber: null,
      stageGrowth: null,
      intentStatus: 1,
    });
  }

  for (const stage of userStore.userFunnels) {
    defaultStagesData.push({
      key: stage,
      name: userStore.getMetricNickname({ metric: stage }),
      number: null,
      prevNumber: null,
      stageGrowth: null,
      intentStatus: 2,
    });
  }

  return defaultStagesData;
}
