import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import { get, isEmpty } from 'lodash';

import Component from 'components/Component';
import GeneralPopup from 'components/common/GeneralPopup';
import Switch from 'components/controls/Switch';
import Dropdown from 'components/controls/Dropdown';
import InfoMarker from 'components/pages/InfoMarker';

import { getAttributionModelsWithFlags } from 'attribution/models';
import { compose } from 'components/utils/utils';
import { onboardingTagsIds } from 'components/pages/analyze/enums';

import style from 'styles/analyze/settings-popup.css';

const enhance = compose(
  inject(({
    attributionStore: {
      attributionModel,
      setAttributionModel,
      setAutoFilter,
      autoFilter,
      updateOtherCampaignsHidden,
      isAccountMode,
      setIsAccountMode,
      isOtherCampaignsHidden,
      isCalculateAdvancedMetrics,
      setIsCalculateAdvancedMetrics,
    },
    userStore: {
      userMonthPlan,
    },
  }) => ({
    attributionModel,
    setAttributionModel,
    setAutoFilter,
    autoFilter,
    updateOtherCampaignsHidden,
    isAccountMode,
    setIsAccountMode,
    isOtherCampaignsHidden,
    userMonthPlan,
    isCalculateAdvancedMetrics,
    setIsCalculateAdvancedMetrics,
  })),
  observer
);

class SettingsPopup extends Component {
  style = style;

  tooltipAttributionModel = `
    <div style="border-radius: 5px; display: flex; flex-direction: column; align-items: center;">
      <div>Choose a model to define how to divide the credit between</div>
      <div>the different touchpoints/interactions occurred </div>
      <div>before the desired conversion.</div>
    </div>
   `;

  tooltipAttributionCredit = `
    <div style="border-radius: 5px; display: flex; flex-direction: column; align-items: center;">
      <div>Per conversion, choose "Time Frame" if you want to credit only the assisted touchpoints</div>
      <div>in the selected time frame or "All" if you want to credit all touchpoints,</div>
      <div>even if occurred before the selected time frame.</div>
    </div>
  `;

  tooltipPredefinedFunnelFilters = 'Apply your pre-defined filters to filter all journeys in one click';

  tooltipHideOtherCampaigns = 'Focus your campaigns list by removing <br>"{channel name} - other" campaigns. <br><b>Note:</b> this won\'t affect your cost or performance numbers.';

  tooltipAccountView = 'Switch to an account-based funnel that groups your funnel stages by companies.';

  tooltipCalculateAdvancedMetrics = 'Toggling will add Trend and Vs. Average calculations to your widgets. These calculations can impact performance.';

  openPanelPopup = () => this.popup.open();

  get isHavePreDefinedFilters() {
    const predefinedFiltersConfig = get(this.props.userMonthPlan?.CRMConfig, 'predefinedFiltersConfig', {});
    return !isEmpty(predefinedFiltersConfig);
  }

  render() {
    const {
      setAttributionModel,
      isOtherCampaignsHidden,
      attributionModel,
      setAutoFilter,
      autoFilter,
      updateOtherCampaignsHidden,
      isAccountMode,
      setIsAccountMode,
      isCalculateAdvancedMetrics,
      setIsCalculateAdvancedMetrics,

      isShowAttributionModel = true,
      isShowPredefinedFilters = true,
      isShowHideOtherCampaigns = true,
      isShowAccountView = true,
      isShowCalculateAdvancedMetrics = true,
    } = this.props;

    const attributionModelsItems = getAttributionModelsWithFlags(this.props.flags);

    return (
      <div className={this.classes.settingsContainer}>
        <div
          className={this.classes.dotIcon}
          data-icon="headerBar:menu"
          onClick={() => this.openPanelPopup()}
          id={onboardingTagsIds.headerSettingsPopup}
        />
        <GeneralPopup
          ref={(el) => {
            this.popup = el;
          }}
          popupClass={this.classes.settingsPopup}
        >
          {isShowAttributionModel && (
            <div className={this.classes.settingsItem}>
              Attribution Model
              <InfoMarker
                containerClass={this.classes.tooltipIcon}
                tooltipText={this.tooltipAttributionModel}
              />
              <Dropdown
                selectedKey={attributionModel.value}
                options={attributionModelsItems}
                onChange={setAttributionModel}
                controlWidth={180}
                menuListMaxHeight="auto"
                menuMaxWidth="300px"
                openMenuFromRightCorner
                bottomMenuRenderer={(
                  <a
                    href="https://help.infinigrow.com/en/articles/5422741-which-attribution-models-does-infinigrow-support-and-how-are-they-different"
                    className={this.classes.attributionModelBottomLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more about attribution models
                  </a>
                )}
                optionRenderer={(item) => (
                  <div className={this.classes.optionRenderer}>
                    <div className={this.classes.optionLabel}>{item.label}</div>
                    <div className={this.classes.optionDescription}>
                      {item.data?.description}
                    </div>
                  </div>
                )}
              />
            </div>
          )}

          {isShowPredefinedFilters && this.isHavePreDefinedFilters && (
            <div className={this.classes.settingsItem}>
              Use pre-defined funnel filters
              <InfoMarker
                containerClass={this.classes.tooltipIcon}
                tooltipText={this.tooltipPredefinedFunnelFilters}
              />
              <Switch
                onSwitchClick={() => setAutoFilter()}
                isActive={autoFilter}
              />
            </div>
          )}

          {isShowHideOtherCampaigns && (
            <div className={this.classes.settingsItem}>
              {'Hide "other" campaigns '}
              <InfoMarker
                containerClass={this.classes.tooltipIcon}
                tooltipText={this.tooltipHideOtherCampaigns}
              />
              <Switch
                onSwitchClick={updateOtherCampaignsHidden}
                isActive={isOtherCampaignsHidden}
              />
            </div>
          )}

          {isShowCalculateAdvancedMetrics && (
          <div className={this.classes.settingsItem}>
            {' Calculate advanced metrics '}
            <InfoMarker
              containerClass={this.classes.tooltipIcon}
              tooltipText={this.tooltipCalculateAdvancedMetrics}
            />
            <Switch
              onSwitchClick={() => setIsCalculateAdvancedMetrics({ isCalculateAdvancedMetrics: !isCalculateAdvancedMetrics })}
              isActive={isCalculateAdvancedMetrics}
            />
          </div>
          )}

          {isShowAccountView && this.props.userMonthPlan?.accountViewRegion && (
            <div className={this.classes.settingsItem}>
              Account view
              <InfoMarker
                containerClass={this.classes.tooltipIcon}
                tooltipText={this.tooltipAccountView}
              />
              <Switch onSwitchClick={setIsAccountMode} isActive={isAccountMode} />
            </div>
          )}
        </GeneralPopup>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(SettingsPopup));
