import ReactDOMServer from 'react-dom/server';

import userStore from 'stores/userStore';

import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { EQUALS } from 'components/utils/filters/logic';
import { getChannelsWithNicknames } from 'components/utils/channels';
import { getChannelNickname } from 'components/utils/filters/channels';

export const mySignalsTitle = 'My Signals';
export const mySignalsSubTitle = 'Set activity patterns as intent signals, to help you prioritize your targeted accounts.';
export const confirmPopupTitle = 'Are you sure you want to delete this signal?';
export const confirmPopupDescription = "Deleting this signal will remove it from 'My signals', and from 'Signals analytics'. This action cannot be undone.";
export const onConfirmSignalDeleteToastMessage = 'Signal deleted successfully!';
export const accountPrioritizationTitle = 'Account prioritization';
export const accountPrioritizationSubTitle = 'Easily create account lists for Sales to work, and prioritize accounts that are worth their time.';
export const onAddedSignalToastMessage = 'New signal added!';
export const signalAnalyticsTitle = 'Signal Analytics';
export const signalAnalyticsSubtitle = 'Validate your signals\' impact on your desired KPI - separate true intent from noise.';

export const sourcesKeys = {
  g2: 'g2',
  sixSense: 'sixSense',
  infinigrowAI: 'infinigrowAI',
  infinigrow: 'infinigrow',
};

export const optionsForSignalSource = [
  sourcesKeys.g2,
  sourcesKeys.sixSense,
  sourcesKeys.infinigrow,
];

export const sourcesLabels = {
  [sourcesKeys.g2]: 'G2',
  [sourcesKeys.sixSense]: '6sense',
  [sourcesKeys.infinigrowAI]: 'InfiniGrow AI',
  [sourcesKeys.infinigrow]: 'InfiniGrow',
};

export const defaultSignalAnalyticsTimeframe = {
  value: TIMEFRAME_VALUES.ROLLING,
  type: 'day',
  rollingValue: 'last_30_day_day',
  excludeToday: true,
};

export const defaultSignalAnalyticsKpiFocus = userStore.userFunnels[userStore.userFunnels.length - 1];

export const defaultAccountPrioritizationTimeframe = {
  value: TIMEFRAME_VALUES.ROLLING,
  type: 'day',
  rollingValue: 'last_30_day_day',
  excludeToday: true,
};

export const signalsPriorities = {
  hot: 'hot',
  cold: 'cold',
  slipping: 'slipping',
  new: 'new',
};

export const signalsPrioritiesLabels = {
  [signalsPriorities.hot]: 'Hot!',
  [signalsPriorities.cold]: 'Cold',
  [signalsPriorities.slipping]: 'Slipping',
  [signalsPriorities.new]: 'New',
};

export const signalsColumnsKeys = {
  signal: 'signal',
  source: 'source',
  timeTriggered: 'timeTriggered',
  closedWonsInfluenced: 'closedWonsInfluenced',
  uplift: 'uplift',
  lastTriggered: 'lastTriggered',
};

export const signalsColumnsLabels = {
  [signalsColumnsKeys.signal]: 'Signal',
  [signalsColumnsKeys.source]: 'Source',
  [signalsColumnsKeys.timeTriggered]: 'Time Triggered',
  [signalsColumnsKeys.closedWonsInfluenced]: 'Closed Wons Influenced',
  [signalsColumnsKeys.uplift]: 'Uplift',
  [signalsColumnsKeys.lastTriggered]: 'Last Triggered',
};

export const mySignalsColumnsKeys = {
  name: 'name',
  rules: 'rules',
  timeframe: 'timeframe',
  source: 'source',
  active: 'active',
};

export const mySignalsColumnsLabels = {
  [mySignalsColumnsKeys.name]: 'Name',
  [mySignalsColumnsKeys.rules]: 'Rules',
  [mySignalsColumnsKeys.timeframe]: 'Timeframe',
  [mySignalsColumnsKeys.source]: 'Source',
  [mySignalsColumnsKeys.active]: 'Status',
};

export const signalsActivityKeys = {
  channel: 'channel',
  campaign: 'campaign',
  content: 'content',
  session: 'session',
};

export const signalsActivityTypesLabels = {
  [signalsActivityKeys.channel]: 'Channel',
  [signalsActivityKeys.campaign]: 'Campaign',
  [signalsActivityKeys.content]: 'Content',
  [signalsActivityKeys.session]: 'Online session',
};

export const accountPrioritizationColumnsKeys = {
  account: 'account',
  intentStatus: 'intentStatus',
  activeContacts: 'activeContacts',
  recentSignal: 'recentSignal',
  touchpoints: 'touchpoints',
  openPipeline: 'openPipeline',
  opportunityStage: 'opportunityStage',
  signals: 'signals',
};

export const accountPrioritizationActiveColumns = [
  accountPrioritizationColumnsKeys.account,
  accountPrioritizationColumnsKeys.intentStatus,
  accountPrioritizationColumnsKeys.activeContacts,
  accountPrioritizationColumnsKeys.recentSignal,
  accountPrioritizationColumnsKeys.openPipeline,
  accountPrioritizationColumnsKeys.opportunityStage,
  '1',
];

export const accountPrioritizationColumnsLabels = {
  [accountPrioritizationColumnsKeys.account]: 'Account',
  [accountPrioritizationColumnsKeys.intentStatus]: 'Intent Status',
  [accountPrioritizationColumnsKeys.activeContacts]: 'Active contacts',
  [accountPrioritizationColumnsKeys.recentSignal]: 'Recent Signal',
  [accountPrioritizationColumnsKeys.touchpoints]: 'Touchpoints',
  [accountPrioritizationColumnsKeys.openPipeline]: 'Open Pipeline',
  [accountPrioritizationColumnsKeys.opportunityStage]: 'Opportunity Stage',
};

export const accountPrioritizationColumnGroupLabels = {
  accountStatus: 'Account Status',
  signals: 'Signals',
};

export const signalDateFormat = 'DD/MM/YYYY';

export function geyPriorityTooltip() {
  return ReactDOMServer.renderToStaticMarkup(
    <div style={{
      display: 'flex', flexDirection: 'column', textAlign: 'left', gap: '12px', margin: '2px 0',
    }}
    >
      Intent status is based on a categorization algorithm that takes into account a signal&apos;s frequency, strength, and timing.
      <ul style={{ padding: 0, margin: '0 0 0 12px' }}>
        <li>
          <b>New:</b>
          {' '}
          Account triggered a signal after not triggering any signals previously.
        </li>
        <li>
          <b>Hot:</b>
          {' '}
          Account triggered high-uplift signals, frequently, over the selected timeframe.
        </li>
        <li>
          <b>Slipping:</b>
          {' '}
          Account triggered signals, but not recently.
        </li>
        <li>
          <b>Cold:</b>
          {' '}
          Account did not trigger signals during the selected timeframe.
        </li>
      </ul>
    </div>
  );
}

export const unclickableItemsKeys = {
  newSignalCreationSource6sense: 'newSignalCreationSource6sense',
  newSignalCreationSourceG2: 'newSignalCreationSourceG2',
  newSignalCreationSourceInfiniGrowAI: 'newSignalCreationSourceInfiniGrowAI',
  newSignalCreationAddActivity: 'newSignalCreationAddActivity',
  newSignalCreationActivityOriginChannel: 'newSignalCreationActivityOriginChannel',
  newSignalCreationActivityOriginCampaign: 'newSignalCreationActivityOriginCampaign',
  newSignalCreationActivityOriginSession: 'newSignalCreationActivityOriginSession',
  newSignalCreationOperatorEqualsOption: 'newSignalCreationOperatorEqualsOption',
  kpiFocusLeads: 'kpiFocusLeads',
  kpiFocusMqls: 'kpiFocusMqls',
  kpiFocusOpps: 'kpiFocusOpps',
  kpiFocusRevenue: 'kpiFocusRevenue',
  kpiFocusPipeline: 'kpiFocusPipeline',
};

export const unclickableSourceToOptionValue = {
  [unclickableItemsKeys.newSignalCreationSource6sense]: sourcesKeys.sixSense,
  [unclickableItemsKeys.newSignalCreationSourceG2]: sourcesKeys.g2,
};

export const unclickableActivityOriginToOptionValue = {
  [unclickableItemsKeys.newSignalCreationActivityOriginChannel]: signalsActivityKeys.channel,
  [unclickableItemsKeys.newSignalCreationActivityOriginCampaign]: signalsActivityKeys.campaign,
  [unclickableItemsKeys.newSignalCreationActivityOriginSession]: signalsActivityKeys.session,
};

export const unclickableKpiFocusToOptionValue = {
  [unclickableItemsKeys.kpiFocusLeads]: 'funnel1',
  [unclickableItemsKeys.kpiFocusMqls]: 'funnel2',
  [unclickableItemsKeys.kpiFocusOpps]: 'funnel3',
  [unclickableItemsKeys.kpiFocusRevenue]: 'revenue',
  [unclickableItemsKeys.kpiFocusPipeline]: 'pipeline',
};

export const signalActivityOriginWithEqualsToDropdown = [
  signalsActivityKeys.channel,
  signalsActivityKeys.campaign,
  signalsActivityKeys.content,
];

export const signalActivityComparatorWithDropdown = [
  EQUALS,
];

export const unclickableSignalOperatorOptionValue = {
  [unclickableItemsKeys.newSignalCreationOperatorEqualsOption]: EQUALS,
};

export function getOptionsByKey({ key }) {
  if (key === signalsActivityKeys.channel) {
    const channelsWithNicknames = getChannelsWithNicknames();
    return channelsWithNicknames.map((item) => ({ value: item.value, label: getChannelNickname(item.value) }));
  }
  return [];
}
