export function parsedReportsData({ data = [] }) {
  const reports = [];
  for (const report of data) {
    reports.push({
      name: report.name,
      linkTo: `/reports?reportId=${report.id}`,
    });
  }
  return reports;
}

export function parsedQuestionsData({ data = [] }) {
  const questions = [];
  for (const question of data) {
    const questionWithoutExtraSpaces = question.replace(/  +/g, ' ').replace(/ ,/g, ',');
    questions.push({
      name: questionWithoutExtraSpaces,
      linkTo: `/questions?questionText=${question}`,
    });
  }
  return questions;
}
