import React from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';

import Component from 'components/Component';
import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import FunnelView from 'components/pages/analyze/OverviewTab/FunnelView';
import GoalsAnalysis from 'components/pages/insights/overviewTab/goals/GoalsAnalysis';
import HistoricalPerformance from 'components/pages/analyze/OverviewTab/HistoricalPerformance';
import ImpactByIndicator from 'components/pages/analyze/OverviewTab/ImpactByIndicator';
import StatsSquares from 'components/pages/analyze/OverviewTab/StatsSquares';
import TrendAnalysis from 'components/pages/analyze/OverviewTab/TrendAnalysis';
import attributionStore from 'stores/attributionStore';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { FUNNEL3 } from 'components/utils/indicators';
import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import { compose } from 'components/utils/utils';
import { getGoalTimeframe } from 'components/utils/goals';
import {
  getWidgetsData, getWidgetsDataFromStore, getWidgetsDataV2, getWidgetFullConfig, getWidgetsDataFromStoreV2,
} from 'components/pages/analyze/widgetsRequest';
import { isFiltersChanged, isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { navigateToJourneys } from 'stores/analyze/logic/journeysStore';
import { pickerTimeframeToFrequencyType, activityStatusValues } from 'components/pages/settings/goals/logic/enums';
import { trendAnalysisInitialMetrics } from 'components/pages/analyze/OverviewTab/logic/enums';
import { widgetTypes, flagsNames } from 'components/pages/analyze/enums';
import { getTimeframeOptionsByActivityStatus } from 'components/pages/settings/goals/logic/PropertiesTab';
import { parseHistoricalPerformanceDataToStatsSquares, getSelectedMetricsForStatsSquare } from 'components/pages/analyze/OverviewTab/logic/statsSquares';
import { pageLabels } from 'enums';

import style from 'styles/analyze/analyze.css';

const enhance = compose(
  inject(({
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.marketingVsSales]: marketingVsSales,
      },
      configPerWidget: {
        [widgetTypes.marketingVsSales]: marketingVsSalesConfig,
      },
      restIsLoadedWidgetIndication,
    },
    widgetsAnalysisStore: {
      dataPerWidget: {
        [widgetTypes.trendAnalysis]: trendAnalysis,
        [widgetTypes.historicalPerformance]: historicalPerformance,
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
      },
      configPerWidget: {
        [widgetTypes.trendAnalysis]: trendAnalysisConfig,
        [widgetTypes.historicalPerformance]: historicalPerformanceConfig,
      },
      getWidgetRequestId,
      resetWidgetData,
    },
    userStore: {
      userMonthPlan: {
        region,
        fiscalYearFirstMonth,
      },
      funnels,
    },
    attributionStore: {
      autoFilter,
      previousTimeframe: isCompareToPreviousEnabled,
      metricsOptions,
      attributionModel,
      filters,
      timeFrame,
      isAccountMode,
    },
  }) => ({
    isCompareToPreviousEnabled,
    metricsOptions,
    historicalPerformance,
    historicalPerformanceConfig,
    attributionModel,
    timeFrame,
    filters,
    marketingVsSales,
    marketingVsSalesConfig,
    trendAnalysis,
    trendAnalysisConfig,
    autoFilter,
    region,
    funnels,
    goalsAnalysis,
    restIsLoadedWidgetIndication,
    fiscalYearFirstMonth,
    isAccountMode,
    getWidgetRequestId,
    resetWidgetData,
  })),
  observer
);

class Overview extends Component {
  style = style;

  constructor(props) {
    super(props);
    this.currentTabName = 'overview';

    this.state = {
      marketingVsSalesIndicator: props.marketingVsSalesConfig?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.[0].impactIndicator || FUNNEL3,
      marketingVsSalesFrequency: props.marketingVsSalesConfig?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.[0].marketingVsSalesFrequency || FREQUENCY_VALUES.MONTH,
      trendAnalysisMetrics: props.trendAnalysisConfig?.[this.currentTabName]?.metrics || trendAnalysisInitialMetrics,
      trendAnalysisFrequency: props.trendAnalysisConfig?.[this.currentTabName]?.frequency || FREQUENCY_VALUES.MONTH,
      trendAnalysisShouldUseRelativeTimeframe: props.trendAnalysisConfig?.[this.currentTabName]?.shouldUseRelativeTimeframe || false,
      historicalPerformanceFrequency: props.historicalPerformanceConfig?.[this.currentTabName]?.historicalPerformanceFrequency || FREQUENCY_VALUES.MONTH,
      historicalPerformanceSelectedMetrics: [],
      isInitialLoad: null,
    };
  }

  componentDidMount() {
    const {
      isCompareToPreviousEnabled,
      marketingVsSales,
      flags,
      location,
    } = this.props;

    this.setState({ isInitialLoad: true });

    const isRenderViaNavigation = location?.state?.isRenderViaNavigation && location?.action === 'PUSH';
    if (isRenderViaNavigation) {
      return;
    }

    if (isFlagActive({ flag: flags.marketingVsSalesWidget })) {
      if (!marketingVsSales?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.marketingVsSales],
          configPerWidget: {
            [widgetTypes.marketingVsSales]: [{
              impactIndicator: this.state.marketingVsSalesIndicator,
              marketingVsSalesFrequency: this.state.marketingVsSalesFrequency,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters,
      attributionModel,
      isCompareToPreviousEnabled,
      autoFilter,
      region,
      flags,
      timeFrame,
      fiscalYearFirstMonth,
      isAccountMode,
    } = this.props;

    const {
      isInitialLoad,
    } = this.state;

    const didInitialLoadChanged = isInitialLoad !== prevState.isInitialLoad;
    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didFiltersChanged = isFiltersChanged({ filters, prevFilters: prevProps.filters });
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });
    const didAttributionModelChanged = attributionModel !== prevProps.attributionModel;
    const didPreDefinedFiltersChanged = autoFilter !== prevProps.autoFilter;
    const didCompareToPreviousChanged = isCompareToPreviousEnabled !== prevProps.isCompareToPreviousEnabled;
    const didRegionChanged = region !== prevProps.region;

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.historicalPerformanceWidget }) || isFlagActive({ flag: flags.funnelViewWidget }) || isFlagActive({ flag: flags.overviewStatsSquareWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.historicalPerformance,
          widgetConfig: {
            historicalPerformanceFrequency: this.state.historicalPerformanceFrequency,
          },
          configKeyName: this.currentTabName,
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.marketingVsSalesWidget })) {
        getWidgetsData({
          widgets: [widgetTypes.marketingVsSales],
          configPerWidget: {
            [widgetTypes.marketingVsSales]: [{
              impactIndicator: this.state.marketingVsSalesIndicator,
              marketingVsSalesFrequency: this.state.marketingVsSalesFrequency,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }

      if (isFlagActive({ flag: flags.analyzeOverviewGoalsAnalysis })) {
        const goalsSupportedTimeframe = getTimeframeOptionsByActivityStatus({ status: activityStatusValues.ALL }).map((option) => option.value);
        const isGoalsSupportSelectedTimeframe = goalsSupportedTimeframe.includes(timeFrame?.value);
        if (isGoalsSupportSelectedTimeframe) {
          getWidgetsDataV2({
            widget: widgetTypes.goalsAnalysis,
            widgetConfig: {
              goalsAnalysisParams: {
                frequencyType: pickerTimeframeToFrequencyType[timeFrame?.value],
                timeframe: getGoalTimeframe({ timeframe: timeFrame, fiscalYearFirstMonth }),
                supportFilters: true,
                status: activityStatusValues.ALL,
                shouldCalculateTrend: true,
              },
            },
            isPreviousTimeFrame: false,
          });
        }
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didPreDefinedFiltersChanged) {
      if (isFlagActive({ flag: flags.trendAnalysisWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.trendAnalysis,
          widgetConfig: {
            trendAnalysisParams: {
              metrics: this.state.trendAnalysisMetrics,
              frequency: this.state.trendAnalysisFrequency,
              shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
            },
          },
          configKeyName: this.currentTabName,
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }

    if (didCompareToPreviousChanged) {
      if (isFlagActive({ flag: flags.historicalPerformanceWidget }) || isFlagActive({ flag: flags.funnelViewWidget }) || isFlagActive({ flag: flags.overviewStatsSquareWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.historicalPerformance,
          widgetConfig: {
            historicalPerformanceFrequency: this.state.historicalPerformanceFrequency,
          },
          configKeyName: this.currentTabName,
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }

      if (isCompareToPreviousEnabled) {
        if (isFlagActive({ flag: flags.marketingVsSalesWidget })) {
          getWidgetsDataFromStore({
            widgets: [widgetTypes.marketingVsSales],
            configPerWidget: {
              [widgetTypes.marketingVsSales]: [{
                impactIndicator: this.state.marketingVsSalesIndicator,
                marketingVsSalesFrequency: this.state.marketingVsSalesFrequency,
              }],
            },
            isPreviousTimeFrame: true,
          });
        }

        if (isFlagActive({ flag: flags.trendAnalysisWidget })) {
          getWidgetsDataFromStoreV2({
            widget: widgetTypes.trendAnalysis,
            widgetConfig: {
              trendAnalysisParams: {
                metrics: this.state.trendAnalysisMetrics,
                frequency: this.state.trendAnalysisFrequency,
                shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
              },
            },
            configKeyName: this.currentTabName,
            isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
          });
        }
      }
    }
  }

  updateMarketingVsSalesIndicator = ({ newMarketingVsSalesIndicator }) => {
    this.setState({ marketingVsSalesIndicator: newMarketingVsSalesIndicator });
    getWidgetsData({
      widgets: [widgetTypes.marketingVsSales],
      configPerWidget: {
        [widgetTypes.marketingVsSales]: [{
          impactIndicator: newMarketingVsSalesIndicator,
          marketingVsSalesFrequency: this.state.marketingVsSalesFrequency,
        }],
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateMarketingVsSalesFrequency = ({ newMarketingVsSalesFrequency }) => {
    this.setState({ marketingVsSalesFrequency: newMarketingVsSalesFrequency });
    getWidgetsData({
      widgets: [widgetTypes.marketingVsSales],
      configPerWidget: {
        [widgetTypes.marketingVsSales]: [{
          impactIndicator: this.state.marketingVsSalesIndicator,
          marketingVsSalesFrequency: newMarketingVsSalesFrequency,
        }],
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateTrendAnalysisFrequency = ({ newTrendAnalysisFrequency }) => {
    this.setState({ trendAnalysisFrequency: newTrendAnalysisFrequency });
    getWidgetsDataV2({
      widget: widgetTypes.trendAnalysis,
      widgetConfig: {
        trendAnalysisParams: {
          metrics: this.state.trendAnalysisMetrics,
          frequency: newTrendAnalysisFrequency,
          shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
        },
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateTrendAnalysisShouldUseRelativeTimeframe = () => {
    const { trendAnalysisMetrics, trendAnalysisFrequency, trendAnalysisShouldUseRelativeTimeframe } = this.state;
    const newTrendAnalysisShouldUseRelativeTimeframe = !trendAnalysisShouldUseRelativeTimeframe;
    this.setState({ trendAnalysisShouldUseRelativeTimeframe: newTrendAnalysisShouldUseRelativeTimeframe });
    getWidgetsDataV2({
      widget: widgetTypes.trendAnalysis,
      widgetConfig: {
        trendAnalysisParams: {
          metrics: trendAnalysisMetrics,
          frequency: trendAnalysisFrequency,
          shouldUseRelativeTimeframe: newTrendAnalysisShouldUseRelativeTimeframe,
        },
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateTrendAnalysisMetrics = ({ newTrendAnalysisMetrics }) => {
    this.setState({ trendAnalysisMetrics: newTrendAnalysisMetrics });
    getWidgetsDataV2({
      widget: widgetTypes.trendAnalysis,
      widgetConfig: {
        trendAnalysisParams: {
          metrics: newTrendAnalysisMetrics,
          frequency: this.state.trendAnalysisFrequency,
          shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
        },
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateHistoricalPerformanceFrequency = ({ newHistoricalPerformanceFrequency }) => {
    this.setState({ historicalPerformanceFrequency: newHistoricalPerformanceFrequency });
    getWidgetsDataV2({
      widget: widgetTypes.historicalPerformance,
      widgetConfig: {
        historicalPerformanceFrequency: newHistoricalPerformanceFrequency,
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  render() {
    const {
      marketingVsSalesIndicator,
      marketingVsSalesFrequency,
      historicalPerformanceSelectedMetrics,
    } = this.state;
    const {
      flags,
      metricsOptions,
      historicalPerformance,
      isCompareToPreviousEnabled,
      marketingVsSales,
      trendAnalysis,
      goalsAnalysis,
      timeFrame,
      fiscalYearFirstMonth,
      filters,
      getWidgetRequestId,
      funnels,
    } = this.props;

    const historicalPerformanceFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        historicalPerformanceFrequency: this.state.historicalPerformanceFrequency,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const historicalPerformanceRequestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: historicalPerformanceFullWidgetConfig });

    const trendAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        trendAnalysisParams: {
          metrics: this.state.trendAnalysisMetrics,
          frequency: this.state.trendAnalysisFrequency,
          shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
        },
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const trendAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.trendAnalysis, widgetConfig: trendAnalysisFullWidgetConfig });

    const goalsAnalysisFullConfig = getWidgetFullConfig({
      widgetConfig: {
        goalsAnalysisParams: {
          frequencyType: pickerTimeframeToFrequencyType[timeFrame?.value],
          timeframe: getGoalTimeframe({ timeframe: timeFrame, fiscalYearFirstMonth: this.props.fiscalYearFirstMonth }),
          supportFilters: true,
          status: activityStatusValues.ALL,
          shouldCalculateTrend: true,
        },
      },
    });
    const goalsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.goalsAnalysis, widgetConfig: goalsAnalysisFullConfig });

    const historicalPerformanceData = historicalPerformance?.[historicalPerformanceRequestId]?.result;
    const goalsAnalysisData = goalsAnalysis?.[goalsAnalysisRequestId]?.result?.goals ?? [];
    const goalsSupportedTimeframe = getTimeframeOptionsByActivityStatus({ status: activityStatusValues.ALL }).map((option) => option.value);
    const isGoalsSupportSelectedTimeframe = goalsSupportedTimeframe.includes(timeFrame?.value);

    const isHistoricalPerformanceLoaded = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'finished';
    const isHistoricalPerformanceFailedToLoad = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'failed';
    const isTrendAnalysisLoaded = trendAnalysis?.[trendAnalysisRequestId]?.status === 'finished';
    const isTrendAnalysisFailedToLoad = trendAnalysis?.[trendAnalysisRequestId]?.status === 'failed';
    const isGoalsAnalysisFailedToLoad = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'failed';
    const isGoalAnalysisLoaded = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'finished' || !isGoalsSupportSelectedTimeframe || isGoalsAnalysisFailedToLoad;

    const requireKeysForStatsSquare = getSelectedMetricsForStatsSquare({ userFunnels: funnels });

    const isHistoricalPerformanceForStatsSquareLoaded = requireKeysForStatsSquare.every((key) => Object.keys(historicalPerformance?.[historicalPerformanceRequestId]?.result || {}).includes(key)) || isHistoricalPerformanceLoaded;
    let statsSquaresData = {};
    if (isHistoricalPerformanceForStatsSquareLoaded) {
      statsSquaresData = parseHistoricalPerformanceDataToStatsSquares({ historicalPerformance: historicalPerformance?.[historicalPerformanceRequestId]?.result, isCompareToPreviousEnabled });
    }

    return (
      <>
        <BreadcrumbsTitle breadcrumbs={[pageLabels.analyze, pageLabels.overview]} />

        <FeatureFlagsWithBlur flag={flags.analyzeOverviewGoalsAnalysis} name={flagsNames.analyzeOverviewGoalsAnalysis}>
          <GoalsAnalysis
            isLoaded={isGoalAnalysisLoaded}
            goals={goalsAnalysisData}
            frequencyType={pickerTimeframeToFrequencyType[timeFrame?.value]}
            timeframe={getGoalTimeframe({ timeframe: timeFrame, fiscalYearFirstMonth })}
            isEnableCreateNewGoal
            isGoalsSupportSelectedTimeframe={isGoalsSupportSelectedTimeframe}
            isHavingApplyingFilters={filters.length > 0}
            isShowAddAlertInPopupNavigationMenu
            isFailedToLoad={isGoalsAnalysisFailedToLoad}
            goalsAnalysisRequestId={goalsAnalysisRequestId}
          />
        </FeatureFlagsWithBlur>
        <FeatureFlagsWithBlur flag={flags.overviewStatsSquareWidget} name={flagsNames.overviewStatsSquareWidget}>
          <StatsSquares
            indicatorsCount={statsSquaresData.indicatorsCount}
            revenueAndPipeline={statsSquaresData.revenueAndPipelineOverview}
            totalCost={statsSquaresData.totalCost}
            growthValues={statsSquaresData.growthValues || {}}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            isLoaded={isHistoricalPerformanceForStatsSquareLoaded}
            isFailedToLoad={isHistoricalPerformanceFailedToLoad}
          />
        </FeatureFlagsWithBlur>

        <div className={classNames(this.classes.splitWidget, this.classes.splitWidgetNoPadding)}>
          <FeatureFlagsWithBlur flag={flags.funnelViewWidget} name={flagsNames.funnelViewWidget}>
            <FunnelView
              navigateToJourneys={() => navigateToJourneys({ indicator: null }, attributionStore.rawFilters)}
              isLoaded={isHistoricalPerformanceLoaded}
              isFailedToLoad={isHistoricalPerformanceFailedToLoad}
              funnels={this.props.funnels}
              data={historicalPerformance?.[historicalPerformanceRequestId]?.result}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            />
          </FeatureFlagsWithBlur>
          <FeatureFlagsWithBlur flag={flags.historicalPerformanceWidget} name={flagsNames.historicalPerformanceWidget}>
            <HistoricalPerformance
              isLoaded={isHistoricalPerformanceLoaded}
              isFailedToLoad={isHistoricalPerformanceFailedToLoad}
              navigateToJourneys={(funnelStage) => navigateToJourneys({ indicator: funnelStage }, attributionStore.rawFilters)}
              historicalPerformance={historicalPerformanceData}
              historicalPerformanceFrequency={this.state.historicalPerformanceFrequency}
              updateHistoricalPerformanceFrequency={(frequencyValue) => this.updateHistoricalPerformanceFrequency({ newHistoricalPerformanceFrequency: frequencyValue })}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              selectedMetrics={historicalPerformanceSelectedMetrics}
              setSelectedMetrics={({ selectedMetrics }) => this.setState({ historicalPerformanceSelectedMetrics: selectedMetrics })}
            />
          </FeatureFlagsWithBlur>
        </div>

        <FeatureFlagsWithBlur flag={flags.marketingVsSalesWidget} name={flagsNames.marketingVsSalesWidget}>
          <ImpactByIndicator
            metricsOptions={metricsOptions}
            impactData={marketingVsSales?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data ?? []}
            impactPrevData={isCompareToPreviousEnabled ? marketingVsSales?.[analyzeWidgetsResultKeyNames.previousTimeFrame]?.data : []}
            impactIndicator={marketingVsSalesIndicator}
            updateImpactIndicator={(indicator) => this.updateMarketingVsSalesIndicator({ newMarketingVsSalesIndicator: indicator })}
            frequencyControl={(frequency) => this.updateMarketingVsSalesFrequency({ newMarketingVsSalesFrequency: frequency.value })}
            currentFrequency={marketingVsSalesFrequency}
            isLoaded={marketingVsSales?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded
            && (!isCompareToPreviousEnabled || marketingVsSales?.[analyzeWidgetsResultKeyNames.previousTimeFrame]?.isLoaded)}
          />
        </FeatureFlagsWithBlur>

        <FeatureFlagsWithBlur flag={flags.trendAnalysisWidget} name={flagsNames.trendAnalysisWidget}>
          <TrendAnalysis
            isLoaded={isTrendAnalysisLoaded}
            isFailedToLoad={isTrendAnalysisFailedToLoad}
            trendAnalysis={trendAnalysis?.[trendAnalysisRequestId]?.result}
            trendAnalysisFrequency={this.state.trendAnalysisFrequency}
            trendAnalysisMetrics={this.state.trendAnalysisMetrics}
            trendAnalysisShouldUseRelativeTimeframe={this.state.trendAnalysisShouldUseRelativeTimeframe}
            updateTrendAnalysisFrequency={(frequencyValue) => this.updateTrendAnalysisFrequency({ newTrendAnalysisFrequency: frequencyValue })}
            updateTrendAnalysisMetrics={(metrics) => this.updateTrendAnalysisMetrics({ newTrendAnalysisMetrics: metrics })}
            updateTrendAnalysisShouldUseRelativeTimeframe={() => this.updateTrendAnalysisShouldUseRelativeTimeframe()}
            metricsOptions={metricsOptions}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            timeFrame={timeFrame}
          />
        </FeatureFlagsWithBlur>
      </>
    );
  }
}

export default withLDConsumer()(enhance(Overview));
