exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".mySignalsTable__rowWrapper-1gc{color:var(--colorsTextsTextGray);font-size:14px;font-weight:400;display:flex;min-width:140px}.mySignalsTable__rulesCell-3Ka{display:flex;gap:8px;align-items:center;color:var(--colorsTextsTextGray)}.mySignalsTable__clickableItem-3eB:hover{cursor:pointer;color:var(--colorsGeneralBlue);-webkit-text-decoration-line:underline;text-decoration-line:underline;border-radius:12px}.mySignalsTable__icon-2D7{background-repeat:no-repeat;background-position:50%;min-width:revert;cursor:pointer}.mySignalsTable__cardRowsTableWrapper-arj .mySignalsTable__switchCell-3qG *{box-sizing:content-box}.mySignalsTable__aiType-2pb{color:var(--colorsGeneralPurple);background:url(/assets/ai/purpleAIStars.svg) 0 no-repeat;padding-left:28px}.mySignalsTable__sectionDisabled-2jv{opacity:.5;cursor:none}.mySignalsTable__cardRowsTableWrapper-arj{padding:16px 0 0}", ""]);

// exports
exports.locals = {
	"rowWrapper": "mySignalsTable__rowWrapper-1gc",
	"rowWrapper": "mySignalsTable__rowWrapper-1gc",
	"rulesCell": "mySignalsTable__rulesCell-3Ka",
	"rulesCell": "mySignalsTable__rulesCell-3Ka",
	"clickableItem": "mySignalsTable__clickableItem-3eB",
	"clickableItem": "mySignalsTable__clickableItem-3eB",
	"icon": "mySignalsTable__icon-2D7",
	"icon": "mySignalsTable__icon-2D7",
	"cardRowsTableWrapper": "mySignalsTable__cardRowsTableWrapper-arj",
	"cardRowsTableWrapper": "mySignalsTable__cardRowsTableWrapper-arj",
	"switchCell": "mySignalsTable__switchCell-3qG",
	"switchCell": "mySignalsTable__switchCell-3qG",
	"aiType": "mySignalsTable__aiType-2pb",
	"aiType": "mySignalsTable__aiType-2pb",
	"sectionDisabled": "mySignalsTable__sectionDisabled-2jv",
	"sectionDisabled": "mySignalsTable__sectionDisabled-2jv"
};