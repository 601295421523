import { getColumnMetricNickName } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { upliftLabel, upliftNoValueTooltip } from 'components/pages/analyze/AttribuitonTable/enums';
import { getUpliftValueAsText } from 'components/common/logic/uplift';
import { getItemLabelWithNoValue } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';

export function getOptionsByOffset({ searchValue, prevOptions, options }) {
  const offset = prevOptions.length;
  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()));
  const slicedOptions = filteredOptions.slice(offset, offset + 10);
  return { options: slicedOptions, hasMore: slicedOptions.length > 0 };
}

export function getUpliftSwitchTooltip({ metric }) {
  return (`
    <div>
        Per segment, Uplift calculates the <b>${metric}</b>
        of journeys that include it, vs the <b>${metric}</b> of journeys that don’t.
        The resulting metric shows you how much of an impact a segment has on the journey.
    </div>
`);
}

export function getUpliftValueTooltip({
  metric, value, firstSegment, secondSegment, upliftRangeLimit, selectedSegments,
}) {
  if (value === 0) {
    return null;
  }

  if (!value) {
    return upliftNoValueTooltip;
  }

  let segmentsLabels = getItemLabelWithNoValue({
    value: firstSegment, segment: selectedSegments.firstSegment.value, segmentLabel: selectedSegments.firstSegment.label,
  });

  if (secondSegment) {
    const secondSegmentLabel = getItemLabelWithNoValue({
      value: secondSegment, segment: selectedSegments.secondSegment.value, segmentLabel: selectedSegments.secondSegment.label,
    });

    segmentsLabels = `${segmentsLabels} and ${secondSegmentLabel}`;
  }

  const isIncrease = value > 0;
  const metricTitle = getColumnMetricNickName({ metric: metric.replace(upliftLabel, '') });
  const upliftValue = getUpliftValueAsText({ value, upliftRangeLimit });

  return (
    `${metricTitle} ${isIncrease ? 'increases' : 'decreases'} by ${upliftValue} when ${segmentsLabels} is part of the journey.`
  );
}
