import React, { useState, useEffect } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';
import AIQuickFilters from 'components/pages/settings/quickFilters/AIQuickFilters';
import CustomQuickFilters from 'components/pages/settings/quickFilters/CustomQuickFilters';
import QuickFilterPopup from 'components/pages/settings/quickFilters/quickFilterPopup';
import servicesStore from 'stores/servicesStore';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { emptyStateTitle, emptyStateSubTitle, quickFiltersCreationTypesLabel } from 'components/pages/settings/quickFilters/enums';
import { Events } from 'trackers/analytics/enums';
import { pageLabels } from 'enums';

import style from 'styles/settings/quickFilters/quickFilters.css';

const styles = style.locals || {};

function QuickFilters({
  quickFilters,
  getQuickFiltersServerRequest,
  quickFiltersAIRecommendations,
  hasMoreQuickFiltersAIRecommendations,
  getAIRecommendationServerRequest,
  isLoadingQuickFiltersServerRequest,
  isLoadingAIRecommendationServerRequest,
  shouldUseAIRecommendation,
  suggestMoreAIRecommendationFilters,
  isLoadingSuggestMoreAIRecommendationServerRequest,
  shouldUseAIRecommendationServerRequest,
}) {
  useStyles([style], [injectStyle]);

  const [selectedQuickFilter, setSelectedQuickFilter] = useState();
  const [stepIndexToOpen, setStepIndexToOpen] = useState(0);
  const [isQuickFilterPopupDisabled, setIsQuickFilterPopupDisabled] = useState(false);

  const isQuickFiltersEmpty = isLoadingQuickFiltersServerRequest || isLoadingQuickFiltersServerRequest ? false : quickFilters?.length === 0 && quickFiltersAIRecommendations.length === 0;

  useEffect(() => {
    shouldUseAIRecommendationServerRequest();
    getQuickFiltersServerRequest();
    if (quickFiltersAIRecommendations.length === 0 && hasMoreQuickFiltersAIRecommendations) {
      getAIRecommendationServerRequest();
    }
  }, []);

  function onClickedToViewQuickFilter({ filter }) {
    setSelectedQuickFilter(filter);
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.clickedToViewQuickFilterSettings,
      properties: {
        quickFilterType: quickFiltersCreationTypesLabel[filter.type],
      },
    });
  }

  function onClickSuggestMore() {
    getAIRecommendationServerRequest({ suggestMoreRequest: true });
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.showMoreAIRecommended,
      properties: {
        AIFiltersToggleStatus: shouldUseAIRecommendation ? 'enabled' : 'disabled',
      },
    });
  }

  return (
    <>
      <BreadcrumbsTitle breadcrumbs={[pageLabels.ops, pageLabels.quickFilters]} />

      {selectedQuickFilter ? (
        <QuickFilterPopup
          quickFilterData={selectedQuickFilter}
          onClose={() => setSelectedQuickFilter(null)}
          stepIndexToOpen={stepIndexToOpen}
          isDisabled={isQuickFilterPopupDisabled}
        />
      ) : null}
      <div className={styles.wrapper}>
        {isQuickFiltersEmpty ? (
          <EmptyStateWithImage
            title={emptyStateTitle}
            subTitle={emptyStateSubTitle}
            buttonName="+ New Filter"
            imageClassName={styles.emptyStateImage}
            onClick={() => {
              setIsQuickFilterPopupDisabled(false);
              setSelectedQuickFilter({});
            }}
          />
        ) : (
          <div className={classnames(styles.content, !hasMoreQuickFiltersAIRecommendations && styles.wrapperWithoutSuggestMore)}>
            <AIQuickFilters
              aiQuickFilters={quickFiltersAIRecommendations}
              setSelectedQuickFilter={(filter) => {
                setIsQuickFilterPopupDisabled(false);
                onClickedToViewQuickFilter({ filter });
              }}
              isLoading={isLoadingAIRecommendationServerRequest}
              setStepIndexToOpen={(stepIndex) => setStepIndexToOpen(stepIndex)}
            />
            <div>
              <CustomQuickFilters
                customQuickFilters={quickFilters || []}
                setSelectedQuickFilter={(filter) => {
                  setIsQuickFilterPopupDisabled(false);
                  onClickedToViewQuickFilter({ filter });
                }}
                isLoading={isLoadingQuickFiltersServerRequest || isLoadingSuggestMoreAIRecommendationServerRequest}
                suggestMoreAIRecommendationFilters={suggestMoreAIRecommendationFilters}
                setStepIndexToOpen={(stepIndex) => setStepIndexToOpen(stepIndex)}
              />
              {hasMoreQuickFiltersAIRecommendations ? (
                <div className={styles.footer}>
                  <div className={styles.suggestMore} onClick={() => onClickSuggestMore()}>
                    Suggest more
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>

      <ToastContainer
        limit={1}
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Slide}
      />
    </>
  );
}

export default inject(
  ({
    filterStore: {
      getQuickFiltersServerRequest,
      quickFilters,
      getAIRecommendationServerRequest,
      quickFiltersAIRecommendations,
      hasMoreQuickFiltersAIRecommendations,
      isLoadingQuickFiltersServerRequest,
      isLoadingAIRecommendationServerRequest,
      shouldUseAIRecommendation,
      updateShouldUseAIRecommendationServerRequest,
      suggestMoreAIRecommendationFilters,
      isLoadingSuggestMoreAIRecommendationServerRequest,
      shouldUseAIRecommendationServerRequest,
    } = {},
  }) => ({
    getQuickFiltersServerRequest,
    quickFilters,
    getAIRecommendationServerRequest,
    quickFiltersAIRecommendations,
    hasMoreQuickFiltersAIRecommendations,
    isLoadingQuickFiltersServerRequest,
    isLoadingAIRecommendationServerRequest,
    shouldUseAIRecommendation,
    updateShouldUseAIRecommendationServerRequest,
    suggestMoreAIRecommendationFilters,
    isLoadingSuggestMoreAIRecommendationServerRequest,
    shouldUseAIRecommendationServerRequest,
  }),
  observer
)(QuickFilters);
