import { Link } from 'react-router';

import useStyles from 'hooks/useStyles';

import tony from 'assets/tony.svg';
import style from 'styles/Page404.css';

const styles = style.locals || {};

function Page404() {
  useStyles([style]);

  return (
    <div>
      <div className={styles.wrapperBody}>
        <h1>Tony couldn’t find the page you’re looking for :(</h1>
        <img src={tony} alt="tony" className={styles.tonyIcon} />
        <div className={styles.footer}>
          <div className={styles.row}>
            But don’t worry, you can always go
            <Link to="/dashboard/navigate" onlyActiveOnIndex>
              Back Home
            </Link>
          </div>
          <div className={styles.row}>
            {'If you still can\'t find what you\'re looking for, feel free to '}
            <a
              href="mailto:support@infinigrow.com?&subject=Support Request"
              target="_blank"
              rel="noreferrer"
            >
              Contact us
            </a>
            {'. We\'re here to help!'}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page404;
