import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import servicesStore from 'stores/servicesStore';
import attributionStore from 'stores/attributionStore';
import NavigationTierTabs from 'components/navigationMenu/NavigationTierTabs';

import { subMenuTabsNamesWithBetaTag } from 'components/enums';
import { getMemberFullName } from 'components/utils/teamMembers';
import { firstTierRoutes, settingsNavigationMenu } from 'components/navigationMenu/enums';
import { getCurrentTierByPath } from 'components/navigationMenu/logic/navigationTierItems';
import { tabsIds, tabsPaths } from 'enums';

import style from 'styles/navigationMenu/sideBarNavigationMenu.css';

const styles = style.locals || {};

function SideBarNavigationMenu({
  userAccount,
  updateParentState,
  globalRoutes,
  flags,
}) {
  useStyles([style]);

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [activeTier, setActiveTier] = useState(getCurrentTierByPath({ globalRoutes, path: window.location.pathname }));

  useEffect(() => {
    if (!isSideMenuOpen) {
      const currentActiveTier = getCurrentTierByPath({ globalRoutes, path: window.location.pathname });
      if (activeTier.mainTier !== currentActiveTier.mainTier && activeTier.subTier !== currentActiveTier.subTier) {
        setActiveTier(currentActiveTier);
      }
    }
  }, [isSideMenuOpen]);

  function onClickLogout() {
    attributionStore.resetToDefaultData();
    updateParentState({ unsaved: false }, () => {
      servicesStore.authService.logout();
    });
  }

  const userProfile = servicesStore.authService.getProfileSync();
  const currentMember = userAccount?.teamMembers.find((member) => member.userId === userProfile.user_id);
  const isOnboarding = currentMember?.isShowGettingStartedPage;

  const firstTierRoutesWithFlags = firstTierRoutes.filter((tab) => {
    if (tab.id === tabsIds.gettingStarted && !isOnboarding) {
      return false;
    }
    return !tab.flag || flags[tab.flag];
  });

  return (
    <div
      className={classnames(styles.wrapper, isSideMenuOpen && styles.openSideBarMenu)}
      onMouseEnter={() => setIsSideMenuOpen(true)}
      onMouseLeave={() => setIsSideMenuOpen(false)}
      data-testid={isSideMenuOpen ? 'sideBarMenuOpen' : 'sideBarMenuClosed'}
    >
      <Link
        to={tabsPaths.dashboard}
        key={tabsIds.dashboard}
        onlyActiveOnIndex
      >
        <div className={styles.logo} data-testid="infinitiGrowLogo" />
      </Link>
      <div className={styles.navigation}>
        <div className={styles.aiAnswers}>
          <Link
            to={flags.aiAnswersNewUi ? '/answers' : '/questions'}
            activeClassName={styles.aiSelectedItem}
            onlyActiveOnIndex
            key="aiAnswers"
          >
            <div data-icon="navigationMenu:aiAnswers" />
            <div className={styles.showWhenMenuIsOpen}>AI Answers</div>
          </Link>
        </div>

        <NavigationTierTabs
          mainRoutes={firstTierRoutesWithFlags}
          globalRoutes={globalRoutes}
          activeTier={activeTier}
          setActiveTier={setActiveTier}
          isSideMenuOpen={isSideMenuOpen}
          subMenuTabsNamesWithBetaTag={subMenuTabsNamesWithBetaTag}
          dividerId={tabsIds.ops}
        />
      </div>

      <div className={styles.bottomMenu}>
        <NavigationTierTabs
          mainRoutes={settingsNavigationMenu}
          globalRoutes={globalRoutes}
          activeTier={activeTier}
          setActiveTier={setActiveTier}
          isSideMenuOpen={isSideMenuOpen}
          subMenuTabsNamesWithBetaTag={subMenuTabsNamesWithBetaTag}
        />

        <div className={styles.support}>
          <a
            href="mailto:support@infinigrow.com?&subject=Support Request"
            target="_blank"
            rel="noreferrer"
          >
            <div data-icon="navigationMenu:support" />
            <div className={styles.showWhenMenuIsOpen}>Support</div>
          </a>
        </div>

        <div className={styles.userWrapper}>
          <div
            className={styles.userAvatar}
            style={{ backgroundImage: userAccount?.companyWebsite ? `url(https://logo.clearbit.com/${userAccount.companyWebsite})` : '' }}
          />
          <div className={styles.showWhenMenuIsOpen}>
            <div className={styles.userDetails}>
              <div className={styles.userName}>
                {currentMember ? (
                  <b>{getMemberFullName(currentMember)}</b>
                ) : null}
                {userAccount?.companyName}
              </div>
              <div
                onClick={() => onClickLogout()}
                className={styles.logout}
                data-testid="logout"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    userStore: {
      userAccount,
    } = {},
  }) => ({
    userAccount,
  }),
  observer
)(SideBarNavigationMenu));
