export const filterTagsColorThemeOptions = {
  orange: 'orange',
  ai: 'ai',
  error: 'error',
  default: 'default',
};

export const filterButtonPositions = {
  start: 'start',
  end: 'end',
};
