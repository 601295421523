import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/users/filters.css';

const styles = style.locals || {};

export default function FiltersIconWithIndication({ activeNumber = 0, numberClassname }) {
  useStyles([style]);

  return (
    <div className={styles.filtersIconWithIndication}>
      {activeNumber ? (
        <div data-testid="active-number" className={classnames(styles.filtersActiveNumber, numberClassname)}>
          {activeNumber}
        </div>
      ) : null}
    </div>
  );
}
