exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".workflows__workflowsWrap-1X1{gap:12px;padding:20px 0;display:grid;grid-template-columns:1fr 1fr 1fr}.workflows__newSection-WWN{padding:60px 0}.workflows__title-10g{color:#182033;font-size:20px;font-weight:600;line-height:20px}", ""]);

// exports
exports.locals = {
	"workflowsWrap": "workflows__workflowsWrap-1X1",
	"workflowsWrap": "workflows__workflowsWrap-1X1",
	"newSection": "workflows__newSection-WWN",
	"newSection": "workflows__newSection-WWN",
	"title": "workflows__title-10g",
	"title": "workflows__title-10g"
};