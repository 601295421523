import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import Textfield from 'components/controls/Textfield';

import { formatIndicatorDisplay } from 'components/utils/indicators';
import { compose } from 'components/utils/utils';
import { getFiscalQuarterForMonth, getFiscalYearForMonth } from 'components/utils/dates';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';

import objectivePopup from 'styles/welcome/objective-popup.css';

const objClasses = objectivePopup.locals;

const enhance = compose(
  inject(({ userStore }) => ({
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    timezone: userStore.userMonthPlan.CRMConfig.timezone,
  })),
  observer
);

function MilestoneBox({
  metricName,
  targetValue,
  timeframe,
  isQuarter,
  onChange,
  disabled,
  fiscalYearFirstMonth,
  timezone,
}) {
  let fiscalQuarter;
  let fiscalYear;
  if (isQuarter) {
    const fiscalMonth = timezone === DEFAULT_TIME_ZONE ? moment(timeframe.startDate).format('M') : moment(timeframe.startDate).format('M');
    fiscalQuarter = getFiscalQuarterForMonth({ month: fiscalMonth, fiscalYearFirstMonth });
    fiscalYear = getFiscalYearForMonth(fiscalMonth, fiscalYearFirstMonth + 1, moment(timeframe.startDate).format('YYYY'));
  }

  return (
    <div className={objClasses.month}>
      <div className={objClasses.monthText}>
        {
                    isQuarter
                      ? `Quarter ${fiscalQuarter}, ${fiscalYear}`
                      : moment(timeframe.startDate).format('MMMM, YYYY')
                }
      </div>
      <div className={objClasses.monthInputWrapper}>
        <Textfield
          value={metricName ? formatIndicatorDisplay(metricName, targetValue, false, false) : targetValue}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default enhance(MilestoneBox);
