import moment from 'moment/moment';
import { get } from 'lodash';

export function getLastCRMIntegrationDate({ integrationsLastUpdate }) {
  const allCrmLastUpdateTimes = Object.values(integrationsLastUpdate);
  if (allCrmLastUpdateTimes.length === 0) {
    return null;
  }
  let earliestDate = moment(allCrmLastUpdateTimes[0]);
  for (const lastUpdateTime of allCrmLastUpdateTimes) {
    const crmLastUpdateTime = moment(lastUpdateTime);
    if (crmLastUpdateTime < earliestDate) {
      earliestDate = crmLastUpdateTime;
    }
  }
  return earliestDate;
}

export function getLastAdsIntegrationDate({ userMonthPlan }) {
  const campaignsDates = [
    userMonthPlan?.linkedinadsapi,
    userMonthPlan?.facebookadsapi,
    userMonthPlan?.twitteradsapi,
    userMonthPlan?.adwordsapi,
    userMonthPlan?.bingadsapi,
    userMonthPlan?.quoraadsapi,
    userMonthPlan?.pardotapi,
  ].map((platform) => get(platform, 'lastUpdateTime'))
    .filter((date) => date)
    .map((date) => new Date(date));

  const salesforceCampaignLastUpdateTime = get(userMonthPlan?.salesforceapi, 'campaignsLastUpdateTime');
  if (salesforceCampaignLastUpdateTime) {
    campaignsDates.push(new Date(salesforceCampaignLastUpdateTime));
  }
  return campaignsDates.length ? new Date(Math.min(...campaignsDates)) : null;
}
