exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".createSignalPopup__pageContainer-1od>div>div{width:600px}.createSignalPopup__wrapper-2YE{display:flex;flex-direction:column;align-self:stretch}.createSignalPopup__wrapperSpaces-2kV{gap:20px}.createSignalPopup__row-1X5{display:flex;align-items:center;gap:8px}.createSignalPopup__controllerWrapper-3LY{width:100%}.createSignalPopup__inputWrapper-1mM{display:flex;gap:12px;align-items:center}.createSignalPopup__controllerTitle-3vY{color:var(--colorsTextsTextDarkGray);font-size:12px;font-weight:600}.createSignalPopup__titleGap-2e4{padding-bottom:12px}.createSignalPopup__additionalConfigurationComplexText-3P_{display:flex;font-size:14px;color:var(--colorsTextsTextDarkGray)}.createSignalPopup__rowWithDropdown-3dg{height:45px}.createSignalPopup__relatedObjectsLabel-31M{display:flex;white-space:nowrap;gap:4px}.createSignalPopup__relatedObjectsEllipsis-2AL{font-weight:600;max-width:120px}.createSignalPopup__toggleBox-N2U{width:100%}.createSignalPopup__toggleBox-N2U>div{flex:1}.createSignalPopup__bold-3sv{font-weight:600}.createSignalPopup__icon-2cu{transform:rotate(45deg)}.createSignalPopup__endOfSection-a78{border-bottom:1px solid var(--colorsInputsGrayLine);width:100%;padding-bottom:20px}", ""]);

// exports
exports.locals = {
	"pageContainer": "createSignalPopup__pageContainer-1od",
	"pageContainer": "createSignalPopup__pageContainer-1od",
	"wrapper": "createSignalPopup__wrapper-2YE",
	"wrapper": "createSignalPopup__wrapper-2YE",
	"wrapperSpaces": "createSignalPopup__wrapperSpaces-2kV",
	"wrapperSpaces": "createSignalPopup__wrapperSpaces-2kV",
	"row": "createSignalPopup__row-1X5",
	"row": "createSignalPopup__row-1X5",
	"controllerWrapper": "createSignalPopup__controllerWrapper-3LY",
	"controllerWrapper": "createSignalPopup__controllerWrapper-3LY",
	"inputWrapper": "createSignalPopup__inputWrapper-1mM",
	"inputWrapper": "createSignalPopup__inputWrapper-1mM",
	"controllerTitle": "createSignalPopup__controllerTitle-3vY",
	"controllerTitle": "createSignalPopup__controllerTitle-3vY",
	"titleGap": "createSignalPopup__titleGap-2e4",
	"titleGap": "createSignalPopup__titleGap-2e4",
	"additionalConfigurationComplexText": "createSignalPopup__additionalConfigurationComplexText-3P_",
	"additionalConfigurationComplexText": "createSignalPopup__additionalConfigurationComplexText-3P_",
	"rowWithDropdown": "createSignalPopup__rowWithDropdown-3dg",
	"rowWithDropdown": "createSignalPopup__rowWithDropdown-3dg",
	"relatedObjectsLabel": "createSignalPopup__relatedObjectsLabel-31M",
	"relatedObjectsLabel": "createSignalPopup__relatedObjectsLabel-31M",
	"relatedObjectsEllipsis": "createSignalPopup__relatedObjectsEllipsis-2AL",
	"relatedObjectsEllipsis": "createSignalPopup__relatedObjectsEllipsis-2AL",
	"toggleBox": "createSignalPopup__toggleBox-N2U",
	"toggleBox": "createSignalPopup__toggleBox-N2U",
	"bold": "createSignalPopup__bold-3sv",
	"bold": "createSignalPopup__bold-3sv",
	"icon": "createSignalPopup__icon-2cu",
	"icon": "createSignalPopup__icon-2cu",
	"endOfSection": "createSignalPopup__endOfSection-a78",
	"endOfSection": "createSignalPopup__endOfSection-a78"
};