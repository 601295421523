import { sourcesKeys, signalsPriorities } from 'components/widgets/signals/enums';

export function getMySignalsMockData() {
  return [
    {
      id: '1',
      name: 'Researching actively',
      rules: [{ name: 'Visited Company Page' }],
      timeframe: {
        value: 'rolling',
        type: 'day',
        rollingValue: 'last_30_day_day',
        excludeToday: true,
      },
      source: sourcesKeys.g2,
      active: true,
    },
    {
      id: '2',
      name: 'Score crossed 85',
      rules: [{
        name: 'Score >= 85', key: 'score', value: '85', operator: 'largerThan', occurrence: 30,
      }],
      timeframe: {
        value: 'rolling',
        type: 'day',
        rollingValue: 'last_30_day_day',
        excludeToday: true,
      },
      source: sourcesKeys.sixSense,
      active: true,
    },
    {
      id: '3',
      name: 'PQL',
      rules: [{ name: 'Visited /what-if' }, { name: '5 Sessions', key: 'session', occurrence: 5 }],
      timeframe: {
        value: 'rolling',
        type: 'day',
        rollingValue: 'last_30_day_day',
        excludeToday: true,
      },
      source: sourcesKeys.infinigrowAI,
      active: true,
    },
  ];
}

export function getSignalAnalyticsMockData() {
  return [
    {
      id: '1',
      timeTriggered: 2407,
      closedWonsInfluenced: 374,
      uplift: 40,
      lastTriggered: new Date('10/16/2024'),
    },
    {
      id: '2',
      timeTriggered: 518,
      closedWonsInfluenced: 94,
      uplift: -20,
      lastTriggered: new Date('09/04/2024'),
    },
    {
      id: '3',
      timeTriggered: 1407,
      closedWonsInfluenced: 240,
      uplift: 76,
      lastTriggered: new Date('10/12/2024'),
    },
  ];
}

export function getAccountPrioritizationMockData() {
  return [
    {
      account: 'Atero',
      intentStatus: signalsPriorities.new,
      activeContacts: 1,
      recentSignal: new Date('09/23/23'),
      touchpoints: 238,
      openPipeline: 48300,
      opportunityStage: 'Champion Convinced',
      signals: [
        { signalId: '1', triggered: '15' },
        { signalId: '2', triggered: '5' },
        { signalId: '3', triggered: '0' },
      ],
    },
    {
      account: 'Alpha',
      intentStatus: signalsPriorities.new,
      activeContacts: 4,
      recentSignal: new Date('09/23/23'),
      touchpoints: 112,
      openPipeline: 39100,
      opportunityStage: 'Champion Convinced',
      signals: [
        { signalId: '1', triggered: '0' },
        { signalId: '2', triggered: '0' },
        { signalId: '3', triggered: '3' },
      ],
    },
    {
      account: 'Armo',
      intentStatus: signalsPriorities.new,
      activeContacts: 2,
      recentSignal: new Date('09/23/23'),
      touchpoints: 103,
      openPipeline: 38800,
      opportunityStage: 'Contract Sent',
      signals: [
        { signalId: '1', triggered: '6' },
        { signalId: '2', triggered: '4' },
        { signalId: '3', triggered: '2' },
      ],
    },
    {
      account: 'BioTech',
      intentStatus: signalsPriorities.new,
      activeContacts: 1,
      recentSignal: new Date('09/23/23'),
      touchpoints: 99,
      openPipeline: 33300,
      opportunityStage: 'Decision Maker Bought In',
      signals: [
        { signalId: '1', triggered: '5' },
        { signalId: '2', triggered: '7' },
        { signalId: '3', triggered: '1' },
      ],
    },
    {
      account: 'Blender',
      intentStatus: signalsPriorities.hot,
      activeContacts: 3,
      recentSignal: new Date('09/15/24'),
      touchpoints: 238,
      openPipeline: 94300,
      opportunityStage: 'Decision Maker Bought In',
      signals: [
        { signalId: '1', triggered: '16' },
        { signalId: '2', triggered: '2' },
        { signalId: '3', triggered: '0' },
      ],
    },
    {
      account: 'Doors Open',
      intentStatus: signalsPriorities.hot,
      activeContacts: 3,
      recentSignal: new Date('09/14/24'),
      touchpoints: 112,
      openPipeline: 88100,
      opportunityStage: 'Contract Sent',
      signals: [
        { signalId: '1', triggered: '0' },
        { signalId: '2', triggered: '0' },
        { signalId: '3', triggered: '0' },
      ],
    },
    {
      account: 'Damian',
      intentStatus: signalsPriorities.hot,
      activeContacts: 5,
      recentSignal: new Date('09/14/24'),
      touchpoints: 103,
      openPipeline: 80800,
      opportunityStage: 'Security Review',
      signals: [
        { signalId: '1', triggered: '7' },
        { signalId: '2', triggered: '3' },
        { signalId: '3', triggered: '4' },
      ],
    },
    {
      account: 'Dreamz',
      intentStatus: signalsPriorities.hot,
      activeContacts: 1,
      recentSignal: new Date('09/13/24'),
      touchpoints: 99,
      openPipeline: 79300,
      opportunityStage: 'Negotiating',
      signals: [
        { signalId: '1', triggered: '0' },
        { signalId: '2', triggered: '0' },
        { signalId: '3', triggered: '6' },
      ],
    },
    {
      account: 'Fargo',
      intentStatus: signalsPriorities.slipping,
      activeContacts: 2,
      recentSignal: new Date('09/15/24'),
      touchpoints: 238,
      openPipeline: 12300,
      opportunityStage: 'Negotiating',
      signals: [
        { signalId: '1', triggered: '10' },
        { signalId: '2', triggered: '6' },
        { signalId: '3', triggered: '1' },
      ],
    },
    {
      account: 'Gimeni',
      intentStatus: signalsPriorities.slipping,
      activeContacts: 4,
      recentSignal: new Date('09/14/24'),
      touchpoints: 112,
      openPipeline: 9100,
      opportunityStage: 'Negotiating',
      signals: [
        { signalId: '1', triggered: '21' },
        { signalId: '2', triggered: '6' },
        { signalId: '3', triggered: '0' },
      ],
    },
    {
      account: 'Gimbala',
      intentStatus: signalsPriorities.cold,
      activeContacts: 4,
      recentSignal: new Date('09/14/24'),
      touchpoints: 103,
      openPipeline: 7800,
      opportunityStage: 'Champion Convinced',
      signals: [
        { signalId: '1', triggered: '2' },
        { signalId: '2', triggered: '5' },
        { signalId: '3', triggered: '0' },
      ],
    },
    {
      account: 'Johnson & Bobson',
      intentStatus: signalsPriorities.cold,
      activeContacts: 1,
      recentSignal: new Date('09/13/24'),
      touchpoints: 99,
      openPipeline: 6300,
      opportunityStage: 'Champion Convinced',
      signals: [
        { signalId: '1', triggered: '1' },
        { signalId: '2', triggered: '0' },
        { signalId: '3', triggered: '2' },
      ],
    },
  ];
}

export function getSignalAnalyticsMockDataForNewSignal({ newSignal }) {
  return {
    id: newSignal.id,
    timeTriggered: 2407,
    closedWonsInfluenced: 88,
    uplift: 10,
    lastTriggered: new Date('11/01/2024'),
  };
}

export function getSignalSourceIconSrc({ value }) {
  const mapping = {
    [sourcesKeys.g2]: '/assets/signal-icons/G2Logo.svg',
    [sourcesKeys.sixSense]: '/assets/signal-icons/6SenseLogo.svg',
    [sourcesKeys.infinigrowAI]: '/assets/ai/purpleAIStars.svg',
    [sourcesKeys.infinigrow]: '/assets/signal-icons/infinigrowLogo.svg',
  };

  return mapping[value] || null;
}

export function getIntentStatusIconSrc({ value }) {
  const mapping = {
    [signalsPriorities.hot]: '/assets/signal-icons/fireIcon.svg',
    [signalsPriorities.cold]: '/assets/signal-icons/snowFlakeIcon.svg',
    [signalsPriorities.slipping]: '/assets/signal-icons/redArrowDownIcon.svg',
    [signalsPriorities.new]: '/assets/signal-icons/newIcon.svg',
  };

  return mapping[value] || null;
}

export function filterDataAccordingActiveColumns({ tableData, activeColumns }) {
  if (!tableData.length) {
    return tableData;
  }
  return tableData.map((dataRow) => {
    const filteredRowData = {};
    for (const column of activeColumns) {
      filteredRowData[column] = dataRow[column];
    }
    return filteredRowData;
  });
}

export function getSignalNameById({ mySignals, signalId }) {
  const currentSignal = mySignals.find((signal) => signal.id === signalId);
  return currentSignal ? currentSignal.name : '';
}

export function getMySignalsIds({ mySignals }) {
  return mySignals.map((signal) => signal.id);
}
