import moment from 'moment';
import { CoralogixRum } from '@coralogix/browser';

export class Coralogix {
  static async init({ config, userProfile, region }) {
    const userEmailLower = userProfile.email.toLowerCase();
    const sessionRecordingEnabled = config.sessionRecording.enabled && !config.sessionRecording.domainsToFilter.some((domain) => userEmailLower.includes(domain));

    CoralogixRum.init({
      public_key: config.publicKey,
      application: config.applicationName,
      version: process.env.version,
      coralogixDomain: config.coralogixDomain,
      sessionRecordingConfig: {
        enable: sessionRecordingEnabled,
        autoStartSessionRecording: true,
        recordConsoleEvents: true,
      },
      maskInputTypes: ['password'],
    });

    CoralogixRum.setUserContext({
      user_email: userProfile.email,
      user_name: userProfile.email,
      user_id: userProfile.user_id,
    });

    CoralogixRum.setLabels({
      ...CoralogixRum.getLabels(),
      UID: userProfile.UID,
      region,
    });
  }

  static sendCustomMetric({ metric, value, attributes }) {
    CoralogixRum.setLabels({
      ...CoralogixRum.getLabels(),
      ...attributes,
    });

    CoralogixRum.sendCustomMeasurement(metric, value);
  }

  static sendTimingCustomMetric({ metric, startTime, attributes }) {
    const totalSeconds = moment().diff(startTime, 'seconds', true);

    this.sendCustomMetric({ metric, value: totalSeconds, attributes });
  }
}
