import {
  get, isEmpty, cloneDeep, groupBy,
} from 'lodash';

import userStore from 'stores/userStore';

import { getFilterLabel, makeFilter } from 'components/utils/filters';
import { getChannelNickname } from 'components/utils/filters/channels';
import { filterKinds } from 'components/utils/filters/logic';
import { getChannelsWithNicknames } from 'components/utils/channels';
import { defaultFiltersByTabRules, filtersThatShouldShownOnAllTabs } from 'components/pages/users/Filters/logic/enums';
import { quickFiltersCreationTypes } from 'components/pages/settings/quickFilters/enums';
import { getFilterKind } from 'components/pages/users/Filters/logic/filterPopup';
import { filterTagsColorThemeOptions } from 'components/pages/users/Filters/FilterPanel/enums';

import aiIcon from 'assets/ai/purpleAIStars.svg';

export function getUpdatedQuickFilter({ filter, newSelectedOptions, filtersData }) {
  const filterKind = filter.kind;
  const config = filtersData.find((c) => c.kind === filterKind);
  const filterSelectedValues = newSelectedOptions.map((selectedOption) => selectedOption.value || selectedOption);

  const updatedQuickFilter = {
    ...filter,
    filter: {
      ...filter.filter,
      data: {
        ...filter.filter.data,
        selectedOptions: filterSelectedValues,
      },
      kind: filter.filter.kind || filter.filter.config.kind,
    },
  };

  let filterConfigs = filtersData;
  const savedFilters = userStore.userMonthPlan.savedFilters;
  if (!isEmpty(savedFilters)) {
    const savedFilter = {
      kind: filterKinds.SAVED,
      options: savedFilters.map((f) => get(f, 'alias', '')),
      fieldKey: 0,
    };
    filterConfigs = [...filterConfigs, savedFilter];
  }

  updatedQuickFilter.filter = makeFilter(cloneDeep(updatedQuickFilter.filter), filterConfigs);

  const filterToReturn = {
    ...updatedQuickFilter,
    filterSelectedOptions: newSelectedOptions,
  };

  const label = getFilterLabel({
    data: filterToReturn.filter.data,
    filterKind,
    filterFieldNames: config?.fieldNames || [],
    filterFieldKey: config?.fieldKey || '',
  }) || filter.name;

  return {
    ...filterToReturn,
    label,
  };
}

function getFilterOptions({ filterConfig, filterKind, filterData }) {
  const options = filterConfig.options || [];

  if (filterKind === filterKinds.FUNNEL_STAGES) {
    return options?.map((option) => ({ value: option, label: userStore.getMetricNickname({ metric: option, isSingular: true }) }));
  }

  if (filterKind === filterKinds.CHANNELS) {
    const fieldIndex = filterData.fieldIndex;
    const isCategory = fieldIndex === 1;
    const channelsWithNicknames = getChannelsWithNicknames();

    if (isCategory) {
      const categories = Object.keys(groupBy(channelsWithNicknames, (c) => c.category));
      return categories.map((item) => ({ value: item, label: item }));
    }
    return channelsWithNicknames.map((item) => ({ value: item.value, label: getChannelNickname(item.value) }));
  }
  return options.map((option) => ({ label: option, value: option }));
}

export function getQuickFiltersObjects({
  filters = [], filtersConfig,
}) {
  return filters.map((quickFilter) => {
    const filterType = quickFilter.type;
    const filterKind = getFilterKind({ kind: quickFilter.kind || get(quickFilter, 'filter.kind', '') });
    const filterData = get(quickFilter, 'filter.data', {});
    const config = filtersConfig.find((c) => c.kind === filterKind);
    const filterLabel = getFilterLabel({
      data: filterData,
      filterKind,
      filterFieldNames: config?.fieldNames || [],
      filterFieldKey: config?.fieldKey || '',
    }) || quickFilter.name;

    const filterOptions = getFilterOptions({ filterConfig: config, filterKind, filterData }) || [];

    const shouldUseOriginalTypeForUI = filterType !== quickFiltersCreationTypes.ai || quickFilter.id.includes('ai-recommendation');
    const typeUI = !shouldUseOriginalTypeForUI ? quickFiltersCreationTypes.manuallyCreated : filterType;
    const leftIcon = shouldUseOriginalTypeForUI && filterType === quickFiltersCreationTypes.ai
      ? <img src={aiIcon} alt="AI Filter" />
      : null;

    return {
      id: quickFilter.id,
      type: filterType,
      kind: filterKind,
      label: filterLabel,
      options: config.isSearchable ? [] : filterOptions,
      active: quickFilter.active ?? true,
      fieldIndex: filterData.fieldIndex,
      filter: quickFilter.filter,
      isQuickFilter: true,
      filterSelectedOptions: quickFilter.filterSelectedOptions || [],
      typeUI,
      leftIcon,
    };
  });
}

export function getQuickFiltersAndDefaultsByTabName({ filters, pathname }) {
  const defaultFilterRules = defaultFiltersByTabRules[pathname] || [];
  const resultFilters = [];

  for (const filter of filters) {
    const currentFilter = { ...filter };
    if (currentFilter.type === quickFiltersCreationTypes.default) {
      const hasSelectedOptions = currentFilter.filterSelectedOptions && currentFilter.filterSelectedOptions.length > 0;

      const isValidForPath = defaultFilterRules.some((rule) => rule.variant === currentFilter.filter.data.variant && rule.kind === currentFilter.kind);
      const shownOnAllTabs = filtersThatShouldShownOnAllTabs.some((rule) => rule.variant === currentFilter.filter.data.variant && rule.kind === currentFilter.kind);

      if (hasSelectedOptions && !isValidForPath && !shownOnAllTabs) {
        currentFilter.type = filterTagsColorThemeOptions.error;
        currentFilter.typeUI = filterTagsColorThemeOptions.error;
      }
      if (isValidForPath || hasSelectedOptions) {
        resultFilters.push(currentFilter);
      }
      continue;
    }
    resultFilters.push(currentFilter);
  }

  return resultFilters;
}

export function sortQuickFiltersByType({ quickFilters }) {
  quickFilters.sort((a, b) => {
    const typePriority = {
      [filterTagsColorThemeOptions.error]: 1,
      [quickFiltersCreationTypes.default]: 2,
      [quickFiltersCreationTypes.ai]: 3,
      [quickFiltersCreationTypes.manuallyCreated]: 4,
    };

    const aTypeRank = typePriority[a.typeUI] || 5;
    const bTypeRank = typePriority[b.typeUI] || 5;

    return aTypeRank - bTypeRank;
  });
}
