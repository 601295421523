import { tabsPaths } from 'enums';
import { filterKinds, VARIANTS } from 'components/utils/filters/logic';

export const savedFiltersSuccessMessages = 'Saved filter added!';

export const defaultFiltersByTabRules = {
  [tabsPaths.analyzeOverview]: [
    { variant: VARIANTS.INCLUDE_ANY_OF, kind: filterKinds.CHANNELS },
    { variant: VARIANTS.INCLUDE_ANY_OF, kind: filterKinds.CAMPAIGNS },
  ],
  [tabsPaths.channels]: [
    { variant: VARIANTS.INCLUDE_ANY_OF_EXACT, kind: filterKinds.CHANNELS },
  ],
  [tabsPaths.campaigns]: [
    { variant: VARIANTS.INCLUDE_ANY_OF_EXACT, kind: filterKinds.CHANNELS },
    { variant: VARIANTS.INCLUDE_ANY_OF_EXACT, kind: filterKinds.CAMPAIGNS },
  ],
  [tabsPaths.content]: [
    { variant: VARIANTS.INCLUDE_ANY_OF_EXACT, kind: filterKinds.CONTENT },
  ],
};

export const filtersThatShouldShownOnAllTabs = [
  ...defaultFiltersByTabRules[tabsPaths.analyzeOverview],
];
