import merge from 'lodash/merge';
import { igDomain, stagingDomain } from 'trackers/enums';

const config = {
  default: {
    coralogixRUM: {
      applicationName: 'local',
      publicKey: 'cxtp_oNZB49TtlM7mU3Ab0kNgdrInjqewdk',
      coralogixDomain: 'EU1',
      sessionRecording: {
        enabled: false,
        domainsToFilter: [],
      },
    },
    authClientId: 'En6sYxyCeCWBwHSORHGxVfBoNjWWp41c',
    authDomain: 'auth.infiqa.com',
    port: 8443,
    clientSideID: '626fd0ebae799e15c1e13b91',
    clientHostName: 'http://localhost:7272',
    authConnectionType: 'Username-Password-Authentication',
    logServer: {
      coralogixPrivateKey: 'cxtp_tqMJz0iSU1aAgo59od8OCWBdSFxBdK',
      subsystemName: 'InfiniGrow',
      coralogixUrl: 'https://api.coralogix.com/api/v1/logs',
      applicationName: 'front',
    },
    eventTracking: {
      sendEvents: false,
      domainsToFilter: [],
      applicationName: 'InfiniGrow',
    },
  },
  'app.infinigrow.com': {
    coralogixRUM: {
      applicationName: 'prod',
      sessionRecording: {
        enabled: true,
        domainsToFilter: [stagingDomain],
      },
    },
    authClientId: 'ZPLaIfv_lyA2N5PghXNjWSjah6aE1y9e',
    authDomain: 'auth.infinigrow.com',
    port: '',
    eventTracking: {
      sendEvents: true,
      domainsToFilter: [stagingDomain],
    },
    clientSideID: '626fd0ebae799e15c1e13b92',
    clientHostName: 'https://app.infinigrow.com',
  },
  'www.infiqa.com': {
    coralogixRUM: {
      applicationName: 'infiqa',
      sessionRecording: {
        enabled: true,
        domainsToFilter: [igDomain, stagingDomain],
      },
    },
    clientHostName: 'https://www.infiqa.com',
    port: '',
    eventTracking: {
      sendEvents: true,
      domainsToFilter: [igDomain, stagingDomain],
    },
  },
};

if (process.env.NODE_ENV === 'development') {
  config.localhost = {
    overrideURL: 'www.infiqa.com',
    overrideProtocol: 'https:',
    port: '',
  };
}

export default merge(config.default, config[window.location.hostname]);
