import base from './base';

function getWidgetConfig({ widgetConfig } = {}) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  const baseConfig = base.getWidgetConfig({ widgetConfig });
  delete baseConfig.hideOtherValuesRows;

  return baseConfig;
}

export default {
  ...base,
  getWidgetConfig,
  eventsWidgetName: 'Impact By Segment',
};
