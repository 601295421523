import React from 'react';
import Component from 'components/Component';
import CampaignsImportPopup from 'components/importCampaignsPopups/CampaignsImportPopup';
import { bingErrorMessages } from 'components/importCampaignsPopups/enums';

export default class BingCampaignsPopup extends Component {
  constructor(props) {
    super(props);
    this.campaignImportRef = React.createRef();
  }

  open = () => {
    this.campaignImportRef.current.open();
  };

  render() {
    let errorMessage = bingErrorMessages.noAccounts;
    const errorCode = localStorage.getItem('integrationError');
    if (errorCode) {
      errorMessage = bingErrorMessages.noPermissions;
    }

    return (
      <CampaignsImportPopup
        ref={this.campaignImportRef}
        api="bingadsapi"
        title="Choose Bing Account"
        platformTitle="Bing Ads"
        message={errorMessage}
        multi
        {...this.props}
      />
    );
  }
}
