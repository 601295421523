import moment from 'moment';
import {
  cloneDeep, get, groupBy, isFinite, mapValues, orderBy, uniq, isEmpty,
} from 'lodash';

import { Events } from 'trackers/analytics/enums';
import { formatBudget } from 'components/utils/budget';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import { mergeWithSum } from 'components/utils/utils';

import servicesStore from 'stores/servicesStore';

let XLSLib;

export const getXLSLib = async () => {
  if (XLSLib) {
    return Promise.resolve(XLSLib);
  } else {
    const { default: lib } = await import('xlsx');
    XLSLib = lib;
    return XLSLib;
  }
};

const monthlyTotal = (data, monthOrder) => monthOrder.reduce((accum, item, idx) => {
  const mergeArr = data.reduce((acc, { values }) => [...acc, values[idx]], []);
  accum.push(mergeWithSum(mergeArr));
  return accum;
}, []);

export const parseExportData = ({
  mapChannels, monthOrder, channelsArr, channelsPropsObject, userChannelsSchema = {},
}) => {
  const parsed = channelsArr
    .map((group, i) => mapChannels(group, i === 1, i === 2))
    .flat();

  const dataByTime = cloneDeep(parsed).reduce((acc, item) => {
    acc[item.time] = item.data;
    return acc;
  }, {});

  const names = uniq(parsed.map((m) => m.data.map((ch) => ch.channel)).flat());

  let tableData = names
    .map((channel) => ({
      channel,
      name: getChannelNickname(channel),
      erp: `${get(userChannelsSchema[channel], 'erpCategory', '')}`,
      category: channelsPropsObject[channel].category,
      values: monthOrder.map((mon) => {
        const monthData = dataByTime[mon].find((ch) => ch.channel === channel);
        const planned = get(monthData, 'planned', 0);
        const actual = get(monthData, 'actual', 0);
        return { planned, actual };
      }),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  tableData = orderBy(tableData, ['category', 'name'], ['asc', 'asc']);

  const totalRow = {
    values: monthlyTotal(tableData, monthOrder),
    category: 'Total',
    channel: ' ',
    name: 'Total',
    erp: ' ',
  };

  const groupedByCategory = mapValues(
    groupBy(tableData, (item) => item.category),
    (channelGroup) => monthlyTotal(channelGroup, monthOrder)
  );
  const tableDataClone = cloneDeep(tableData);

  let prevCategory;
  tableData.forEach((channel) => {
    const { category } = channel;
    if (category === prevCategory) {
      return;
    }
    prevCategory = category;
    const highestIndex = Math.max(...tableDataClone.map((ch, idx) => ((ch.category === category) ? idx : -1)));

    tableDataClone.splice(highestIndex + 1, 0, {
      values: groupedByCategory[category],
      category: '',
      channel: '',
      name: 'Total',
      erp: '',
    }, {});
  });

  tableDataClone.push(totalRow);
  return tableDataClone;
};

export const createSheet = (XLSX, tableData, monthOrderFull) => {
  const getTitles = (frame) => frame.map(() => ['Plan', 'Actual', 'Plan-Actual']).flat();
  const format = (v, withSign) => (isFinite(v) ? formatBudget(v, true, false, withSign) : v);

  const payload = tableData.reduce((resArr, ch) => {
    if (!ch.values) {
      resArr.push([['']]);
      return resArr;
    }
    const channelValues = ch.values
      .map(({ planned, actual }) => [format(planned), format(actual), format(planned - actual, true)])
      .flat();
    resArr.push(channelValues);
    return resArr;
  }, []);

  const rowTitles = tableData.map((ch) => [ch.category, ch.name, ch.erp]);
  const channelCategories = tableData.map((ch) => ch.category);

  const sheet = XLSX.utils.aoa_to_sheet([['']]);
  if (!sheet['!merges']) {
    sheet['!merges'] = [];
  }
  const headerColumns = monthOrderFull
    .map(({ isMonth, time }) => (isMonth ? moment(time, 'MMM YY').format('MMMM') : time));

  for (const mon of monthOrderFull) {
    const monIdx = monthOrderFull.indexOf(mon);
    const OFFSET = 4;
    const startIdx = OFFSET + monIdx * 3;
    if (mon.isFirst) {
      XLSX.utils.sheet_add_aoa(sheet, [[mon.quarter]], { origin: { r: 0, c: startIdx } });

      const qEndIndex = monthOrderFull.findIndex(({ isQuarter, time }) => isQuarter && time === mon.quarter);
      if (qEndIndex) {
        const endIndex = OFFSET + qEndIndex * 3 + 2;
        const mergeCells = { s: { r: 0, c: startIdx }, e: { r: 0, c: endIndex } };
        sheet['!merges'].push(mergeCells);
      }
    }
  }

  headerColumns.forEach((mon, monIdx) => {
    const col = 4 + monIdx * 3;
    const mergeCells = { s: { r: 1, c: col }, e: { r: 1, c: col + 2 } };
    sheet['!merges'].push(mergeCells);
    XLSX.utils.sheet_add_aoa(sheet, [[mon]], { origin: { r: 1, c: col } });
  });

  XLSX.utils.sheet_add_aoa(sheet, [['Category', 'Channel', 'ERP key', 'Note', ...getTitles(headerColumns)]], { origin: 'A3' });
  XLSX.utils.sheet_add_aoa(sheet, rowTitles, { origin: 'A4' });

  const uniqCategories = [];
  channelCategories.forEach((category, categoryIdx) => {
    const OFFSET = 3;
    if (category) {
      if (!uniqCategories.includes(category)) {
        const rows = channelCategories.map((cat, index) => (cat === category ? index : 0));
        const endRow = Math.max(...rows);
        const mergeCells = { s: { r: OFFSET + categoryIdx, c: 0 }, e: { r: OFFSET + endRow, c: 0 } };
        sheet['!merges'].push(mergeCells);
      }
      uniqCategories.push(category);
    }
  });

  XLSX.utils.sheet_add_aoa(sheet, payload, { origin: 'E4' });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet, 'Sheet 1');
  XLSX.writeFile(wb, 'plans-vs-actuals.xlsx');
};

export const createSheetAnalyze = ({
  XLSX, tableData, fileName = 'analyze-journeys', fileFormat = 'xlsx', widgetEventData = {},
}) => {
  const sheet = XLSX.utils.aoa_to_sheet([['']]);
  if (!sheet['!merges']) {
    sheet['!merges'] = [];
  }
  XLSX.utils.sheet_add_aoa(sheet, tableData, { origin: 'A1' });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet, 'Sheet 1');
  XLSX.writeFile(wb, `${fileName}.${fileFormat}`);

  if (!isEmpty(widgetEventData)) {
    servicesStore.eventTracker.track({
      eventName: Events.widgetExported,
      properties: widgetEventData,
    });
  }
};

export const processValidateData = (firstArr, secondArr) => {
  const lookupSecond = secondArr.reduce((acc, email) => {
    acc[email] = false;
    return acc;
  }, {});

  const resultingObj = {};
  firstArr.forEach((email) => {
    const isPresentInBothSets = lookupSecond[email] !== undefined;
    resultingObj[email] = isPresentInBothSets;
    if (isPresentInBothSets) {
      lookupSecond[email] = true;
    }
  });
  return [Object.entries(resultingObj), Object.entries(lookupSecond)];
};

export const createSheetValidate = (XLSX, data) => {
  const titles = data.length > 0 ? [Object.keys(data[0])] : '';
  const parsed = data.map((item) => Object.values(item).map((obj) => {
    if (Array.isArray(obj)) {
      return obj.flat().join(', ');
    } if (typeof obj === 'object') {
      return Object.entries(item).map((x) => x.join(': ')).join(', ');
    } else {
      return obj;
    }
  }));
  const sheet = XLSX.utils.aoa_to_sheet([['']]);
  if (!sheet['!merges']) {
    sheet['!merges'] = [];
  }
  XLSX.utils.sheet_add_aoa(sheet, titles, { origin: 'A1' });
  XLSX.utils.sheet_add_aoa(sheet, parsed, { origin: 'A2' });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet, 'Sheet 1');
  XLSX.writeFile(wb, 'validation-report.xlsx');
};

export const createSheetImpactDataTable = (XLSX, tableData, fileName) => {
  const wb = XLSX.utils.book_new();
  for (const tableSheet of tableData) {
    const sheet = XLSX.utils.aoa_to_sheet([['']]);
    XLSX.utils.sheet_add_aoa(sheet, tableSheet.data, { origin: 'A1' });
    const labelWithoutUnsupportedExcelChars = tableSheet.label.replace(/[\\/?*[\]]/g, '_');
    XLSX.utils.book_append_sheet(wb, sheet, labelWithoutUnsupportedExcelChars);
  }
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export function createSheetInspectDataTable({ XLSX, tableData, fileName }) {
  const wb = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet([['']]);
  XLSX.utils.sheet_add_aoa(sheet, tableData.data, { origin: 'A1' });
  XLSX.utils.book_append_sheet(wb, sheet, tableData.label);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}
