import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import WidgetHeader from 'components/common/WidgetHeader';
import InfiniteScrollTable from 'components/common/InfiniteScrollTable';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Uplift from 'components/common/Uplift';
import Skeleton from 'components/common/Skeleton';

import Dropdown from 'components/controls/Dropdown';
import TimeframeDropdown from 'components/common/TimeframeDropdown';
import {
  signalsColumnsLabels,
  signalsColumnsKeys,
  sourcesKeys,
  sourcesLabels,
  signalAnalyticsTitle,
  signalAnalyticsSubtitle,
  signalDateFormat, unclickableKpiFocusToOptionValue,
} from 'components/widgets/signals/enums';
import { skeletonSmallTableCellSizes } from 'components/common/enums';
import { tableDataWithFiltersByColumns, getColumnOptionsByData } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { getSignalSourceIconSrc } from 'components/widgets/signals/logic/signals';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { FUNNEL4 } from 'components/utils/indicators';

import iconXBlue from 'assets/x-blue.svg';
import style from 'styles/salesEnablement/salesEnablement.css';

const styles = style.locals || {};

function SignalAnalytics({
  data,
  isLoaded,
  timeFrame,
  setTimeFrame,
  metricsOptions,
  flags,
}) {
  useStyles([style]);

  const [tableData, setTableData] = useState(data);
  const [sortByColumn, setSortByColumn] = useState({});
  const [filtersByColumn, setFiltersByColumn] = useState({});
  const [kpiFocus, setKpiFocus] = useState(FUNNEL4);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  function updateKpiFocus({ newKpiFocus }) {
    for (const [unclickableKpiFocus, matchedOptionValue] of Object.entries(unclickableKpiFocusToOptionValue)) {
      if (matchedOptionValue === newKpiFocus.value && flags.salesEnablementUnclickableItems[unclickableKpiFocus]) {
        return;
      }
    }
    setKpiFocus(newKpiFocus.value);
  }

  function onUpdateFiltersByColumn({ filters }) {
    const filteredByColumnsItems = tableDataWithFiltersByColumns({ tableData: data, filtersByColumn: filters });
    setTableData(filteredByColumnsItems);
    setFiltersByColumn(filters);
  }

  function getTableColumnByKey({ columnKey }) {
    const newColumn = {
      id: columnKey,
      accessor: columnKey,
      header: signalsColumnsLabels[columnKey] || columnKey,
      inlineFiltersParams: {
        columnKey,
        selectedSegments: {},
        sortByColumn,
        filtersByColumn,
        setSortByColumn: ({ id, desc }) => setSortByColumn({ id, desc }),
        updateFiltersByColumn: ({ filters }) => onUpdateFiltersByColumn({ filters }),
        isNumeric: true,
      },
      cell: (cellItem) => {
        if (isLoaded || cellItem) {
          return (
            <>
              <EllipsisTooltip text={cellItem}>
                {cellItem}
              </EllipsisTooltip>
            </>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      },
    };

    if (columnKey === signalsColumnsKeys.signal) {
      const columnFiltersOptions = getColumnOptionsByData({
        selectedSegments: {}, tableData: data, columnKey,
      });
      newColumn.inlineFiltersParams = {
        ...newColumn.inlineFiltersParams,
        isNumeric: false,
        columnFiltersOptions,
      };
    }

    if (columnKey === signalsColumnsKeys.source) {
      const columnFiltersOptions = getColumnOptionsByData({
        selectedSegments: {}, tableData: data, columnKey,
      });
      newColumn.inlineFiltersParams = {
        ...newColumn.inlineFiltersParams,
        isNumeric: false,
        columnFiltersOptions,
      };
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          const signalSourceIconSrc = getSignalSourceIconSrc({ value: cellItem });
          const cellStyle = cellItem === sourcesKeys.infinigrowAI ? styles.ai : '';
          const sourceValue = sourcesLabels[cellItem];
          return (
            <div className={styles.cellWithIcon}>
              <img src={signalSourceIconSrc} alt={cellItem} />
              <EllipsisTooltip text={sourceValue} className={cellStyle}>
                {sourceValue}
              </EllipsisTooltip>
            </div>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      };
    }

    if (columnKey === signalsColumnsKeys.lastTriggered) {
      newColumn.inlineFiltersParams = null;
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          const date = moment(cellItem).format(signalDateFormat);
          return (
            <>
              <EllipsisTooltip text={date}>
                {date}
              </EllipsisTooltip>
            </>
          );
        }
        return <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />;
      };
    }

    if (columnKey === signalsColumnsKeys.uplift) {
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          return (
            <Uplift
              value={cellItem}
              maxValue={100}
              minValue={-20}
            />
          );
        }
        return <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />;
      };
    }

    return newColumn;
  }

  const tableDataKeys = Object.keys(signalsColumnsKeys);
  const tableColumns = tableDataKeys.map((columnKey) => getTableColumnByKey({ columnKey }));

  return (
    <div>
      <WidgetHeader
        widgetHeaderConfig={{
          title: signalAnalyticsTitle,
          subTitle: signalAnalyticsSubtitle,
        }}
        classNameWidgetTitle={styles.salesEnablementWidgetTitle}
        isHideAddToReport
      >
        <TimeframeDropdown
          classNamePopup={styles.timeframeDropdownPopup}
          isShowPopupTitle={false}
          titlePrefix="Timeframe: "
          onChange={(newTimeFrame) => (flags.salesEnablementDemoOnly && newTimeFrame.value !== TIMEFRAME_VALUES.ROLLING ? null : setTimeFrame(newTimeFrame.value, newTimeFrame))}
          timeframe={timeFrame}
        />
        <Dropdown
          selectedKey={kpiFocus}
          options={metricsOptions}
          onChange={(newKpiFocus) => updateKpiFocus({ newKpiFocus })}
          dropdownLabel="Focus KPI"
        />
        <Button
          icon={iconXBlue}
          iconClassName={styles.plusIcon}
          type="secondaryBlue"
        >
          Add filter
        </Button>
      </WidgetHeader>
      <InfiniteScrollTable
        tableData={tableData}
        tableColumns={tableColumns}
        isLoaded={isLoaded}
        sortByColumn={sortByColumn}
      />
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    attributionStore: {
      timeFrame,
      setTimeFrame,
      metricsOptions,
    },
  }) => ({
    timeFrame,
    setTimeFrame,
    metricsOptions,
  }),
  observer
)(SignalAnalytics));
