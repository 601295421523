export const tabsPaths = {
  aiPrompts: '/insights/ai-prompts',
  insights: '/insights/overview',
  channels: '/analyze/channels',
  campaigns: '/analyze/campaigns',
  content: '/analyze/content',
  segments: '/analyze/segments',
  whatIf: '/analyze/what-if',
  optimalJourney: '/analyze/optimal-journey',
  analyzeOverview: '/analyze/overview',
  analyzeJourneys: '/analyze/journeys',
  oppsQuickFilters: '/settings/ops/quickFilters',
  reports: '/reports',
  dashboard: '/dashboard/navigate',
  gettingStarted: '/getting-started',
  questionsResults: '/questions/results',
  answers: '/answers',
  reportsNew: '/reports/new',
  widgetBuilder: '/widget-builder',
};

export const tabsToFeatureFlags = {
  [tabsPaths.aiPrompts]: 'aiPromptTab',
  [tabsPaths.campaigns]: 'headerCampaignsTab',
  [tabsPaths.whatIf]: 'whatIfAnalyzeTab',
  [tabsPaths.optimalJourney]: 'optimalJourneysAnalyzeTab',
  [tabsPaths.oppsQuickFilters]: 'quickFiltersOppsPage',
};

export const tabsIds = {
  dashboard: 'dashboard',
  analyze: 'analyze',
  reports: 'reports',
  insights: 'insights',
  plan: 'plan',
  ops: 'ops',
  settings: 'settings',
  gettingStarted: 'gettingStarted',
  answers: 'answers',
  salesEnablement: 'sales-enablement',
};

export const customMetricsNames = {
  performancePerWidget: 'performance_per_widget',
  performancePerWidgetEssentialData: 'performance_per_widget_essential_data',
};

export const pageLabels = {
  analyze: 'Analyze',
  reports: 'Reports',
  plan: 'Plan',
  ops: 'Ops',
  settings: 'Settings',
  channels: 'Channels',
  campaigns: 'Campaigns',
  content: 'Content',
  segments: 'Segments',
  overview: 'Overview',
  journeys: 'Journeys',
  whatIf: 'What If',
  optimalJourney: 'Optimal Journey',
  workflows: 'Workflows',
  customSegments: 'Custom Segments',
  unmapped: 'Unmapped Attribution',
  alerts: 'Alerts',
  quickFilters: 'Quick Filters',
  salesEnablement: 'Sales Enablement',
  intentSignals: 'Intent Signals',
  accountPrioritization: 'Account Prioritization',
};
