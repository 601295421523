import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { formatBudget } from 'components/utils/budget';
import { isNil } from 'lodash';

export function calculateUpliftBarsWidth({ value = 0, minValue = 0, maxValue = 0 }) {
  const isPositiveValue = value >= 0;

  const minValueAbs = Math.abs(minValue);
  const maxValueAbs = Math.abs(maxValue);
  const totalRange = minValueAbs + maxValueAbs;

  const negativeBarWrapperWidth = minValueAbs === 0 ? 0 : (minValueAbs / totalRange) * 100;

  const valueAbs = Math.abs(value);
  let negativeBarWidth = 0;
  let positiveBarWidth = 0;

  if (!isPositiveValue && minValueAbs !== 0) {
    negativeBarWidth = Number(((valueAbs / minValueAbs) * 100).toFixed(2));
  }

  if (isPositiveValue && maxValueAbs !== 0) {
    positiveBarWidth = Number(((valueAbs / maxValueAbs) * 100).toFixed(2));
  }

  return {
    negativeBarWrapperWidth,
    negativeBarWidth,
    positiveBarWidth,
  };
}

export const getUpliftValueAsText = ({ value, upliftRangeLimit = {} }) => {
  if (isNil(value)) {
    return 'N/A';
  }

  if (value >= upliftRangeLimit.max) {
    return `${formatBudget(value, false, true)}% >`;
  }

  if (value <= upliftRangeLimit.min) {
    return `< -${formatBudget(value, false, true)}%`;
  }

  return `${value > 0 ? '+' : ''}${formatNumberWithDecimalPoint(value, 2)}%`;
};
