import React, { useEffect } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import EmptyWidgets from 'components/pages/reports/EmptyWidgets';
import FeatureFlags from 'components/common/FeatureFlags';
import servicesStore from 'stores/servicesStore';

import { Events } from 'trackers/analytics/enums';
import { getComponentAccordingWidgetType, getComponentProps } from 'components/pages/reports/logic/reports';
import { widgetsClassWidthByType } from 'components/pages/analyze/enums';
import { getQueryParams } from 'components/utils/UrlParamsProvider';
import {
  reportIdParam,
  reportTemplateTypes,
} from 'components/pages/reports/enums';

import appStyle from 'styles/app.css';
import pageStyle from 'styles/page.css';
import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals;

function Report({
  reportsList,
  selectedReport,
  setSelectedReport,
  isFilterDataLoaded,
  getUserFiltersData,
  widgetsOfSelectedReport,
  reorderReportWidgets,
  flags,
  isOnboarding,
}) {
  const reportIdFromURL = getQueryParams({ queryParamKey: reportIdParam });
  const reportFromUrl = reportsList.find((report) => report.value === reportIdFromURL);

  useStyles([appStyle, pageStyle, reportsStyle]);

  useEffect(() => {
    if (!isFilterDataLoaded) {
      getUserFiltersData();
    }

    if (reportFromUrl && (reportFromUrl.value !== selectedReport.value)) {
      setSelectedReport(reportFromUrl);
    }

    servicesStore.eventTracker.track({
      eventName: Events.reportsReportViewed,
      properties: {
        source: reportFromUrl.sourceType || reportTemplateTypes.scratch,
        template: reportFromUrl.templateName || 'none',
        isOnboarding,
      },
    });
  }, []);

  if (widgetsOfSelectedReport.length === 0) {
    return (
      <EmptyWidgets />
    );
  }

  return (
    <DragDropContext
      onDragEnd={({
        source,
        destination,
      }) => {
        reorderReportWidgets({
          from: source.index,
          to: destination.index,
        });
      }}
    >
      <Droppable droppableId="report-widgets">
        {({ innerRef: droppableRef, droppableProps, placeholder }) => (
          <div
            ref={droppableRef}
            className={classNames(pageStyle.locals.container, pageStyle.locals.analyzeContainer, styles.reportContainer)}
            {...droppableProps}
          >
            {widgetsOfSelectedReport.length > 0
                && widgetsOfSelectedReport.map((widget, index) => {
                  const widgetProps = getComponentProps({
                    widgetData: widget, reportGlobalConfiguration: selectedReport, isInReports: true, isShowTimeframeSelect: true,
                  });
                  const WidgetComponent = getComponentAccordingWidgetType({ widgetType: widget.type });
                  const currentWidgetsClassWidth = widgetsClassWidthByType[widget.type];
                  return (
                    <Draggable
                      key={`widget-${widget.type}-${widget.id}`}
                      draggableId={widget.id}
                      index={index}
                    >
                      {({
                        innerRef: draggableRef,
                        draggableProps,
                        dragHandleProps,
                      }) => (
                        <div
                          ref={draggableRef}
                          {...draggableProps}
                          className={classNames(styles.widgetDraggableWrapper, currentWidgetsClassWidth ? styles[currentWidgetsClassWidth] : null)}
                        >
                          <div
                            {...dragHandleProps}
                            className={styles.widgetDragHandle}
                          />
                          <WidgetComponent {...widgetProps} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
            {placeholder}

            <FeatureFlags flag={flags.reportsWidgetBuilderButton}>
              <Link className={styles.linkButton} to={`/widget-builder?reportId=${reportIdFromURL}`}>
                <div className={styles.createNewWidget}>
                  + Create a new widget
                </div>
              </Link>
            </FeatureFlags>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default withLDConsumer()(inject(
  ({
    reportsStore: {
      reportsList,
      selectedReport,
      setSelectedReport,
      widgetsOfSelectedReport,
      reorderReportWidgets,
    },
    filterStore: {
      isFilterDataLoaded,
      getUserFiltersData,
    },
  }) => ({
    reportsList,
    selectedReport,
    setSelectedReport,
    isFilterDataLoaded,
    getUserFiltersData,
    widgetsOfSelectedReport,
    reorderReportWidgets,
  }),
  observer
)(Report));
