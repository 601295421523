exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".Page404__wrapperBody-3CW{display:flex;flex-direction:column;gap:20px;justify-content:center;align-items:center;height:calc(100vh - 190px);font-size:16px;color:var(--colorsGeneralIgBlack)}.Page404__tonyIcon-1FI{width:272px;height:328px;margin-bottom:24px}.Page404__row-16S{display:flex;gap:4px}.Page404__footer-1u_{text-align:center;display:flex;flex-direction:column;gap:20px;align-items:center}.Page404__wrapperBody-3CW a{color:var(--blue);text-decoration:underline}.Page404__wrapperBody-3CW a:hover{text-decoration:none}.Page404__wrapperBody-3CW a:visited{color:var(--blue)}", ""]);

// exports
exports.locals = {
	"wrapperBody": "Page404__wrapperBody-3CW",
	"wrapperBody": "Page404__wrapperBody-3CW",
	"tonyIcon": "Page404__tonyIcon-1FI",
	"tonyIcon": "Page404__tonyIcon-1FI",
	"row": "Page404__row-16S",
	"row": "Page404__row-16S",
	"footer": "Page404__footer-1u_",
	"footer": "Page404__footer-1u_"
};