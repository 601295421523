import React, { useMemo, useState } from 'react';
import history from 'history';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ActionPopup } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Textfield from 'components/controls/Textfield';
import ButtonWithOptions from 'components/common/ButtonWithOptions';
import CustomFiltersPanel from 'components/pages/users/Filters/CustomFiltersPanel';
import QuickFiltersPanel from 'components/pages/users/Filters/QuickFiltersPanel';
import FeatureFlags from 'components/common/FeatureFlags';

import { getUniqueTitle } from 'components/utils/logic/utils';
import { savedFiltersSuccessMessages } from 'components/pages/users/Filters/logic/enums';
import { filterButtonPositions } from 'components/pages/users/Filters/FilterPanel/enums';

import style from 'styles/quickFilters/quickFilters.css';

const styles = style.locals || {};

function FiltersContainerBar({
  appliedCustomFilters,
  numberOfFilters,
  filters,
  setFilters,
  setQuickFilters,
  savedFilters,
  updateSavedFilters,
  flags,
}) {
  useStyles([style], [injectStyle]);

  const [isShowSaveFilterPopup, setIsShowSaveFilterPopup] = useState(false);
  const [savedFiltersTitle, setSavedFiltersTitle] = useState(getUniqueTitle({ baseTitle: 'New Saved Filter', listOfNames: savedFilters.map((filter) => filter.alias) }));

  const filtersActionMenuOptions = useMemo(() => {
    const options = [{
      label: 'Edit quick filters',
      action: () => history.push('/settings/ops/quickFilters'),
    }];

    if (numberOfFilters > 0) {
      options.unshift({
        label: 'Save filter combinations',
        action: () => setIsShowSaveFilterPopup(true),
      }, {
        label: 'Clear all filters',
        action: () => {
          setFilters([]);
          setQuickFilters([]);
        },
      });
    }
    return options;
  }, [numberOfFilters]);

  async function onConfirmSaveFilter() {
    await updateSavedFilters({ filters, alias: savedFiltersTitle });
    toast.success(savedFiltersSuccessMessages);
    setIsShowSaveFilterPopup(false);
    setSavedFiltersTitle('');
  }

  return (
    <>
      <div className={styles.filtersContainer}>
        <FeatureFlags flag={flags.quickFiltersOppsPage}>
          <QuickFiltersPanel />
        </FeatureFlags>
        <CustomFiltersPanel
          filters={appliedCustomFilters}
          setFilters={setFilters}
          buttonPosition={filterButtonPositions.end}
          shouldShowClearAll={false}
        />
      </div>
      <ButtonWithOptions
        options={filtersActionMenuOptions}
        renderMenuButton={() => <div className={styles.filterActionMenu} />}
        dataTestId="filter-action-menu"
      />

      {isShowSaveFilterPopup ? (
        <ActionPopup
          onCancel={() => setIsShowSaveFilterPopup(false)}
          width="360px"
          title="Name Saved Filter"
          confirmButtonDisabled={!savedFiltersTitle || savedFiltersTitle === ''}
          onConfirm={() => onConfirmSaveFilter()}
        >
          <Textfield
            onChange={(event) => setSavedFiltersTitle(event.target.value)}
            value={savedFiltersTitle}
            dataTestId="saved-filter-input"
          />
        </ActionPopup>
      ) : null}
    </>
  );
}

export default withLDConsumer()(inject(
  ({
    attributionStore: {
      appliedCustomFilters,
      filters,
      setFilters,
      setQuickFilters,
      numberOfFilters,
    },
    filterStore: {
      updateSavedFilters,
    },
    userStore: {
      userMonthPlan: {
        savedFilters,
      },
    },
  }) => ({
    savedFilters,
    updateSavedFilters,
    appliedCustomFilters,
    filters,
    setFilters,
    setQuickFilters,
    numberOfFilters,
  }),
  observer
)(FiltersContainerBar));
