import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import BetaTag from 'components/common/BetaTag';

import { isActiveInChildRoutes } from 'components/navigationMenu/logic/navigationTierItems';
import { subMenuTabsNamesWithBetaTag } from 'components/enums';

import style from 'styles/navigationMenu/sideBarNavigationMenu.css';
import { tabsToFeatureFlags } from 'enums';

const styles = style.locals || {};

function TierItem({
  tierData, activeTier, setActiveTier, hidden, flags,
}) {
  if (hidden) {
    return null;
  }

  const hasChildRoutes = tierData.childRoutes?.length > 0;
  const shouldShowSubTier = activeTier === tierData.tabName && hasChildRoutes;
  const hasActiveSubTier = isActiveInChildRoutes({ childRoutes: tierData.childRoutes, activeTier });

  return (
    <>
      <Link
        to={tierData.path}
        activeClassName={styles.menuSelectedItem}
        className={styles.subMenuItem}
        key={tierData.path}
        onlyActiveOnIndex
        onClick={() => setActiveTier(shouldShowSubTier ? null : tierData.tabName)}
      >
        <div className={styles.showWhenMenuIsOpen}>
          <div className={styles.tierName}>
            {tierData.tabName}
            <BetaTag
              dataTestId={`beta-tag-${tierData.tabName}`}
              hidden={!subMenuTabsNamesWithBetaTag.includes(tierData.tabName)}
            />
          </div>
          {hasChildRoutes ? (
            <div className={classnames(styles.arrowDown, shouldShowSubTier && styles.arrowUp)} />
          ) : null}
        </div>

      </Link>

      {hasChildRoutes ? (
        <div className={classnames(styles.subMenuHide, (shouldShowSubTier || hasActiveSubTier) && styles.subMenuShow)}>
          <NavigationTierItems
            tierRoutes={tierData.childRoutes}
            activeTier={activeTier}
            setActiveTier={setActiveTier}
            flags={flags}
          />
        </div>
      ) : null}
    </>
  );
}

function NavigationTierItems({
  tierRoutes, activeTier, setActiveTier, flags = {},
}) {
  useStyles([style]);

  return (
    <div className={styles.tierGroup}>
      {tierRoutes.map((tier, index) => {
        const tierFlag = tabsToFeatureFlags[tier?.path];
        const shouldShowTier = tierFlag ? flags[tierFlag] : true;
        const tierKey = `tierItem-${index}-${tier.tabName}-${tier.path}`;
        return (
          <TierItem
            tierData={tier}
            key={tierKey}
            activeTier={activeTier}
            setActiveTier={setActiveTier}
            hidden={!shouldShowTier}
            flags={flags}
          />
        );
      })}
    </div>
  );
}

export default withLDConsumer()(NavigationTierItems);
