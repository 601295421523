import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';

import AttributionTable from 'components/pages/analyze/AttribuitonTable/AttributionTable';
import timeFrameModule from 'modules/timeframe';
import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';

import { ATTRIBUTION_TYPES } from 'components/utils/utils';
import { getSegmentsContentOptionsFields, getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { getFilteredSegmentAnalysisRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { parseWidgetFiltersToFiltersConfig } from 'components/pages/reports/logic/reports';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';
import { segmentsTimeOptions } from 'components/pages/analyze/AttribuitonTable/enums';

function AttributionTableSegmentsWrapper({
  widgetConfig,
  conversionIndicator,
  segmentsAnalysis,
  getWidgetRequestId,
  updateSegmentsAnalysisParams,
  widgetHeaderConfig,
  segmentsAnalysisParams,
  customFieldsIdToLabelMap = {},
  customUtmsWhitelist,
  selectedReport,
  hideNoValuesRows,
  trendBySegments,
  accountOptimizationMetrics,
  updateReportWidgetColumns,
  updateWidgetFilters,
  defaultStageKey,
  isPrimarySegmentDisable,
  isShowStageSelector = false,
  isCompareToPreviousEnabled,
  tableTypeName,
  widgetHeaderProps,
  isCalculateAdvancedMetrics = attributionStore.isCalculateAdvancedMetrics,
  sortByColumn,
  filtersByColumn,
  updateWidgetInlineFiltersByColumn,
  isShowWidgetHeader,
  isEnableSkeletonAnimation = true,
  updateWidgetPropsValues,
  isEnableNavigationMenu,
  isLoaded,
  updateReportWidgetDefaultStageKey,
  emptyStateComponent,
  isOtherCampaignsHidden,
  updateWidgetConfig,
  upliftBySegmentsParams,
  upliftBySegments,
}) {
  const [hideNoValuesRowsState, setHideNoValuesRowsState] = useState(hideNoValuesRows);
  const reportId = selectedReport.value;
  const { widgetId } = widgetHeaderConfig;

  const timeFrameParams = timeFrameModule.getTimeframeParams({ ...widgetHeaderConfig.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
  const { startDate, endDate } = timeFrameParams;

  const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
  const upliftBySegmentsRequestId = getWidgetRequestId({ widget: widgetTypes.upliftBySegments, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });

  const updateSegmentsAnalysisParam = (newSegmentsAnalysisParams) => {
    updateSegmentsAnalysisParams({ reportId, widgetId, segmentsAnalysisParams: newSegmentsAnalysisParams });
  };

  function updateWidgetColumns({
    reorderColumns,
    inactiveColumns,
  }) {
    updateReportWidgetColumns({
      reportId,
      widgetId,
      reorderColumns,
      inactiveColumns,
    });
  }

  function onUpdateDefaultStageKey({ stageKey }) {
    updateReportWidgetDefaultStageKey({ reportId, widgetId, stageKey });
  }

  function onUpdateWidgetInlineFiltersByColumn({ sortBy, filtersBy }) {
    if (updateWidgetPropsValues) {
      updateWidgetPropsValues({ values: { sortByColumn: sortBy, filtersByColumn: filtersBy } });
      return;
    }
    if (!widgetHeaderConfig.isReadOnly) {
      updateWidgetInlineFiltersByColumn({
        sortBy, filtersBy, reportId, widgetId,
      });
    }
  }

  const filters = parseWidgetFiltersToFiltersConfig(widgetHeaderConfig.filters);

  const segmentsAnalysisTableData = {};
  const segmentAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};
  for (const [stageName, stageData] of Object.entries(segmentAnalysisData)) {
    const filteredData = getFilteredSegmentAnalysisRecords({ data: stageData, filters, segments: segmentsAnalysisParams });
    segmentsAnalysisTableData[stageName] = filteredData;
  }

  const mergedFunnelsSegmentsAnalysisTableData = getFilteredSegmentAnalysisRecords({
    data: segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.mergedFunnelsTableData ?? [],
    filters,
    segments: segmentsAnalysisParams,
  });

  const stagesAmount = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.countPerStage ?? {};

  let segmentsOptionsFields = [];
  let secondSegmentsOptionsFields = null;
  if (tableTypeName === ATTRIBUTION_TYPES.CONTENT) {
    segmentsOptionsFields = getSegmentsContentOptionsFields();
    secondSegmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
  } else {
    segmentsOptionsFields = [...getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist }), ...segmentsTimeOptions];
  }

  const upliftBySegmentsData = upliftBySegments?.[upliftBySegmentsRequestId]?.result?.data;

  const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
  const isSegmentAnalysisFailedToLoad = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'failed';
  const isUpliftBySegmentsLoaded = upliftBySegments?.[upliftBySegmentsRequestId]?.status === 'finished';

  const widgetColumnsConfig = {
    reorderColumns: cloneDeep(widgetConfig.reorderColumns),
    inactiveColumns: cloneDeep(widgetConfig.inactiveColumns),
  };

  return (
    <AttributionTable
      isShowHideNoValue
      onChangeHideNoValuesRows={() => setHideNoValuesRowsState((prevState) => !prevState)}
      hideNoValuesRows={hideNoValuesRowsState}
      segmentsOptionsFields={segmentsOptionsFields}
      secondSegmentsOptionsFields={secondSegmentsOptionsFields}
      key={conversionIndicator}
      isLoaded={isSegmentAnalysisLoaded}
      type={ATTRIBUTION_TYPES.OVERVIEW}
      title="Segment Analysis"
      defaultStageKey={defaultStageKey || conversionIndicator}
      selectedStageKey={defaultStageKey || conversionIndicator}
      dataNickname="Segment Analysis"
      updateSegmentsAnalysisParam={updateSegmentsAnalysisParam}
      trendData={trendBySegments}
      isLoadedTrendData={isLoaded}
      data={segmentsAnalysisTableData}
      mergedFunnelsTableData={mergedFunnelsSegmentsAnalysisTableData}
      prevData={{}}
      widgetHeaderConfig={widgetHeaderConfig}
      segmentsAnalysisParams={segmentsAnalysisParams}
      timeframe={{
        ...widgetHeaderConfig.timeFrame, startDate, endDate,
      }}
      dataTotals={accountOptimizationMetrics ?? {}}
      trendDataTotals={trendBySegments}
      innerPageNavigation={({ filters: newFilters, isNewFiltersUIOnly }) => {
        updateWidgetFilters({
          reportId, widgetId, filters: newFilters, isNewFiltersUIOnly,
        });
      }}
      isPrimarySegmentDisable={isPrimarySegmentDisable}
      isShowStageSelector={isShowStageSelector}
      filters={filters}
      attributionModel={widgetHeaderConfig.attributionModel}
      stagesAmount={stagesAmount}
      isSegmentAnalysisFailedToLoad={isSegmentAnalysisFailedToLoad}
      isAttribution={segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution}
      updateWidgetColumns={({ reorderColumns, inactiveColumns }) => updateWidgetColumns({ reorderColumns, inactiveColumns })}
      widgetColumnsConfig={widgetColumnsConfig}
      isCompareToPreviousEnabled={isCompareToPreviousEnabled}
      isReadOnly={widgetHeaderConfig.isReadOnly}
      widgetHeaderProps={widgetHeaderProps}
      isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
      exportMode
      sortByColumn={sortByColumn}
      filtersByColumn={filtersByColumn}
      updateWidgetInlineFiltersByColumn={({ sortBy, filtersBy }) => onUpdateWidgetInlineFiltersByColumn({ sortBy, filtersBy })}
      isShowWidgetHeader={isShowWidgetHeader}
      isEnableSkeletonAnimation={isEnableSkeletonAnimation}
      isEnableNavigationMenu={isEnableNavigationMenu}
      updateDefaultStageKey={({ stageKey }) => onUpdateDefaultStageKey({ stageKey })}
      emptyStateComponent={emptyStateComponent}
      isOtherCampaignsHidden={isOtherCampaignsHidden}
      isShowHideOtherCampaigns
      onChangeHideOtherCampaigns={() => updateWidgetConfig({
        configKey: 'isOtherCampaignsHidden', configValue: !isOtherCampaignsHidden, reportId, widgetId, shouldPullDataFromServer: false,
      })}
      upliftColumnsKeys={upliftBySegmentsParams?.selectedMetrics}
      updatedUpliftColumnsKeys={widgetHeaderConfig.isReadOnly ? null : ({ columnsKeys }) => updateWidgetConfig({
        configKey: 'upliftBySegmentsParams',
        configValue: {
          ...upliftBySegmentsParams,
          selectedMetrics: columnsKeys,
        },
        reportId,
        widgetId,
      })}
      upliftData={upliftBySegmentsData}
      isLoadedUpliftData={isUpliftBySegmentsLoaded}
    />
  );
}

AttributionTableSegmentsWrapper.propTypes = {
  conversionIndicator: PropTypes.string,
  segmentsAnalysis: PropTypes.object,
  segmentsAnalysisParams: PropTypes.object,
};

AttributionTableSegmentsWrapper.defaultProps = {
  conversionIndicator: '',
  segmentsAnalysis: {},
  segmentsAnalysisParams: {},
};

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
    },
    reportsStore: {
      updateSegmentsAnalysisParams,
      selectedReport,
      updateReportWidgetColumns,
      updateWidgetFilters,
      updateWidgetInlineFiltersByColumn,
      updateReportWidgetDefaultStageKey,
      updateWidgetConfig,
      updatedUpliftColumnsKeys,
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
    },
  }) => ({
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
    updateSegmentsAnalysisParams,
    selectedReport,
    updateReportWidgetColumns,
    updateWidgetFilters,
    segmentsAnalysis,
    getWidgetRequestId,
    updateWidgetInlineFiltersByColumn,
    updateReportWidgetDefaultStageKey,
    updateWidgetConfig,
    updatedUpliftColumnsKeys,
    upliftBySegments,
  }),
  observer
)(AttributionTableSegmentsWrapper);
