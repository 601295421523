exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".questions__wrapper-2rF{display:inline-block;min-height:calc(100vh - 180px);width:100%}.questions__askQuestionWrapper-vyo{margin:80px auto;display:flex;justify-content:center}.questions__askQuestion-1ls{width:800px;display:flex;gap:12px;flex-direction:column}.questions__questionInput-W0k{border-radius:30px;border:1px solid #dce1ef;width:calc(100% - 68px);padding:20px 20px 20px 48px;font-size:14px;color:#242a3f;background:#fff url(/assets/input-search.svg) 20px no-repeat}.questions__questionInput-W0k .questions__active-2pI,.questions__questionInput-W0k:focus{outline:none;border:1px solid #4d91fc;background:#fff url(/assets/questions/blue-search.svg) 20px no-repeat}.questions__questionResultsWrapper-1Ng{border-radius:20px;background:#fff;box-shadow:0 4px 24px 0 rgba(36,42,64,.06);padding:16px 0}.questions__closeIcon-sZu{width:12px;height:12px;background:url(/assets/close-icon.svg) 50% no-repeat;position:absolute;right:20px;top:50%;transform:translateY(-50%);cursor:pointer}.questions__askQuestionInputWrapper-3TK{position:relative}", ""]);

// exports
exports.locals = {
	"wrapper": "questions__wrapper-2rF",
	"wrapper": "questions__wrapper-2rF",
	"askQuestionWrapper": "questions__askQuestionWrapper-vyo",
	"askQuestionWrapper": "questions__askQuestionWrapper-vyo",
	"askQuestion": "questions__askQuestion-1ls",
	"askQuestion": "questions__askQuestion-1ls",
	"questionInput": "questions__questionInput-W0k",
	"questionInput": "questions__questionInput-W0k",
	"active": "questions__active-2pI",
	"active": "questions__active-2pI",
	"questionResultsWrapper": "questions__questionResultsWrapper-1Ng",
	"questionResultsWrapper": "questions__questionResultsWrapper-1Ng",
	"closeIcon": "questions__closeIcon-sZu",
	"closeIcon": "questions__closeIcon-sZu",
	"askQuestionInputWrapper": "questions__askQuestionInputWrapper-3TK",
	"askQuestionInputWrapper": "questions__askQuestionInputWrapper-3TK"
};