import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import {
  isEqual, isEmpty, get, cloneDeep, merge,
} from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import AnnualTab from 'components/pages/plan/AnnualTab';
import CampaignTagsOptions from 'components/pages/plan/campaignPopups/CampaignTagsOptions';
import CampaignTag from 'components/pages/plan/campaignPopups/CampaignTag';
import Component from 'components/Component';
import FeatureFlags from 'components/common/FeatureFlags';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import Loader from 'components/controls/Loader';
import Page from 'components/Page';
import PlanButton from 'components/pages/plan/PlanButton';
import PlanControls from 'components/pages/plan/PlanControls';
import Switch from 'components/controls/Switch';
import Toggle from 'components/controls/Toggle';
import events from 'data/events';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { EXTRA_CHANNEL } from 'components/pages/PlannedVsActual';
import {
  PACING_METHODS, getRegionsToUpdate, isMasterRegion, compose,
} from 'components/utils/utils';
import { amountTypes, generalCampaignName } from 'stores/plan/logic/enums';
import { budgetTypeMapping, fiscalData, getCurrentFiscalYear } from 'components/utils/date';
import { disablePopupMode, isPopupMode } from 'modules/popup-mode';
import { getChannelsWithProps } from 'components/utils/channels';
import { pageLabels } from 'enums';

import style from 'styles/plan/plan.css';
import pageStyle from 'styles/page.css';

const enhance = compose(
  inject((stores) => {
    const {
      planStore,
      forecastStore,
    } = stores;
    const {
      channelsMonthlyBudgets,
      campaignsMonthlyBudgets,
      updateCampaignAmount,
      getCampaignsAndChannelBudgetsGroupedByMonth,
      getCampaignsAndChannelBudgetsGroupedByMonthMultipleRegions,
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      updateCampaignProperties,
      resetCampaignsToUpdate,
      setChannelsMonthlyBudgets,
      getAllCampaignTags,
      campaignTags,
      updateSelectedCampaignTags,
      selectedCampaignTags,
      removeFromSelectedCampaignTags,
      isBudgetLoadingFromServer,
    } = planStore;
    const {
      formattedGraphExplainableForecast,
      resetFormattedGraphExplainableAlternativeScenarioForecast,
      getExplainableForecastData,
      getAlternativeScenarioExplainableForecastData,
    } = forecastStore;
    return {
      formattedGraphExplainableForecast,
      channelsMonthlyBudgets,
      campaignsMonthlyBudgets,
      getCampaignsAndChannelBudgetsGroupedByMonth,
      getCampaignsAndChannelBudgetsGroupedByMonthMultipleRegions,
      updateCampaignAmount,
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      updateCampaignProperties,
      resetCampaignsToUpdate,
      setChannelsMonthlyBudgets,
      resetFormattedGraphExplainableAlternativeScenarioForecast,
      getExplainableForecastData,
      getAlternativeScenarioExplainableForecastData,
      getAllCampaignTags,
      campaignTags,
      updateSelectedCampaignTags,
      selectedCampaignTags,
      removeFromSelectedCampaignTags,
      isBudgetLoadingFromServer,
    };
  }),
  observer
);

class Plan extends Component {
  style = style;

  styles = [pageStyle];

  tempBudgetsData = null;

  static defaultProps = {
    budgetType: budgetTypeMapping.fiscalCurrent,
    userProfile: {},
    targetAudience: {},
    userAccount: {},
    planBudgets: [],
    annualBudgetFiscalCurrent: 0,
    annualBudgetFiscalNext: 0,
  };

  static channelsPropsObject() {
    return {
      ...getChannelsWithProps(),
      [EXTRA_CHANNEL]: {
        category: EXTRA_CHANNEL,
        department: 'test',
        nickname: 'test',
        title: 'test',
        isUnknownChannel: false,
      },
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      prevBudgetType: budgetTypeMapping.fiscalCurrent,
      optionSelected: false,
      addChannelPopup: false,
      editMode: false,
      interactiveMode: false,
      scrollEvent: null,
      showOptimizationPopup: false,
      primaryPlanForecastedIndicators: this.props.forecastedIndicators,
      budgetToggle: false,
      showSumData: false,
      isMergedView: false,
      isLoading: false,
      campaignTagProperties: null,
    };
  }

  async componentDidMount() {
    if (!this.props.campaignTags) {
      this.props.getAllCampaignTags();
    }
    this.getRelevantEvents(this.props);
    if (isPopupMode()) {
      disablePopupMode();
      this.setState({ interactiveMode: true });
    }
    await this.setMultipleRegionsBudgetData();
    this.setState({ primaryPlanForecastedIndicators: this.props.forecastedIndicators });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.getRelevantEvents(nextProps);
    if (nextProps.planBudgets) {
      this.props.resetFormattedGraphExplainableAlternativeScenarioForecast();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.region !== prevProps.region) {
      this.setState({ isMergedView: false });
    }
    if (!isEqual(this.props.channelsMonthlyBudgets, prevProps.channelsMonthlyBudgets)) {
      this.setBudgetData({
        budgetsData: {
          [this.props.region]: [...this.props.channelsMonthlyBudgets],
        },
      });
    }

    if (!isEqual(this.props.campaignTags, prevProps.campaignTags)) {
      this.setMultipleRegionsBudgetData();
    }
  }

  get currentFiscalYear() {
    const { fiscalYearFirstMonth } = this.props;
    return getCurrentFiscalYear(fiscalYearFirstMonth);
  }

  get isMasterRegion() {
    return this.isManyRegions && isMasterRegion(this.props.region);
  }

  get isManyRegions() {
    return !isEmpty(this.props.extendedRegionData);
  }

  async setMultipleRegionsBudgetData() {
    const { regions } = this.props;
    const allRegionsBudgetsData = {};

    await this.props.getCampaignsAndChannelBudgetsGroupedByMonthMultipleRegions({ regions });

    for (const regionOfResult of regions) {
      allRegionsBudgetsData[regionOfResult] = this.props.channelsMonthlyBudgets.filter((channelBudget) => channelBudget.region === regionOfResult);
    }

    await this.setBudgetData({ budgetsData: allRegionsBudgetsData });
  }

  async setBudgetData({ budgetsData }) {
    const {
      formattedGraphExplainableForecast, campaignsMonthlyBudgets, setChannelsMonthlyBudgets, channelsMonthlyBudgets,
    } = this.props;

    if (formattedGraphExplainableForecast?.length > 0) {
      const forecastFirstItemDate = formattedGraphExplainableForecast[0].timeText;
      for (const region of Object.keys(budgetsData)) {
        const firstIndexToKeep = budgetsData[region]?.findIndex((item) => item.date > moment(forecastFirstItemDate).subtract(1, 'month'));
        if (firstIndexToKeep === 0) {
          continue;
        }
        budgetsData[region] = budgetsData[region]?.slice(firstIndexToKeep);
        setChannelsMonthlyBudgets({ channelsMonthlyBudgets: channelsMonthlyBudgets.slice(firstIndexToKeep) });
        for (const campaign of campaignsMonthlyBudgets[region]) {
          campaign.actualSpent = campaign.actualSpent.slice(firstIndexToKeep);
          campaign.budget = campaign.budget.slice(firstIndexToKeep);
        }
      }
    }

    await this.setState({ budgetsData });
  }

  async setBudgetsData(region = this.props.region) {
    await this.props.getCampaignsAndChannelBudgetsGroupedByMonth({ region });
    const { channelsMonthlyBudgets } = this.props;

    return [...channelsMonthlyBudgets];
  }

  getPlanBudgets = (region = this.props.region) => {
    const { budgetsData } = this.state;
    const relevantBudgetsData = this.tempBudgetsData || budgetsData;
    const channels = get(relevantBudgetsData, [region], [])
      .filter((item) => !item.isHistory)
      .map((item) => item.channels);
    return channels.map((month) => {
      const planBudgets = {};
      Object.keys(month)
        .forEach((channelKey) => {
          const {
            primaryBudget,
          } = month[channelKey];
          planBudgets[channelKey] = {
            committedBudget: primaryBudget,
            regions: [region],
          };
        });
      return planBudgets;
    });
  };

  deleteChannel = (channelKey) => {
    const { budgetsData } = this.state;
    Object.keys(budgetsData).forEach((region) => {
      budgetsData[region] = budgetsData[region]
        .map((month) => {
          if (month.isHistory) {
            return month;
          } else {
            const channels = { ...month.channels };
            if (channels[channelKey]) {
              channels[channelKey].primaryBudget = 0;
              if (!channels[channelKey].secondaryBudget && !channels[channelKey].isConstraint) {
                delete channels[channelKey];
              }
            }
            return { ...month, channels };
          }
        });
    });
    this.setBudgetData({ budgetsData });
  };

  getUpdatedBudgetsData = (newBudget, channelKey, month, region, withForecast, srcData = this.state.budgetsData) => {
    const { interactiveMode } = this.state;
    const budgetsData = { ...srcData };
    const secondary = budgetsData[region][month].channels[channelKey]
      && budgetsData[region][month].channels[channelKey].secondaryBudget;
    const alreadyHardConstraint = budgetsData[region][month].channels[channelKey]
      && budgetsData[region][month].channels[channelKey].isConstraint
      && budgetsData[region][month].channels[channelKey].isSoft
      === false;
    budgetsData[region][month].channels[channelKey] = {
      secondaryBudget: (withForecast && !interactiveMode) ? newBudget : secondary,
      primaryBudget: newBudget,
      budgetConstraint: newBudget,
      isConstraint: true,
      isSoft: !alreadyHardConstraint,
    };
    return budgetsData;
  };

  updateTempBudgets = (newBudget, channelKey, month, region) => {
    const { budgetsData } = this.state;
    this.tempBudgetsData = this.getUpdatedBudgetsData(newBudget, channelKey, month, region, true, this.tempBudgetsData || cloneDeep(budgetsData));
  };

  onCancelClick = async () => {
    this.setState({ interactiveMode: false });
    await this.setBudgetsData();
    this.setState({ primaryPlanForecastedIndicators: this.props.forecastedIndicators });
  };

  editCommittedBudget = async (month, channelKey, newBudget, region, withForecast, deferServerUpdate, startDate, endDate) => {
    this.props.updateCampaignAmount({
      campaignName: generalCampaignName, channel: channelKey, region, startDate, endDate, amountType: amountTypes.budget, amount: newBudget,
    });
    this.props.updateCampaignProperties({
      name: generalCampaignName, channel: channelKey, sources: [channelKey], startDate, endDate, status: 'New', region,
    });
    if (region) {
      if (deferServerUpdate) {
        await this.updateTempBudgets(newBudget, channelKey, month, region);
      }
      if (this.state.editMode && !this.props.unsaved) {
        this.props.updateState({ unsaved: true });
      }
      if (!this.state.editMode) {
        const budgetsData = this.getUpdatedBudgetsData(newBudget, channelKey, month, region, withForecast);
        await this.setBudgetData({ budgetsData });
        this.props.getExplainableForecastData();
      }
    }
    if (!this.state.interactiveMode && !this.state.editMode) {
      this.props.sendUpdateCampaignsAmountRequestToServer({ startDate, endDate });
      this.props.sendUpdateCampaignsPropertiesRequestToServer({});
    }
  };

  afterRegionCreation = (region) => {
    this.addRegionToChannel(this.state.contextMenuChannel, region);
  };

  getRelevantEvents = (props) => {
    this.setState({
      events: events.filter(
        (event) => event.vertical
          === props.userProfile.vertical
          || event.companyType
          === props.targetAudience.companyType
      ),
    });
  };

  editUpdate = async () => {
    // In first login localStorage is not initialized with region obj
    const { region, regions } = this.props;
    const regionsToUpdate = getRegionsToUpdate(region, regions);
    const serverUpdate = () => {
      regionsToUpdate.forEach(() => {
        this.tempBudgetsData = null;
        if (!this.state.interactiveMode) {
          this.props.getExplainableForecastData();
          this.props.updateState({
            dataUpdated: true,
            unsaved: false,
          });
        }
      });
    };

    if (this.tempBudgetsData) {
      await this.setBudgetData({ budgetsData: this.tempBudgetsData });
      serverUpdate();
    } else {
      serverUpdate();
    }
  };

  addChannel = async (channelKey) => {
    const { budgetsData } = this.state;
    const updatedBudgetsData = { ...budgetsData };
    const { region, regions } = this.props;
    const regionsToUpdate = getRegionsToUpdate(region, regions);
    regionsToUpdate.forEach((regionToUpdate) => {
      updatedBudgetsData[regionToUpdate] = updatedBudgetsData[regionToUpdate]
        .map((month) => {
          if (month.isHistory) {
            return month;
          } else {
            const channels = { ...month.channels };
            this.initializeChannelIfNeeded(channels, channelKey, regionToUpdate);
            return { ...month, channels };
          }
        });
    });
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');
    this.props.updateCampaignAmount({
      campaignName: generalCampaignName, channel: channelKey, region, startDate, endDate, amountType: amountTypes.budget, amount: 0,
    });
    this.props.updateCampaignProperties({
      name: generalCampaignName, channel: channelKey, sources: [channelKey], startDate, endDate, status: 'New', region,
    });
    this.setState({ addChannelPopup: false, budgetsData: updatedBudgetsData }, () => {
      const [el] = document.querySelectorAll(`*[data-channel='${channelKey}']`);
      if (el) {
        this.scrollTo(el);
      }
    });
  };

  scrollTo(el, offset = 360) {
    if (!el) {
      return;
    }

    el.scrollIntoView(true);
    const currentScroll = window.pageYOffset;
    const elCurrentScrollTop = el.getBoundingClientRect().top;

    if (elCurrentScrollTop < offset) {
      window.scroll(0, currentScroll - offset);
    }
  }

  setRef = (channel, ref) => {
    this[channel] = ref;
  };

  openAddChannelPopup = (channel) => {
    this.setState({ addChannelPopup: true, initialChannelToOpen: channel });
  };

  addRegionToChannel = (channel, region) => {
    const budgetsData = { ...this.state.budgetsData };
    budgetsData[region] = budgetsData[region]
      .map((month) => {
        if (month.isHistory) {
          return month;
        } else {
          const channels = { ...month.channels };
          this.initializeChannelIfNeeded(channels, channel);
          this.initializeRegionsIfNeeded(channels, channel);
          channels[channel].regions[region] = 0;
          return { ...month, channels };
        }
      });
    this.setBudgetData({ budgetsData });
  };

  initializeChannelIfNeeded = (channels, channelKey, region) => {
    if (!channels[channelKey]) {
      channels[channelKey] = {
        secondaryBudget: 0,
        primaryBudget: 0,
        budgetConstraint: 0,
        isConstraint: false,
        isSoft: false,
        region,
      };
    }
  };

  initializeRegionsIfNeeded = (channels, channelKey) => {
    if (!channels[channelKey].regions) {
      channels[channelKey].regions = {};
    }
  };

  onPageScrollEventRegister = (onPageScroll) => {
    this.setState({ scrollEvent: onPageScroll });
  };

  updateRegionState = (region, updateObject, callback) => {
    const { extendedRegionData, updateState } = this.props;
    const newExtendedRegionData = {
      ...extendedRegionData,
      [region]: {
        ...extendedRegionData[region],
        ...updateObject,
      },
    };
    updateState({ extendedRegionData: cloneDeep(newExtendedRegionData) }, callback, true);
  };

  cancelPopup = async () => {
    this.props.resetCampaignsToUpdate();
    this.props.resetFormattedGraphExplainableAlternativeScenarioForecast();
    this.setState({ editMode: false });
    this.props.updateState({ unsaved: false });
  };

  commitChangesPopup = () => {
    const { editMode } = this.state;
    if (editMode) {
      this.props.sendUpdateCampaignsAmountRequestToServer({});
      this.props.sendUpdateCampaignsPropertiesRequestToServer({});
      this.editUpdate();
      this.props.updateState({ unsaved: false });
    }
    this.setState({ editMode: !editMode });
  };

  setAsyncState = (newState) => new Promise((resolve) => { this.setState(newState, () => resolve()); });

  asyncTimeout = () => new Promise((resolve) => { setTimeout(() => resolve(), 0); });

  renderRegionViewToggle = (region, isMergedView, annualTabActive) => (isMasterRegion(region) ? (
    <div style={annualTabActive ? { width: 200, marginLeft: 25 } : { marginBottom: -24, width: 200 }}>
      <Toggle
        style={{ margin: '10px 0' }}
        options={[{
          text: 'By Region',
          value: false,
        }, {
          text: 'By Channel',
          value: true,
        }]}
        selectedValue={isMergedView}
        onClick={() => {
          this.setAsyncState({ isLoading: true })
            .then(this.asyncTimeout)
            .then(() => this.setAsyncState({ isMergedView: !isMergedView, isLoading: false }));
        }}
      />
    </div>
  ) : null);

  onUpdateAlternativeForecast = () => {
    const updatedPlanBudget = this.getPlanBudgets();
    this.props.getAlternativeScenarioExplainableForecastData(updatedPlanBudget);
  };

  render() {
    const {
      showSumData, interactiveMode, editMode, addChannelPopup, initialChannelToOpen,
      isMergedView, isLoading, isCustomYear, campaignTagProperties,
    } = this.state;
    const {
      userChannelsSchema, campaigns, fiscalYearFirstMonth,
      setNewGlobalState, plan,
      forecastedIndicators, calculatedData, userRegionsNicknames, updateState,
      channelsImpact, region, regions, extendedRegionData,
      budgetType, unsaved, campaignKeyToNameMapping,
      pacingMethod, flags, campaignTags,
      selectedCampaignTags, removeFromSelectedCampaignTags, isBudgetLoadingFromServer,
    } = this.props;
    const useAlternativePacing = PACING_METHODS.PACING_FOR_ON_LAST_DAY === pacingMethod;

    const currentYear = this.currentFiscalYear;

    const budgetTypeOptions = [{
      label: currentYear.toString(),
      value: budgetTypeMapping.fiscalCurrent,
    }, {
      label: (currentYear + 1).toString(),
      value: budgetTypeMapping.fiscalNext,
    }, {
      label: 'Agile',
      value: budgetTypeMapping.agile,
    }];

    const fiscalTimeData = fiscalData(fiscalYearFirstMonth);
    const yearSelected = `${budgetType === budgetTypeMapping.agile ? currentYear.toString() : budgetTypeOptions[budgetType].label}`;

    const annualTabActive = this.props.children ? this.props.children.type === AnnualTab : null;

    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => React.cloneElement(child, merge({
        useAlternativePacing,
        whatIf: plan,
        setRef: this.setRef,
        editCommittedBudget: this.editCommittedBudget,
        deleteChannel: this.deleteChannel,
        addRegionToChannel: this.addRegionToChannel,
        secondaryPlanForecastedIndicators: this.state.editMode || this.state.interactiveMode
          ? forecastedIndicators
          : null,
        onPageScrollEventRegister: this.onPageScrollEventRegister,
        openAddChannelPopup: this.openAddChannelPopup,
        onUpdateAlternativeForecast: this.onUpdateAlternativeForecast,
        budgetEditMode: this.state.editMode,
        calculatedData,
        fiscalYearFirstMonth,
        userRegionsNicknames,
        updateState,
        channelsImpact,
        region,
        regions,
        campaigns,
        budgetType,
        unsaved,
        channelsPropsObject: Plan.channelsPropsObject(),
        currentYear,
        year: yearSelected,
        showSumData,
        userChannelsSchema,
        setNewGlobalState,
        isMergedView,
        isMasterRegion: this.isMasterRegion,
        updateRegionState: this.updateRegionState,
        extendedRegionData,
        isCustomYear,
        campaignKeyToNameMapping,
        ...fiscalTimeData,
      }, this.state))
    );

    return (
      <div>
        <Page
          width="100%"
          popup={interactiveMode}
          onPageScroll={this.state.scrollEvent}
          contentClassName={this.classes.content}
        >
          {isLoading && (
            <div className={this.classes.loader}>
              <Loader newStyle />
            </div>
          )}
          <div className={pageStyle.locals.container}>
            {annualTabActive ? (
              <div className={pageStyle.locals.budgetContentHead}>
                <div className={this.classes.column} style={{ justifyContent: 'flex-start', marginRight: 8 }}>
                  <BreadcrumbsTitle breadcrumbs={[pageLabels.plan]} />

                  {this.renderRegionViewToggle(region, isMergedView, true)}
                  {!isMasterRegion(region) ? (!editMode && interactiveMode ? (
                    <div style={{ display: 'flex' }}>
                      <div className={this.classes.error}>
                        <label hidden={!this.props.isPlannerError}>
                          {'You\'ve reached the plan updates limit.'}
                          <br />
                          {' '}
                          To
                          upgrade,
                          click
                          <a
                            href="mailto:support@infinigrow.com?&subject=I need replan upgrade"
                            target="_blank"
                            rel="noreferrer"
                          >
                            here
                          </a>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <FeatureFlags flag={flags.getSuggestionsButtonPlanBudget}>
                      <PlanButton
                        numberOfPlanUpdates={this.props.numberOfPlanUpdates}
                        onClick={() => this.setState({ showOptimizationPopup: true })}
                        style={{ marginLeft: 15, width: 140 }}
                        label="Get Suggestions"
                        showIcons={false}
                      />
                    </FeatureFlags>
                  )) : null}
                </div>
              </div>
            ) : this.renderRegionViewToggle(region, isMergedView)}
            {annualTabActive && !isBudgetLoadingFromServer && (
              <div style={{ width: '100%' }}>
                <div className={this.classes.planControlsRow}>
                  <div className={this.classes.planControlsRow}>
                    <CampaignTagsOptions
                      onEditClick={(campaignTag = {}) => this.setState({ campaignTagProperties: campaignTags.find((tag) => tag.id === campaignTag.value) || {} })}
                    />
                    {selectedCampaignTags.map((selectedCampaignTag) => (
                      <FilterTag
                        onClick={() => this.setState({ campaignTagProperties: campaignTags.find((tag) => tag.id === selectedCampaignTag.value) })}
                        key={`filter-tag-budget-${selectedCampaignTag.value}`}
                        label={selectedCampaignTag.label}
                        onRemove={() => removeFromSelectedCampaignTags({ campaignTag: selectedCampaignTag })}
                      />
                    ))}
                  </div>
                  <div className={this.classes.planControlsRow}>
                    <PlanControls
                      editMode={editMode}
                      interactiveMode={interactiveMode}
                      addChannelPopup={addChannelPopup}
                      initialChannelToOpen={initialChannelToOpen}
                      addChannel={this.addChannel}
                      openAddChannelPopup={this.openAddChannelPopup}
                      onCancelClick={this.onCancelClick}
                      commitChanges={this.commitChangesPopup}
                      cancelPopup={this.cancelPopup}
                      closeAddChannel={() => this.setState({ addChannelPopup: false })}
                    />
                    <Switch
                      onSwitchClick={() => this.setState({ showSumData: !showSumData })}
                      isActive={showSumData}
                      title="Q/Y sums"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={this.classes.serverDown}>
              <label hidden={!this.props.serverDown}>
                Something is wrong... Let us check what is it and fix it for you
                :)
              </label>
            </div>
            {childrenWithProps}
          </div>
        </Page>
        {campaignTagProperties && (<CampaignTag tagProperties={campaignTagProperties} onClose={() => this.setState({ campaignTagProperties: null })} />)}
      </div>
    );
  }
}

export default withLDConsumer()(enhance(Plan));
