import React, { useState } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import CardRowsTable from 'components/common/CardRowsTable';
import ConfirmPopup from 'components/ConfirmPopup';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import Switch from 'components/controls/Switch';
import Skeleton from 'components/common/Skeleton';
import CreateSignalPopup from 'components/widgets/signals/createSignalPopup';

import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import { skeletonSmallTableCellSizes } from 'components/common/enums';
import { getSignalSourceIconSrc } from 'components/widgets/signals/logic/signals';
import {
  confirmPopupDescription,
  confirmPopupTitle, mySignalsColumnsKeys, mySignalsColumnsLabels,
  mySignalsSubTitle,
  mySignalsTitle,
  onConfirmSignalDeleteToastMessage, sourcesLabels, sourcesKeys,
} from 'components/widgets/signals/enums';

import tableStyle from 'styles/salesEnablement/mySignalsTable.css';
import style from 'styles/salesEnablement/salesEnablement.css';

const styles = style.locals || {};
const tableStyles = tableStyle.locals || {};

export default function MySignals({
  mySignals, setMySignals, setSignalAnalyticsData, isLoaded,
}) {
  useStyles([tableStyle, style], [injectStyle]);

  const [signalToDelete, setSignalToDelete] = useState({});
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [isShowCreateSignalPopup, setIsShowCreateSignalPopup] = useState(false);

  function confirmDeletePopupCallBack({ isCanceled }) {
    if (!isCanceled) {
      const signalId = signalToDelete.id;
      setMySignals(mySignals.filter((signal) => signal.id !== signalId));
      setSignalAnalyticsData((prevState) => prevState.filter((signal) => signal.id !== signalId));
      toast.success(onConfirmSignalDeleteToastMessage, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
        toastId: onConfirmSignalDeleteToastMessage,
        style: { width: '320px', fontSize: '14px' },
      });
    }
    setIsShowConfirmationPopup(false);
  }

  function getTableColumnByKey({ columnKey }) {
    const newColumn = {
      id: columnKey,
      accessor: columnKey,
      header: mySignalsColumnsLabels[columnKey] || columnKey,
      cell: (cellItem) => {
        if (isLoaded || cellItem) {
          return (
            <div className={tableStyles.rowWrapper}>
              {cellItem}
            </div>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      },
    };

    if (columnKey === mySignalsColumnsKeys.source) {
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          const signalSourceIconSrc = getSignalSourceIconSrc({ value: cellItem });
          const cellStyle = cellItem === sourcesKeys.infinigrowAI ? styles.ai : tableStyles.rowWrapper;
          return (
            <div className={classnames(styles.cellWithIcon, cellStyle)}>
              <img src={signalSourceIconSrc} alt={cellItem} />
              {sourcesLabels[cellItem]}
            </div>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      };
    }

    if (columnKey === mySignalsColumnsKeys.rules) {
      newColumn.width = 300;
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          return cellItem.map((rule, index) => (
            <div className={tableStyles.rulesCell}>
              <FilterTag
                label={rule.name}
              />
              {index < cellItem.length - 1 && 'AND'}
            </div>
          ));
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      };
    }
    if (columnKey === mySignalsColumnsKeys.timeframe) {
      newColumn.cell = (cellItem) => {
        if (isLoaded || cellItem) {
          return (
            <>
              <div className={tableStyles.rowWrapper}>
                {getParseDateRangeLabel({ timeFrame: cellItem })}
              </div>
            </>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      };
    }

    if (columnKey === mySignalsColumnsKeys.active) {
      newColumn.cell = (cellItem, props) => {
        if (isLoaded || cellItem) {
          return (
            <>
              <div className={tableStyles.switchCell}>
                <Switch
                  onSwitchClick={() => setMySignals(mySignals.map((signal) => (signal.id === props.original.id ? { ...signal, active: !signal.active } : signal)))}
                  isActive={cellItem}
                  dataTestId={`switch-${props.original.id}`}
                />
              </div>
            </>
          );
        }
        return (
          <Skeleton {...skeletonSmallTableCellSizes} isLightTheme />
        );
      };
    }

    return newColumn;
  }

  const tableDataKeys = Object.keys(mySignalsColumnsKeys);
  const tableColumns = tableDataKeys.map((columnKey) => getTableColumnByKey({ columnKey }));
  tableColumns.push({
    id: 'delete',
    header: '',
    width: 100,
    cell: (item) => (
      <>
        <div
          data-testid="trashIcon"
          className={classnames(tableStyles.rowWrapper, tableStyles.icon, tableStyles.clickableItem)}
          onClick={() => {
            setSignalToDelete(item);
            setIsShowConfirmationPopup(true);
          }}
        >
          <img src="/assets/userActions-icons/trash.svg" alt="delete signal" />
        </div>
      </>
    ),
  });

  return (
    <div>
      <div className={styles.header}>
        <div className={classnames(styles.title, styles.bold)}>
          {mySignalsTitle}
        </div>
        <div className={styles.headerActions}>
          <Button
            type="primaryBlue"
            onClick={() => setIsShowCreateSignalPopup(true)}
          >
            + Create Signal
          </Button>
        </div>
      </div>
      <div className={styles.description}>
        {mySignalsSubTitle}
      </div>
      <CardRowsTable
        data={mySignals}
        columns={tableColumns}
        isLoading={!isLoaded}
        wrapperClassname={tableStyles.cardRowsTableWrapper}
      />

      {isShowConfirmationPopup ? (
        <ConfirmPopup
          callback={(isCanceled) => confirmDeletePopupCallBack({ isCanceled })}
          confirmBtn="Delete"
          isTitleBold
          title={confirmPopupTitle}
          description={confirmPopupDescription}
        />
      ) : null}
      {isShowCreateSignalPopup ? (
        <CreateSignalPopup
          onClose={() => setIsShowCreateSignalPopup(false)}
          stepIndexToOpen={0}
          signals={mySignals}
          setSignals={setMySignals}
          setSignalAnalyticsData={setSignalAnalyticsData}
        />
      ) : null}
    </div>

  );
}
