import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';
import Skeleton from 'components/common/Skeleton';

import { calculateUpliftBarsWidth, getUpliftValueAsText } from 'components/common/logic/uplift';

import style from 'styles/common/uplift.css';

const styles = style.locals || {};

export default function Uplift({
  value,
  minValue = 0,
  maxValue = 0,
  className,
  tooltip,
  isLoaded,
  upliftRangeLimit = {},
}) {
  useStyles([style]);

  const { negativeBarWrapperWidth, negativeBarWidth, positiveBarWidth } = calculateUpliftBarsWidth({ value, minValue, maxValue });

  const upliftText = getUpliftValueAsText({ value, upliftRangeLimit });

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.upliftBar}>
        {isLoaded || !isNil(value) ? (
          <>
            <div className={styles.negativeBarWrapper} style={{ flexBasis: `${negativeBarWrapperWidth}%` }}>
              <div
                data-testid="negative-bar"
                className={styles.negativeBar}
                style={{ width: `${negativeBarWidth}%` }}
              />
            </div>
            <div className={styles.originLine} />
            <div className={styles.positiveBarWrapper}>
              <div
                data-testid="positive-bar"
                className={styles.positiveBar}
                style={{ width: `${positiveBarWidth}%` }}
              />
            </div>
          </>
        ) : (
          <Skeleton height={12} borderRadius={12} width={80} />
        )}
      </div>

      {isLoaded || !isNil(value) ? (
        <Tooltip
          id={`uplift-tooltip-${value}-${tooltip}`}
          tip={tooltip}
          hidden={!tooltip}
          className={classNames(styles.upliftValue, value > 0 && styles.positiveValue, value < 0 && styles.negativeValue)}
        >
          <div>{upliftText}</div>
        </Tooltip>
      ) : (
        <Skeleton height={12} width={20} />
      )}
    </div>
  );
}
