import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import Component from 'components/Component';
import serverCommunication from 'data/serverCommunication';
import IntegrationPopup from 'components/common/IntegrationPopup';
import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';

import style from 'styles/onboarding/onboarding.css';

export default class AuthorizationIntegrationPopup extends Component {
  style = style;

  static defaultProps = {
    haveStepAfterAuthorizationBeforeMapping: false,
  };

  static propTypes = {
    haveStepAfterAuthorizationBeforeMapping: PropTypes.bool,
  };

  componentDidMount() {
    if (!this.props.data) {
      serverCommunication.serverRequest('get', this.props.api, null, userStore.userMonthPlan.region)
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((data) => {
                this.setState({ url: data });
              });
          } else if (response.status === 401) {
            history.push('/');
          } else {
            servicesStore.logger.error('failed getting data for api', {
              api: this.props.api,
            });
          }
        });
    }
  }

  open() {
    this.getAuthorization();
  }

  afterAuthorizationBeforeMap = (code) => {
    if (this.props.haveStepAfterAuthorizationBeforeMapping) {
      this.loadingStarted();
      this.props.afterAuthorizationBeforeMappingStep(code)
        .then(() => {
          this.loadingFinished();
          this.refs.integrationPopup.propogateStep(false);
        });
    } else {
      this.afterAuthorization(code);
    }
  };

  getAuthorization = () => {
    if (!this.props.data && !isEmpty(this.state.url)) {
      const win = window.open(this.state.url);
      const timer = setInterval(() => {
        if (win.closed) {
          clearInterval(timer);
          const code = localStorage.getItem('code');
          if (code) {
            localStorage.removeItem('code');
            this.afterAuthorizationBeforeMap(code);
          } else {
            this.refs.integrationPopup.propogateStep(false);
          }
        }
      }, 1000);
    } else {
      this.afterAuthorizationBeforeMap(null);
    }
  };

  loadingStarted = () => {
    if (this.props.loadingStarted) {
      this.props.loadingStarted();
    }
  };

  loadingFinished = () => {
    if (this.props.loadingFinished) {
      this.props.loadingFinished();
    }
  };

  afterAuthorization = (data) => {
    const { objectsMapping, fieldKey = 'code' } = this.props;
    this.loadingStarted();
    return serverCommunication.serverRequest(
      'post',
      this.props.afterAuthorizationApi || this.props.api,
      JSON.stringify({
        [fieldKey]: data,
        objectsMapping,
      }),
      userStore.userMonthPlan.region
    )
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((dataResponse) => {
              this.props.afterDataRetrieved(dataResponse)
                .then((showPopup) => {
                  this.loadingFinished();
                  this.refs.integrationPopup.propogateStep(!showPopup);
                })
                .catch((error) => {
                  this.loadingFinished();
                  window.alert(error.message);
                });
            });
        } else if (response.status === 401) {
          this.loadingFinished();
          history.push('/');
        } else {
          this.loadingFinished();
          window.alert(`Error authorizing connection to ${this.props.platformTitle}`);
        }
      });
  };

  render() {
    const { doneButtonAction } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <IntegrationPopup
          {...this.props}
          makeServerRequest={this.props.makeServerRequest}
          ref="integrationPopup"
          closeWhileWaitingForRequest
          platformTitle={this.props.platformTitle}
          doneButtonAction={doneButtonAction ? doneButtonAction(this.afterAuthorization) : null}
        >
          {this.props.children}
        </IntegrationPopup>
      </div>
    );
  }
}
