import { isEmpty, isNil } from 'lodash';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';
import timeFrameModule from 'modules/timeframe';
import SingleMetricProperties from 'components/pages/widgetBuilder/propertiesComponents/SingleMetricProperties';
import ImpactBySegmentProperties from 'components/pages/widgetBuilder/propertiesComponents/ImpactBySegmentProperties';
import SegmentAnalysisProperties from 'components/pages/widgetBuilder/propertiesComponents/SegmentAnalysisProperties';

import { getWidgetsDataV2 } from 'components/pages/analyze/widgetsRequest';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { widgetTypes, widgetsForPollingRequest, widgetsConfig } from 'components/pages/analyze/enums';
import { widgetBuilderTypes } from 'components/pages/widgetBuilder/enums';

export async function getWidgetsBuilderDataResults({ widget }) {
  for (const filter of widget.filters) {
    if (filter.data.timeFrame) {
      const { startDate: startTS, endDate: endTS } = timeFrameModule.getTimeframeParams({
        ...filter.data.timeFrame,
        fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
      });
      filter.data.timeFrame = {
        value: filter.data.timeFrame.value,
        startTS,
        endTS,
      };
    }
  }

  const { startDate, endDate } = timeFrameModule.getTimeframeParams({
    ...widget.timeFrame,
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
  });

  const widgetDataConfig = {
    ...widget.widgetConfig,
    type: widget.type,
    timeFrame: {
      ...widget.timeFrame,
      startDate: new Date(getTSForTimezone(startDate)),
      endDate: new Date(getTSForTimezone(endDate)),
    },
  };

  const serverRequestTasks = [];
  const relevantWidgetConfig = Object.values(widgetsConfig).find((config) => config.type === widget.type);

  let relatedWidgetsToRun = relevantWidgetConfig?.widgetsToServerRequest || [];
  if (!attributionStore.isCalculateAdvancedMetrics) {
    relatedWidgetsToRun = relatedWidgetsToRun.filter((widgetToRun) => widgetToRun !== widgetTypes.trendBySegments);
  }

  if (isEmpty(widget.widgetConfig?.upliftBySegmentsParams)) {
    relatedWidgetsToRun = relatedWidgetsToRun.filter((widgetToRun) => widgetToRun !== widgetTypes.upliftBySegments);
  }

  for (const widgetToRun of relatedWidgetsToRun) {
    if (widgetsForPollingRequest.includes(widgetToRun)) {
      getWidgetsDataV2({
        widget: widgetToRun,
        widgetConfig: widgetDataConfig,
        addBaseAnalyzeConfig: false,
        useAccountViewLogic: false,
      });
      continue;
    }

    const body = {
      region: userStore.userMonthPlan.region,
      requestStoreParams: {
        widgets: [widgetToRun],
        widgetsConfig: { [widgetToRun]: [widgetDataConfig] },
      },
    };

    serverRequestTasks.push(handleServerRequest({ body }));
  }

  const widgetsDataResponse = await Promise.all(serverRequestTasks);

  let updatedWidgetBuilderDataResults = {};

  for (const widgetResponse of widgetsDataResponse) {
    updatedWidgetBuilderDataResults = {
      ...updatedWidgetBuilderDataResults,
      ...widgetResponse,
    };
  }

  return {
    ...updatedWidgetBuilderDataResults,
    isLoaded: true,
  };
}

async function handleServerRequest({ body }) {
  const region = userStore.userMonthPlan.region;
  try {
    const response = await serverCommunication.serverRequest('POST', 'analysis/widgets', JSON.stringify(body), region);
    const responseData = await response.json();
    const { data } = responseData;

    return data;
  } catch (exception) {
    servicesStore.logger.error('failed to get question widget data', {
      UID: userStore.userMonthPlan.UID,
      region,
      questionId: body.questionId,
    });
    return {};
  }
}

export function getDefaultWidgetProperties() {
  const numberOfDays = userStore.userAccount?.widgetBuilderConfig?.amountOfDaysForPreview || 7;
  return {
    widgetKind: widgetBuilderTypes.table,
    timeFrame: {
      value: TIMEFRAME_VALUES.ROLLING,
      type: 'day',
      rollingValue: `last_${numberOfDays}_day_day`,
      excludeToday: true,
    },
    filters: [],
    attributionModel: attributionStore.attributionModel,
    isCalculateAdvancedMetrics: attributionStore.isCalculateAdvancedMetrics,
    isInReports: false,
    isReadOnly: true,
    isEnableNavigationMenu: false,
    isShowTimeframeSelect: true,
    isSelectAll: true,
  };
}

export function checkIfAllFieldsAreFilled({ values, requiredFields }) {
  return Object.entries(requiredFields).every(([fieldKey, fieldValue]) => {
    if (Array.isArray(fieldValue)) {
      const valuesByFieldKey = values[fieldKey] !== undefined;
      if (!valuesByFieldKey) {
        return false;
      }
      return fieldValue.every((field) => !isNil(values[fieldKey][field]) && values[fieldKey][field] !== '');
    }
    return !isNil(values[fieldKey]) && values[fieldKey] !== '';
  });
}

export function getWidgetConfigByType({ type }) {
  if (type === widgetBuilderTypes.metric) {
    return {
      PropertiesComponent: SingleMetricProperties,
      requiredFieldsForRequest: {
        kpiFocus: 'kpiFocus',
      },
      requiredFieldsForRender: {
        kpiFocus: 'kpiFocus',
      },
      widgetType: widgetTypes.performanceItem,
    };
  }

  if (type === widgetBuilderTypes.chart) {
    return {
      PropertiesComponent: ImpactBySegmentProperties,
      requiredFieldsForRequest: {
        kpiFocus: 'kpiFocus',
        impactBySegmentParams: ['firstSegment'],
      },
      requiredFieldsForRender: {
        kpiFocus: 'kpiFocus',
      },
      widgetType: widgetTypes.impactBySegment,
    };
  }

  if (type === widgetBuilderTypes.table) {
    return {
      PropertiesComponent: SegmentAnalysisProperties,
      requiredFieldsForRequest: {
        segmentsAnalysisParams: ['firstSegment'],
      },
      requiredFieldsForRender: {
        segmentsAnalysisParams: ['firstSegment'],
      },
      widgetType: widgetTypes.segmentsAnalysis,
    };
  }

  return null;
}
