import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';
import Textfield from 'components/controls/Textfield';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';

import style from 'styles/salesEnablement/createSignalPopup.css';

const styles = style.locals || {};

export default function SignalTimeframeSection({ newTimeframe, setNewTimeframe }) {
  useStyles([style]);

  return (
    <>
      <div className={styles.controllerTitle}>
        The signal occurred during
      </div>
      <div className={classnames(styles.inputWrapper, styles.additionalConfigurationComplexText)}>
        Last
        <Textfield
          value={newTimeframe?.rollingValue.split('_')[1] || 0}
          style={{ width: 64 }}
          onChange={(event) => {
            const formatedTimeframe = {
              value: TIMEFRAME_VALUES.ROLLING,
              type: 'day',
              rollingValue: `last_${event.target.value}_day_day`,
              excludeToday: true,
            };
            setNewTimeframe(formatedTimeframe);
          }}
          type="number"
          min="0"
        />
        days
      </div>
    </>
  );
}
