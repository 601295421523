import { toast } from 'react-toastify';

export function getPopupTextContent({ popupType }) {
  if (popupType.isRename) {
    return {
      popupTitle: 'Edit report name',
      actionText: 'Save',
    };
  }

  if (popupType.isDuplicate) {
    return {
      popupTitle: 'Duplicate report',
      subTitle: 'You can always change the report name in the report tab',
      actionText: 'Save',
      secondaryText: 'Back',
    };
  }

  return {
    popupTitle: 'Create new report',
    actionText: 'Create report',
  };
}

export function toastSuccessMessage(message = 'Changes saved successfully!') {
  toast.success(message, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
    toastId: message,
    style: { width: '320px', fontSize: '14px' },
  });
}
