import React, { useEffect } from 'react';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router';
import { Button } from '@infinigrow/libs';

import useWindowDimensions from 'components/utils/getWindowDimensions';
import emptyWidgetsAnimation from 'assets/lottieAnimations/emptyWidgetsAnimation.json';
import FeatureFlags from 'components/common/FeatureFlags';

import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { reportIdParam } from 'components/pages/reports/enums';

import reportsStyle from 'styles/reports/reports.css';
import pageStyle from 'styles/page.css';

const styles = reportsStyle.locals;

function EmptyWidgets({ flags }) {
  const { height: screenHeight } = useWindowDimensions();
  const popupHeight = `${screenHeight - 300}px`;

  useEffect(() => {
    reportsStyle.use();
    pageStyle.use();

    return () => {
      reportsStyle.unuse();
      pageStyle.unuse();
    };
  }, []);

  const lottieAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyWidgetsAnimation,
    renderer: 'svg',
    rendererSettings: {
      className: styles.emptyWidgetsLottieAnimation,
    },
  };

  const reportIdFromURL = getQueryParams({ queryParamKey: reportIdParam });

  return (
    <div className={classNames(pageStyle.locals.container, pageStyle.locals.analyzeContainer)}>
      <div className={styles.emptyReports} style={{ height: popupHeight }}>
        <div className={classNames(styles.half, styles.emptyReportsContent)}>
          <h1>Your report is empty</h1>
          <p>Go to Analyze and add widgets to this report. Widgets will store data such as timeframe, filters, attribution models, and focus KPIs.</p>
          <div className={styles.emptyReportsButton}>
            <FeatureFlags flag={flags.reportsWidgetBuilderButton}>
              <Link className={styles.linkButton} to={`/widget-builder?reportId=${reportIdFromURL}`}>
                <Button type="primaryBlue">
                  Create a new widget
                </Button>
              </Link>
            </FeatureFlags>
            <Link className={styles.linkButton} to="/analyze/overview">
              <Button type="secondaryBlue">
                Go to Analyze
              </Button>
            </Link>
          </div>
        </div>
        <div className={styles.half}>
          <Lottie
            options={lottieAnimationOptions}
            style={{ height: 640 }}
          />
        </div>
      </div>
    </div>
  );
}

export default withLDConsumer()(EmptyWidgets);
